import { gql } from '../../../__generated__/graphql/catalog'

export const GET_REPLACEMENT_VARIANTS = gql(`
  query getReplacementVariants($replacementInput: [ReplacementInput!]!) {
    replacementVariants(replacementInput: $replacementInput) {
      replacementSku
      requestedKey
      replacementShopifyId
    }
  }
`)
