import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../../../../app/_providers/TranslationProvider.client'
import { useImageLoader } from '../../../../../../lib/hooks/useImageLoader'

import { DetailLine, Visual } from './styles'
import { Template } from './Template'

export type Props = {
  className?: string
  backgroundColor?: string
  imgSrc?: string
  logoName?: string
  logoPlacement?: string
} & HTMLAttributes<HTMLDivElement>

export const LogoSneakPeek: FC<Props> = ({
  className,
  backgroundColor,
  imgSrc,
  logoName,
  logoPlacement,
  ...rest
}) => {
  const { imageLoader } = useImageLoader()
  const {
    embroidery: {
      modal: { forms: fixtures },
    },
  } = useTranslation()

  if (!imgSrc) return null
  return (
    <Template.Container data-testid='logo-sneak-peek-container' className={className} {...rest}>
      <Template.Header backgroundColor={backgroundColor}>
        <ImageWrapper>
          <ResponsiveImage
            alt={logoName || 'CustomLogo'}
            aspectRatios={{
              sm: null,
              md: null,
            }}
            src={imageLoader({ src: imgSrc, width: 560, height: 100 })}
            loading={'default'}
            widths={{ unit: 'vw', sm: 100, md: 100 }}
          />
        </ImageWrapper>
      </Template.Header>
      <Template.Body>
        {logoName && (
          <DetailLine general>
            {fixtures.logoOption.logoLabel}: {logoName}
          </DetailLine>
        )}

        {logoPlacement && (
          <DetailLine>
            {fixtures.logoOption.logoPlacementLabel}: {logoPlacement}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}

const ImageWrapper = styled(Visual)`
  padding: ${o => o.theme.spacing(3.5)} ${o => o.theme.spacing(6)};
`
