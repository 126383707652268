import { gql } from '../../../__generated__/graphql/catalog'

export const PRICE_DETAILS_FRAGMENT = gql(`
  fragment PriceDetail on PriceDetails {
    __typename
    discountPrice {
      amount
    }
    price {
      currency
      amount
      base {
        amount
        currency
      }
    }
  }
`)
