import uniq from 'lodash-es/uniq'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMountedState } from 'react-use'
import Cookie from 'universal-cookie'
import { createContainer } from 'unstated-next'

import {
  GetReplacementVariantsQuery,
  GetVariantsBySkusQuery,
} from '../../../__generated__/graphql/catalog/graphql'
import {
  reportClientError,
  reportClientEvent,
} from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { cookieKeys } from '../../../app/_config/Cookies.config'
import { useInternationalAvailability } from '../../../app/_hooks/useInternationalAvailability'
import { useAuthentication } from '../../../app/_providers/AuthenticationProvider.client'
import { useCheckoutClient } from '../../../app/_providers/CheckoutClientProvider.client'
import { useGraphqlClients } from '../../../app/_providers/GraphqlClientsProvider.client'
import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { useTrackCartModified } from '../../../app/_providers/TrackingProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { useLocalCart } from '../../../lib/adapters/cart-persistence/useLocalCart'
import { mapCartItemToGtmVariant } from '../../../lib/analytics/analytics'
import { mapFigsCartItemsToShopifyCheckoutItems } from '../../../lib/cart-client/utils'
import { FETCH_CART_VARIANTS, MULTIPASS_AUTH_URL } from '../../../lib/graphql/queries'
import { GET_REPLACEMENT_VARIANTS } from '../../../lib/graphql/queries/GET_REPLACEMENT_VARIANTS'
import { QueryStatus } from '../../../lib/hooks/types'
import { GtmVariant, useGoogleTagManager } from '../../../lib/hooks/useGoogleTagManager'
import {
  deriveMapFromList,
  isLogoEmbroiderySetup,
  isStandardEmbroiderableProduct,
  isStandardEmbroideryItem,
  isTeamsEmbroiderableProduct,
  isTeamsEmbroideryItem,
} from '../../../lib/utils'
import { Cart, CartItem } from '../../../types/figs'
import { Variant } from '../../../types/graphql'
import { CustomAttribute } from '../../../types/shopify-storefront-api'

import { augmentPersistedCartItemsWithFigsData } from './utils'
const localStorageCartKeyForEcom = '@figs:storefront-cart-id'

interface UseCartNewCartItem {
  finalSale?: boolean
  item: GtmVariant
  properties?: Record<string, string | undefined>
  quantity?: number
  swPromoEligible?: boolean | undefined
  isPortalColor?: boolean
  isPortalLogo?: boolean
}

export interface UseCartOutput {
  cart: Cart
  addItems(itemsToAdd: UseCartNewCartItem[]): void
  addGlobalAttributes(attributes: CustomAttribute[]): void
  removeItems(keys: string[], options?: { removeAssociatedPortalProduct: boolean }): void
  updateItemsQuantity(
    keys: string[],
    calculateQuantity: ((currentQuantity: number) => number) | number
  ): void
  proceedToCheckout(props?: {
    callbackBeforeRedirect?: () => Promise<void>
    discountCode?: string
  }): Promise<void>
  status: Exclude<QueryStatus, 'idle'>
}

// https://www.30secondsofcode.org/js/s/split-array-into-chunks/#:~:text=Split%20array%20into%20chunks%20of%20a%20given%20size&text=This%20can%20be%20calculated%20by,from()%20.
const chunkItems = <T,>({ items, chunkSize }: { items: T[]; chunkSize: number }): T[][] => {
  return Array.from({ length: Math.ceil(items.length / chunkSize) }, (_nextItem, index) =>
    items.slice(index * chunkSize, index * chunkSize + chunkSize)
  )
}

function useCart(): UseCartOutput {
  const checkIsMounted = useMountedState()
  const { pushAddToCart: pushAddToCartToGtm, pushRemoveFromCart: pushRemoveFromCartToGtm } =
    useGoogleTagManager()
  const { trackCartModified } = useTrackCartModified()
  const { region, languageGroup } = useLocalization()
  const { cacheOptimizedClient: figsPublicClient, authorizedClient: figsAuthedClient } =
    useGraphqlClients()
  const shopifyCartClient = useCheckoutClient()
  const { filterUnavailableItemsFromMap } = useInternationalAvailability()
  const { account } = useAuthentication()
  const customerShopifyToken = account?.tokens.shopify

  const {
    cart: { finalSale },
  } = useTranslation()

  const {
    addItemsToCart: addItemsToPersistenceLayerWithoutEvent,
    addGlobalAttributesToCart: addGlobalAttributesToPersistenceLayerWithoutEvent,
    clearCart: clearPersistenceLayer,
    status: persistenceLayerStatus,
    cartItems: unfilteredPersistenceLayerItems,
    cartId: persistenceLayerId,
    cartAttributes: persistenceLayerAttributes,
    removeItemsFromCart: removeItemsFromPersistenceLayerWithoutEvent,
    setItemsQuantitiesInCart: setItemQuantitiesInPersistenceLayerWithoutEvent,
  } = useLocalCart()

  const persistenceLayerItems = useMemo(() => {
    return filterUnavailableItemsFromMap({
      itemsMap: unfilteredPersistenceLayerItems,
      regionId: region.id,
    })
  }, [filterUnavailableItemsFromMap, region.id, unfilteredPersistenceLayerItems])

  const persistenceLayerUniqueSkus = useMemo(() => {
    return uniq(Object.values(persistenceLayerItems).map(item => item.sku))
  }, [persistenceLayerItems])

  const removeItemsFromPersistenceLayer = useCallback(
    (itemsToRemove: CartItem[]) => {
      removeItemsFromPersistenceLayerWithoutEvent(
        itemsToRemove.map(itemToRemove => itemToRemove.key)
      )
      itemsToRemove.forEach(itemToRemove => {
        const variantToTrack = mapCartItemToGtmVariant(itemToRemove, region.currency)
        pushRemoveFromCartToGtm({ variant: variantToTrack, quantity: itemToRemove.quantity })
      })
    },
    [pushRemoveFromCartToGtm, region.currency, removeItemsFromPersistenceLayerWithoutEvent]
  )

  const addGlobalAttributesToPersistenceLayer = useCallback(
    (attributes: CustomAttribute[]) => {
      addGlobalAttributesToPersistenceLayerWithoutEvent(attributes)
    },
    [addGlobalAttributesToPersistenceLayerWithoutEvent]
  )

  const addItemsToPersistenceLayer = useCallback(
    (itemsToAdd: UseCartNewCartItem[]) => {
      const convertedItemsToAdd = itemsToAdd.map(itemToAdd => {
        const properties: Parameters<
          typeof addItemsToPersistenceLayerWithoutEvent
        >[0][number]['properties'] = {
          ...itemToAdd.properties,
          ...(Boolean(itemToAdd?.properties?._kitId) && {
            _setUid: itemToAdd?.properties?._kitId + '1',
          }),
        }
        if (itemToAdd.finalSale) {
          properties['_LPROP'] = 'final sale'
        }
        return {
          productType: itemToAdd.item.category,
          properties,
          sku: itemToAdd.item.sku,
          swPromoEligible: itemToAdd.swPromoEligible,
          quantity: itemToAdd.quantity ?? 1,
          variantId: itemToAdd.item.shopifyId,
          isPortalColor: itemToAdd.isPortalColor,
          isPortalLogo: itemToAdd.isPortalLogo,
        }
      })
      addItemsToPersistenceLayerWithoutEvent(convertedItemsToAdd)
      itemsToAdd.forEach(itemToAdd => {
        pushAddToCartToGtm({ variant: itemToAdd.item })
      })
    },
    [addItemsToPersistenceLayerWithoutEvent, pushAddToCartToGtm]
  )

  const setItemQuantitiesInPersistenceLayer = useCallback(
    (itemsToUpdate: CartItem[], newQuantity: number) => {
      itemsToUpdate.forEach(itemToUpdate => {
        const oldQuantity = itemToUpdate.quantity
        const variantToTrack = mapCartItemToGtmVariant(itemToUpdate, region.currency)
        if (newQuantity > oldQuantity) {
          pushAddToCartToGtm({
            variant: variantToTrack,
            quantity: newQuantity - oldQuantity,
          })
        } else {
          pushRemoveFromCartToGtm({
            variant: variantToTrack,
            quantity: oldQuantity - newQuantity,
          })
        }
      })

      setItemQuantitiesInPersistenceLayerWithoutEvent(
        itemsToUpdate.map(itemToUpdate => itemToUpdate.key),
        newQuantity
      )
    },
    [
      pushAddToCartToGtm,
      pushRemoveFromCartToGtm,
      region.currency,
      setItemQuantitiesInPersistenceLayerWithoutEvent,
    ]
  )

  useEffect(() => {
    const clearCartIfCheckoutWasCompleted = async () => {
      const cartId = window.localStorage.getItem(localStorageCartKeyForEcom)
      if (cartId) {
        const isCheckoutCompleted = await shopifyCartClient.isCheckoutCompleted({
          checkoutId: cartId,
        })
        if (isCheckoutCompleted) {
          window.localStorage.removeItem(localStorageCartKeyForEcom)
          clearPersistenceLayer()
        }
      }
    }
    clearCartIfCheckoutWasCompleted()
  }, [clearPersistenceLayer, shopifyCartClient])

  // Note: It doesn't make sense to "augment" data at the level of the cart provider...
  // we don't know yet what data people need. At most we should just be exposing ids for
  // cart items and letting different UIs decide what they want to query based on what they
  // need to display. They can also do pagination then... whereas here we end up calling 100s
  // of queries if we have 100s of cart items. Even if a user never once even looks inside the cart
  // at those items. It is wasteful and creates coupling of data types.
  const [variantsStatus, setVariantsStatus] = useState<UseCartOutput['status']>('pending')
  const [variants, setVariants] = useState<
    NonNullable<NonNullable<GetVariantsBySkusQuery['variants']>['nodes']>
  >([])

  const previousFetchedVariants = useRef<typeof persistenceLayerUniqueSkus>([])
  if (previousFetchedVariants.current !== persistenceLayerUniqueSkus && checkIsMounted()) {
    previousFetchedVariants.current = persistenceLayerUniqueSkus
    setVariantsStatus('pending')
    const newVariants: typeof variants = []
    const loadVariantDataForPersistedSkus = async () => {
      let variantFailed = false
      const variantQueries = persistenceLayerUniqueSkus.map(persistenceLayerUniqueSku => {
        return figsPublicClient.query({
          query: FETCH_CART_VARIANTS,
          variables: { skus: [persistenceLayerUniqueSku] },
        })
      })
      const variantResponses = await Promise.allSettled(variantQueries)
      variantResponses.forEach(variantResponse => {
        if (variantResponse.status === 'fulfilled') {
          newVariants.push(...(variantResponse.value.data.variants?.nodes ?? []))
        } else {
          variantFailed = true
        }
      })
      setVariants(newVariants)
      if (variantFailed) {
        setVariantsStatus('rejected')
      } else {
        setVariantsStatus('resolved')
      }
    }

    loadVariantDataForPersistedSkus()
  }

  const cart = useMemo<Cart>(() => {
    const variantsBySku = deriveMapFromList(
      variants,
      o => o.sku ?? 'INVALID_SKU'
    ) as unknown as Record<string, Variant>
    const augmentedCartItems = augmentPersistedCartItemsWithFigsData(
      persistenceLayerItems,
      variantsBySku
    )
    const itemCount: number = augmentedCartItems.reduce((sum, item) => sum + item.quantity, 0)
    const totalPrice: number = augmentedCartItems.reduce(
      (sum, item) => sum + (item.effectivePrice ?? 0) * item.quantity,
      0
    )
    return {
      id: persistenceLayerId,
      items: deriveMapFromList(augmentedCartItems, o => o.key),
      itemCount,
      totalPrice,
      attributes: persistenceLayerAttributes ?? [],
    }
  }, [persistenceLayerId, persistenceLayerItems, variants, persistenceLayerAttributes])

  const status = useMemo<UseCartOutput['status']>(() => {
    if (persistenceLayerStatus === 'pending' || variantsStatus === 'pending') return 'pending'
    if (variantsStatus === 'rejected') return 'rejected'
    return 'resolved'
  }, [persistenceLayerStatus, variantsStatus])

  useEffect(() => {
    if (status !== 'resolved') return
    const signalCartItems = Object.values(cart.items).map(item => {
      const signalCartItem: Parameters<typeof trackCartModified>[0]['items'][number] = {
        title: item.title,
        quantity: item.quantity,
        variantSku: item.sku,
        variantShopifyId: item.variantId,
        effectivePrice: item.effectivePrice,
        productShopifyId: item.productId,
      }
      return signalCartItem
    })
    trackCartModified({
      id: cart.id,
      items: signalCartItems,
    })
  }, [cart.id, cart.items, trackCartModified, status])

  const aggregateThisAndRelatedStandardEmbroideryItems = useCallback(
    (guid: string | undefined, cartItems: Record<string, CartItem>): CartItem[] => {
      return Object.values(cartItems).filter(item => item.properties._guid === guid)
    },
    []
  )

  const aggregateThisAndRelatedPendingEmbroideryLogos = useCallback(
    (logoId: string | undefined, cartItems: Record<string, CartItem>) => {
      return Object.values(cartItems).filter(item => item.properties._logo_id === logoId)
    },
    []
  )

  const aggregateThisAndRelatedTeamsEmbroideryItems = useCallback(
    (groupId: string | undefined, cartItems: Record<string, CartItem>): CartItem[] => {
      return Object.values(cartItems).filter(
        item => item.properties._groupid === groupId || item.properties._groupId === groupId
      )
    },
    []
  )

  const aggregateRestOfKitsNotRelatedToEmbroideryItems = useCallback(
    (kitId: string | undefined, cartItems: Record<string, CartItem>): CartItem[] => {
      return Object.values(cartItems).filter(
        item => item.properties._kitId === kitId && !item.properties._guid
      )
    },
    []
  )

  /**
   * When removing an embroiderable product or logo embroidery we need to check
   * if the associated logo embroidery is the last one in the cart. If it is we
   * need to remove the logo setup item as well.  We check if there are 3 items
   * that include the logo id one of which is the logo setup item, the other
   * two being the embroiderable product and the associated logo embroidery item.
   * In this case we return the logo setup item to be removed.
   */
  const shouldRemoveLogoSetup = useCallback(
    (logoId: string | undefined, cartItems: Record<string, CartItem>) => {
      const otherProductsThatHavePendingLogoSetup = Object.values(cartItems).filter(item => {
        return item.properties._logo_id === logoId
      })
      if (otherProductsThatHavePendingLogoSetup.length === 3) {
        const logoSetupItem = otherProductsThatHavePendingLogoSetup.find(
          item => item.properties._is_logo_setup === 'true'
        )
        if (logoSetupItem) {
          return logoSetupItem
        }
      }
      return
    },
    []
  )

  const getNewKitId = useCallback(
    (oldKitId: string | undefined, cartItems: Record<string, CartItem>): string => {
      return (
        Object.values(cartItems)
          .filter(item => item.properties._kitId === oldKitId && item.productType !== 'Embroidery')
          .map(o => o.sku)
          .sort()
          .join() + ','
      )
    },
    []
  )

  const addGlobalAttributes = useCallback<UseCartOutput['addGlobalAttributes']>(
    attributes => {
      addGlobalAttributesToPersistenceLayer(attributes)
    },
    [addGlobalAttributesToPersistenceLayer]
  )

  const addItems = useCallback<UseCartOutput['addItems']>(
    (itemsToAdd): void => {
      addItemsToPersistenceLayer(itemsToAdd)
    },
    [addItemsToPersistenceLayer]
  )

  const removeItems = useCallback<UseCartOutput['removeItems']>(
    (keys, options): void => {
      const removeItem = (key: string, removeAssociatedPortalProduct: boolean = false): void => {
        const itemToRemove = cart.items[key]
        if (!itemToRemove) return

        if (isStandardEmbroiderableProduct(itemToRemove)) {
          removeItemsFromPersistenceLayer(
            aggregateThisAndRelatedStandardEmbroideryItems(
              itemToRemove.properties._guid,
              cart.items
            )
          )
          if (itemToRemove.properties._logo_id) {
            const relatedLogoEmbroideryItems = aggregateThisAndRelatedPendingEmbroideryLogos(
              itemToRemove.properties._logo_id,
              cart.items
            )
            removeItemsFromPersistenceLayer(relatedLogoEmbroideryItems)

            // Find items with different _guid that are Scrub Tops
            const scrubTopsToAddBack = relatedLogoEmbroideryItems
              .filter(
                item =>
                  item.properties._guid !== itemToRemove.properties._guid &&
                  item.productType === 'Scrub Top'
              )
              .map(item => {
                // Remove embroidery-specific properties
                const {
                  _embroideryId,
                  _guid,
                  _logo_id,
                  _LPROP,
                  [finalSale]: FinalSale,
                  ...rest
                } = item.properties
                if (item.finalSale) {
                  rest._LPROP = _LPROP
                  rest[finalSale] = FinalSale
                }
                return {
                  ...item,
                  properties: rest,
                }
              })

            if (scrubTopsToAddBack.length > 0) {
              addItemsToPersistenceLayerWithoutEvent(scrubTopsToAddBack)
            }

            const logoSetup = shouldRemoveLogoSetup(itemToRemove.properties._logo_id, cart.items)
            if (logoSetup) {
              removeItem(logoSetup.key)
            }
          }
        } else if (isStandardEmbroideryItem(itemToRemove)) {
          /**
           * We remove this and all related embroiderable items and re-add only
           * the related embroiderable items with the embroidery-specific line
           * item properties removed. This way variants can be combined with
           * matching variants with the same custom line item properties, which
           * is how Shopify distinguishes unique line items.
           * */

          let thisAndRelatedEmbroiderableItems: CartItem[]
          if (isLogoEmbroiderySetup(itemToRemove)) {
            thisAndRelatedEmbroiderableItems = aggregateThisAndRelatedPendingEmbroideryLogos(
              itemToRemove.properties._logo_id,
              cart.items
            )
          } else {
            thisAndRelatedEmbroiderableItems = aggregateThisAndRelatedStandardEmbroideryItems(
              itemToRemove.properties._guid,
              cart.items
            )
          }

          const relatedEmbroiderableItems = thisAndRelatedEmbroiderableItems
            .filter(isStandardEmbroiderableProduct)
            .map(item => {
              const {
                _embroideryId,
                _guid,
                _LPROP,
                [finalSale]: FinalSale,
                _kitId,
                _setUid,
                _logo_id,
                ...rest
              } = item.properties
              if (_kitId) {
                const newKitId = getNewKitId(_kitId, cart.items)
                rest._kitId = newKitId
                rest._setUid = newKitId + '1'
              }
              if (item.finalSale) {
                rest._LPROP = _LPROP
                rest[finalSale] = FinalSale
              }
              return {
                ...item,
                properties: rest,
              }
            })

          if (itemToRemove.properties._kitId) {
            const restOfKitGroup = aggregateRestOfKitsNotRelatedToEmbroideryItems(
              itemToRemove.properties._kitId,
              cart.items
            )
            restOfKitGroup.forEach(item => {
              const { _kitId, _setUid, ...rest } = item.properties
              const newKitId = getNewKitId(_kitId, cart.items)
              rest._kitId = newKitId
              rest._setUid = newKitId + '1'
              relatedEmbroiderableItems.push({
                ...item,
                properties: rest,
              })
              thisAndRelatedEmbroiderableItems.push(item)
            })
          }

          if (itemToRemove.properties._logo_id) {
            const logoSetup = shouldRemoveLogoSetup(itemToRemove.properties._logo_id, cart.items)
            if (logoSetup) {
              thisAndRelatedEmbroiderableItems.push(logoSetup)
            }
          }

          removeItemsFromPersistenceLayer(thisAndRelatedEmbroiderableItems)

          const hasAssociatedPortalProduct = thisAndRelatedEmbroiderableItems.some(
            item => item.isPortalColor
          )
          // Avoid adding back associated embroidered products for portals that enforce text/logo embroidery
          if (!(removeAssociatedPortalProduct && hasAssociatedPortalProduct)) {
            addItemsToPersistenceLayerWithoutEvent(relatedEmbroiderableItems)
          }
        } else if (
          isTeamsEmbroiderableProduct(itemToRemove) ||
          isTeamsEmbroideryItem(itemToRemove)
        ) {
          const groupId = itemToRemove.properties._groupId || itemToRemove.properties._groupid
          removeItemsFromPersistenceLayer(
            aggregateThisAndRelatedTeamsEmbroideryItems(groupId, cart.items)
          )
        } else {
          removeItemsFromPersistenceLayer([itemToRemove])
        }
      }

      keys.forEach(key => {
        removeItem(key, options?.removeAssociatedPortalProduct)
      })
    },
    [
      addItemsToPersistenceLayerWithoutEvent,
      aggregateRestOfKitsNotRelatedToEmbroideryItems,
      aggregateThisAndRelatedPendingEmbroideryLogos,
      aggregateThisAndRelatedStandardEmbroideryItems,
      aggregateThisAndRelatedTeamsEmbroideryItems,
      cart.items,
      finalSale,
      getNewKitId,
      removeItemsFromPersistenceLayer,
      shouldRemoveLogoSetup,
    ]
  )

  const updateItemsQuantity = useCallback<UseCartOutput['updateItemsQuantity']>(
    (keys, calculateQuantity) => {
      const setItemQuantity = (key: string, newQuantity: number): void => {
        const itemToUpdate = cart.items[key]
        if (!itemToUpdate) return

        if (newQuantity <= 0) return removeItems([key])

        if (isStandardEmbroiderableProduct(itemToUpdate)) {
          setItemQuantitiesInPersistenceLayer(
            aggregateThisAndRelatedStandardEmbroideryItems(
              itemToUpdate.properties._guid,
              cart.items
            ),
            newQuantity
          )
        } else if (
          isTeamsEmbroiderableProduct(itemToUpdate) ||
          isTeamsEmbroideryItem(itemToUpdate)
        ) {
          const groupId = itemToUpdate.properties._groupid || itemToUpdate.properties._groupId
          setItemQuantitiesInPersistenceLayer(
            aggregateThisAndRelatedTeamsEmbroideryItems(groupId, cart.items),
            newQuantity
          )
        } else {
          setItemQuantitiesInPersistenceLayer([itemToUpdate], newQuantity)
        }
      }

      keys.forEach(key => {
        const itemToUpdate = cart.items[key]
        if (!itemToUpdate) return
        const newQuantity =
          typeof calculateQuantity === 'function'
            ? calculateQuantity(itemToUpdate.quantity)
            : itemToUpdate.quantity + calculateQuantity
        setItemQuantity(itemToUpdate.key, newQuantity)
      })
    },
    [
      aggregateThisAndRelatedStandardEmbroideryItems,
      aggregateThisAndRelatedTeamsEmbroideryItems,
      cart.items,
      removeItems,
      setItemQuantitiesInPersistenceLayer,
    ]
  )

  const proceedToCheckout = useCallback<UseCartOutput['proceedToCheckout']>(
    async options => {
      const callbackBeforeRedirect = options?.callbackBeforeRedirect
      const providedDiscountCode = options?.discountCode
      const submittedCartItems = { ...cart.items }

      const allReplacementInputs = Object.values(submittedCartItems)
        .filter(item => item.productType !== 'Embroidery') // embroidery items do not have replacement SKU's this will help reduce payload size
        .map(filteredItem => {
          return { key: filteredItem.key, quantity: filteredItem.quantity, sku: filteredItem.sku }
        })

      // Break into chunks to avoid 413 HTTP code for too long of a URL
      const replacementInputChunks = chunkItems({
        items: allReplacementInputs,
        chunkSize: 15,
      })

      const replacementPromises = replacementInputChunks.map(replacementInputChunk => {
        return figsPublicClient.query<GetReplacementVariantsQuery>({
          query: GET_REPLACEMENT_VARIANTS,
          variables: { replacementInput: replacementInputChunk },
          fetchPolicy: 'no-cache',
        })
      })

      const replacementChunkResults = await Promise.all(replacementPromises)

      replacementChunkResults.forEach(({ data: nextData, error: nextError }) => {
        if (nextError) {
          reportClientError({
            error: nextError,
            context: {
              scope: 'useCart',
              label: 'Replacement Variants Query',
            },
          })
        } else {
          nextData.replacementVariants?.forEach(
            ({ replacementShopifyId, replacementSku, requestedKey }) => {
              const itemToReplace = submittedCartItems[requestedKey]

              if (itemToReplace !== undefined) {
                const replacementItem = {
                  ...itemToReplace,
                  sku: replacementSku,
                  variantId: replacementShopifyId,
                }
                submittedCartItems[replacementItem.key] = replacementItem
              }
            }
          )
        }
      })
      let redirectUri: string | undefined
      const token = account?.tokens.auth
      const cookies = new Cookie()
      const discountFromCookie = cookies.get(cookieKeys.discountCode.key)
      const finalDiscountCode = providedDiscountCode ?? discountFromCookie
      const facebookCookieVal = cookies.get(cookieKeys.facebook.key)
      const { checkoutUrl, checkoutId } = await shopifyCartClient.createCheckoutWithCartItems({
        items: mapFigsCartItemsToShopifyCheckoutItems(Object.values(submittedCartItems)),
        regionId: region.id,
        languageGroup,
        customerShopifyToken,
        discountCodes: finalDiscountCode ? [finalDiscountCode] : undefined,
        globalCustomAttributes: cart.attributes,
      })
      window.localStorage.setItem(localStorageCartKeyForEcom, checkoutId)
      if (finalDiscountCode) checkoutUrl.searchParams.append('discount', finalDiscountCode)
      if (facebookCookieVal) checkoutUrl.searchParams.append('FBCLID', facebookCookieVal)

      redirectUri = checkoutUrl.toString()

      if (!redirectUri) {
        reportClientError({
          error: new Error('No checkout/redirect URI found.'),
          context: { scope: 'useCart', label: 'proceedToCheckout' },
        })
        return
      }

      let multipassRedirectUri: string | null = null
      if (token) {
        const { data, error } = await figsAuthedClient.query<{
          shopifyUrl: { url: string | null }
          customerShopifyUrl: { url: string | null }
        }>({
          query: MULTIPASS_AUTH_URL,
          variables: { redirectUri },
        })

        if (error) {
          reportClientError({
            error,
            context: {
              scope: 'useCart',
              label: 'multipass authentication url generation',
            },
          })
        }

        multipassRedirectUri = data?.customerShopifyUrl?.url
      }

      if (callbackBeforeRedirect) await callbackBeforeRedirect()

      reportClientEvent({
        label: 'navigated-to-checkout',
        context: {
          scope: 'useCart',
          itemCount: `${cart.itemCount}`,
          cartValue: `${cart.totalPrice}`,
        },
      })
      window.location.assign(multipassRedirectUri ?? redirectUri)
    },
    [
      cart.items,
      cart.itemCount,
      cart.totalPrice,
      cart.attributes,
      account?.tokens.auth,
      figsPublicClient,
      customerShopifyToken,
      figsAuthedClient,
      shopifyCartClient,
      region.id,
      languageGroup,
    ]
  )

  return {
    cart,
    status,
    addGlobalAttributes,
    addItems,
    removeItems,
    updateItemsQuantity,
    proceedToCheckout,
  }
}

export const CartContainer = createContainer(useCart)
CartContainer.Provider.displayName = 'CartProviderImpl'
