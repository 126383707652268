import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_BULK_COLLECTION_FILTERED = gql(`
  query FetchBulkCollectionFiltered(
    $handle: String!
    $selectedFilters: [CollectionItemFilterInput!]
    $after: String
  ) {
    optimizedCollection(handle: $handle, colorScope: ALL) {
      id
      items(selectedFilters: $selectedFilters, grouping: PRODUCT_GROUP, after: $after) {
        nodes {
          id
          handle
          color
          colorInfo {
            availability
            name
            rawName
          }
          fit
          waitlistable
          shopifyId
          finalSale
          images(first: 1) {
            nodes {
              source
            }
          }
          priceRange
          rawFit
          productGroup {
            id
            briefProductDescription
            colorGroups {
              ...ColorGroup
            }
            defaultVariant {
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                }
              }
            }
            rawCategory
            category
            description
            fits
            genderCategory
            handle
            productsForAvailability: products {
              availableSizes
              colorInfo {
                rawName
              }
              rawFit
            }
            rawFits
            sizes
            style
            sizeChart: sizeChartCG {
              ...SizeChartFragment
            }
          }
          variants {
            color
            fit
            priceExplicitFmt
            discountPriceExplicitFmt
            priceDetails {
              discountPrice {
                amount
                currency
              }
              price {
                amount
                currency
              }
            }
            rawColor
            shopifyId
            size
            sku
            soldOut
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`)
