'use client'

import styled from 'styled-components'

import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'

import { useAuthentication } from '../../_providers/AuthenticationProvider.client'

export const PrimaryNavAccountMenuBody = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;

  > * {
    white-space: nowrap;
  }
`

export const PrimaryNavAccountMenuInitialsIcon = styled.div`
  color: ${o => o.theme.color.text.primary.on.subtle};
  align-items: center;
  background: ${o => o.theme.color.fill.subtle};
  border-radius: 50%;
  display: flex;
  font-size: 9px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  text-transform: uppercase;
  width: 24px;
`

type PrimaryNavAccountLogoutLinkProps = {
  className?: string
  children: React.ReactNode
}

export const PrimaryNavAccountLogoutLink = (props: PrimaryNavAccountLogoutLinkProps) => {
  const { logOut } = useAuthentication()
  return (
    <TextLink
      as='button'
      variant={'underline-on-hover'}
      inheritTypography
      onClick={() => {
        logOut()
      }}
      {...props}
    />
  )
}
