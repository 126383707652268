import * as React from 'react'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { Body, ExclamationIconWrapper, Text } from './styles'

export const Template: React.FC<{
  style?: React.CSSProperties
  text: string
}> = ({ style, text }) => {
  const {
    magnolia: {
      general: { warning: warningCopy },
    },
  } = useTranslation()

  return (
    <Body style={style}>
      <ExclamationIconWrapper>
        <span aria-label={warningCopy} role='img'>
          ⚠️
        </span>
      </ExclamationIconWrapper>

      <Text>{text}</Text>
    </Body>
  )
}
