import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { Info, ResponsiveImage } from '@syconium/little-miss-figgy'

import { LogoDigitizationSetting } from '../../../../../../__generated__/graphql/catalog/graphql'
import { useTranslation } from '../../../../../../app/_providers/TranslationProvider.client'
import { useFixturesContext } from '../../../../../../brunswick/containers/fixtures'
import { StyledTooltip } from '../forms/shared-form-styles'

import { DetailLine, LearnMoreLink, Visual } from './styles'
import { Template } from './Template'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string
  imgSrc?: string
  logoName?: string
  logoPlacement?: string
  logoColor?: LogoDigitizationSetting | undefined
  logoNote?: string
  hasLogoSetup?: boolean
}

export const DTCLogoSneakPeek: FC<Props> = ({
  backgroundColor,
  imgSrc,
  logoName,
  logoPlacement,
  logoColor,
  logoNote,
  hasLogoSetup,
  ...rest
}) => {
  const {
    embroidery: {
      modal: { forms: fixtures },
    },
  } = useTranslation()
  const { logoDigitizationRequestedTooltip } = useFixturesContext()
  if (!imgSrc) return null
  return (
    <Template.Container className='logo-sneak-peek-container' {...rest}>
      <Template.Header backgroundColor={backgroundColor}>
        <ImageWrapper>
          <ResponsiveImage
            alt={logoName || 'CustomLogo'}
            aspectRatios={{
              sm: null,
              md: null,
            }}
            src={imgSrc}
            loading={'default'}
            widths={{ unit: 'px', sm: 100, md: 100 }}
            transparentBackground={true}
          />
        </ImageWrapper>
      </Template.Header>
      <Template.Body>
        {hasLogoSetup && (
          <LineWrapper>
            <StyledDetailLine general>
              {fixtures.dtcLogoEmbroideryForm.digitizationRequested}
            </StyledDetailLine>
            <StyledTooltip
              content={
                <>
                  <span>{logoDigitizationRequestedTooltip}</span>{' '}
                  <LearnMoreLink
                    target='_blank'
                    rel='noopener'
                    href='https://help.wearfigs.com/hc/en-us/categories/360003960333-Embroidery'
                  >
                    {fixtures.dtcLogoEmbroideryForm.learnMore}.
                  </LearnMoreLink>
                </>
              }
            >
              <button
                type='button'
                aria-label='More information about the logo digitization process'
              >
                <Info height='12px' width='12px' fill='#282828' />
              </button>
            </StyledTooltip>
          </LineWrapper>
        )}
        <DetailLine general>
          {fixtures.logoOption.logoLabel}: {logoName}
        </DetailLine>

        <DetailLine>
          {fixtures.logoOption.logoColorLabel}:{' '}
          {logoColor === 'BLACK_WHITE'
            ? fixtures.dtcLogoEmbroideryForm.logoStrings['LOGO_BLACK_WHITE']?.title
            : fixtures.dtcLogoEmbroideryForm.logoStrings['LOGO_COLOR']?.title}
        </DetailLine>

        <DetailLine>
          {fixtures.logoOption.logoPlacementLabel}: {logoPlacement}
        </DetailLine>

        {logoNote && (
          <DetailLine>
            {fixtures.logoOption.logoNoteLabel}: "{logoNote}"
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}

const ImageWrapper = styled(Visual)`
  padding: ${o => o.theme.spacing(0, 3.5)};
`

const StyledDetailLine = styled(DetailLine)`
  text-transform: uppercase;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 1px;
  margin: ${o => o.theme.spacing(1, 0)};
`

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(1)};
`
