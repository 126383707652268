import styled from 'styled-components'

import { TextLink, TypeStyle, fromMd, typeStyleMap } from '@syconium/little-miss-figgy'

import { PriceLineProps } from '../MiniCart/PriceLine'

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  margin-inline-end: 6px;
`

export const ItemCount = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-inline-start: 6px;
`

export const EmptyCartMessage = styled(TypeStyle.BodySmall)`
  display: block;
  margin: ${o => o.theme.spacing(2, 0)};
  text-align: center;
`

export const KeepShopping = styled.div`
  border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  padding: ${o => o.theme.spacing(5)};
`

export const KeepShoppingLink = styled(TextLink)`
  ${TypeStyle.css.bodyDefault};
  border-bottom: solid;
  color: ${o => o.theme.color.stroke.neutral.text.primary.on.background};
  text-transform: uppercase;
  line-height: 1em;
  font-weight: bold;
  padding-bottom: 2px;
`

export const MiniCartMessage = styled(TypeStyle.HeadlineMicro)`
  margin-bottom: 10px;
`

export const TotalSectionDivider = styled.hr`
  background: ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  border: none;
  height: 1px;
  margin: ${o => o.theme.spacing(0, 4)};

  ${fromMd} {
    margin: ${o => o.theme.spacing(0, 10)};
  }
`

export const TotalSection = styled.div`
  padding: ${o => o.theme.spacing(5, 4)};

  ${fromMd} {
    padding: ${o => o.theme.spacing(5, 10)};
  }
`

export const PriceLineLabel = styled.span`
  color: ${o => o.theme.color.text.primary.on.background};
`

export const PriceAmount = styled.span`
  color: ${o => o.theme.color.text.primary.on.background};
`

export const PriceLineWrapper = styled.div<{ $type: PriceLineProps['type'] }>`
  display: flex;
  min-height: 24px;
  align-items: center;
  justify-content: space-between;

  ${PriceLineLabel} {
    ${o => mapPriceLineTypeToStyles(o.$type)}
  }

  ${PriceAmount} {
    ${o => mapPriceLineTypeToStyles(o.$type)}
  }
`

export const CheckoutButtonWrapper = styled.div`
  padding: ${o => o.theme.spacing(0, 4, 4)};

  ${fromMd} {
    padding: ${o => o.theme.spacing(0, 10, 4)};
  }
`

export const CheckoutButtonContentWrapper = styled.div`
  display: inline-flex;
  gap: ${o => o.theme.spacing(4)};
  align-items: end;
  margin-bottom: 3px;
`

export const EmbroideryWarningContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

function mapPriceLineTypeToStyles(type: PriceLineProps['type']) {
  switch (type) {
    case 'shipping':
    case 'stipend':
    case 'discount':
      return typeStyleMap['bodyMedium']
    case 'subtotal':
      return `
        ${TypeStyle.css.bodyLarge};
        font-weight: 700;
      `
  }
}
