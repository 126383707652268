import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_KIT_VARIANTS = gql(`
  query fetchKitVariants($handles: [String!]) {
    products(handles: $handles) {
      nodes {
        variants {
          sku
          category
          color
          currencyType
          discountPrice
          fit
          rawFit
          shopifyId
          size
          upc
          handle
          price
          soldOut
          product {
            finalSale
          }
        }
      }
    }
  }
`)
