import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_SINGLE_COLLECTION = gql(`
  query getCollections(
    $name: String
    $handle: String!
    $id: ID
    $isPreview: Boolean = false
    $includeUnavailable: Boolean = false
  ) {
    collections(
      name: $name
      handle: $handle
      id: $id
      isPreview: $isPreview
      includeUnavailable: $includeUnavailable
    ) {
      nodes {
        filters {
          key
          values
        }
        handle
        id
        name
        productGroups {
          ...CollectionProductGroupFragment
        }
        productHandles
      }
    }
  }
`)
