import { useMemo } from 'react'
import styled from 'styled-components'

const StyledHighlightedText = styled.span`
  font-weight: 700;
  color: ${o => o.theme.color.text.highContrast.on.background};
`

type TextHighlightingProps = {
  text: string
  targetText: string
}

export const TextHighlighting = ({ text: allText, targetText }: TextHighlightingProps) => {
  const cleanTargetText: string = targetText.replace(/\W/g, '')

  const pattern = useMemo(() => {
    return new RegExp(cleanTargetText, 'i')
  }, [cleanTargetText])

  const [textBeforeMatch, textAfterMatch] = useMemo(() => {
    if (cleanTargetText.trim().length < 2) return [allText]
    return allText.split(pattern)
  }, [allText, cleanTargetText, pattern])

  const targetTextMatches = useMemo(() => {
    if (cleanTargetText.trim().length < 2) return null
    return allText.match(pattern)
  }, [allText, cleanTargetText, pattern])

  return (
    <>
      {textBeforeMatch}
      {targetTextMatches && <StyledHighlightedText>{targetTextMatches[0]}</StyledHighlightedText>}
      {textAfterMatch}
    </>
  )
}
