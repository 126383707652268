'use client'

import Script from 'next/script'

import { useConsentPolicy } from '../../../_providers/ConsentPolicyProvider.client'

export const AttentiveScript = () => {
  const { targeting } = useConsentPolicy()

  if (targeting !== 'allowed') return null

  return (
    <Script
      id='attentive'
      strategy='lazyOnload' // Marketing is set to lazy on load
      dangerouslySetInnerHTML={{
        __html: `
        (function(){
          var script = document.createElement('script');
          script.async = true;
          script.type = 'text/javascript';
          script.src = 'https://zannv.wearfigs.com/providers/atLabel/load';
          script.onerror = function() {
            var fallbackScript = document.createElement('script');
            fallbackScript.src = 'https://cdn.attn.tv/wearfigs/dtag.js';
            document.head.appendChild(fallbackScript);
          };
          document.head.appendChild(script);
        })();
      `,
      }}
    />
  )
}
