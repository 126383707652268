import { useLocalization } from '../../../../../app/_providers/LocalizationProvider.client'
import { useTranslation } from '../../../../../app/_providers/TranslationProvider.client'

export function useViewModel() {
  const { locale } = useLocalization()

  const {
    collections: {
      productGroupTile: { valueSignalLabel },
    },
  } = useTranslation()

  return { locale, valueSignalLabel }
}
