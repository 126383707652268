import { gql } from '../../../__generated__/graphql/catalog'

/**
 * No recursive fragments allowed.
 * NavAccordions nest 2 levels deep.
 */
export const FETCH_SITE_FIXTURES = gql(`
  query GetSiteFixtures($handle: String!, $preview: Boolean = false) {
    siteFixtures(handle: $handle, preview: $preview) {
      __typename
      id
      embroideryIcon
      enablePDPAccentColor
      handle
      miniCartMessage
      embroideryWarningMessage
      logoColorSelectionTooltip
      logoDigitizationDisclaimer
      logoDigitizationWarning
      logoLearnMorelink
      logoDigitizationRequestedTooltip
      navigation {
        __typename
        name
        desktopTabs {
          ...navTab
        }
        mobileTabs {
          ...navTab
        }
        helpLinkText
        helpLinkHref
      }
      siteWidePromo
      enableSiteWidePromo
      tooltips {
        id
        contentType
        enumValue {
          name
        }
        title
        description
      }
      footer {
        copyrightLine
        accessibilityButtonText
        desktopSections {
          __typename
          ... on NavListSection {
            ...NavListSectionFragment
          }
          ... on NavTileSection {
            ...navTileSection
          }
          ... on RawHtmlSection {
            ...RawHtmlSectionFragment
          }
          ... on NavAccordion {
            ...navAccordionSection
          }
        }
        mobileSections {
          __typename
          ... on NavListSection {
            ...NavListSectionFragment
          }
          ... on NavTileSection {
            ...navTileSection
          }
          ... on RawHtmlSection {
            ...RawHtmlSectionFragment
          }
          ... on NavAccordion {
            ...navAccordionSection
          }
        }
        socialLinks {
          ...NavListSectionItemFragment
        }
        legalLinks {
          ...NavListSectionItemFragment
        }
      }
      promoBar {
        backgroundColor
        textColor
        pages {
          id
          content
        }
      }
      regionData {
        regions {
          id
          name
          flagSrc
          shippingText
          taxAndDutyText
          welcomeShippingText
          welcomeTaxAndDutyText
          promoBarRegion {
            backgroundColor
            textColor
            pages {
              id
              content
            }
          }
        }
      }
      localeData {
        locales {
          language
          locale
        }
        name
      }
    }
  }
  fragment navTab on NavTab {
    id
    __typename
    href
    text
    textColor
    sections {
      __typename
      ... on NavListSection {
        ...NavListSectionFragment
      }
      ... on NavListGroupSection {
        ...NavListGroupSectionFragment
      }
      ... on NavTileSection {
        ...navTileSection
      }
      ... on RawHtmlSection {
        ...RawHtmlSectionFragment
      }
      ... on NavAccordion {
        ...navAccordionSection
      }
    }
    alternateModelVariant {
      validContentfulModel {
        ... on NavTab {
          href
          id
          text
          textColor
          sections {
            __typename
            ... on NavListSection {
              ...NavListSectionFragment
            }
            ... on NavListGroupSection {
              ...NavListGroupSectionFragment
            }
            ... on NavTileSection {
              ...navTileSection
            }
            ... on RawHtmlSection {
              ...RawHtmlSectionFragment
            }
            ... on NavAccordion {
              ...navAccordionSection
            }
          }
        }
      }
      name
      action
      targetCriteria {
        name
        targetLogic
        targetCriteria
        inRegion
      }
    }
  }
  fragment navAccordionSection on NavAccordion {
    id
    __typename
    variant
    sections {
      id
      header {
        ...NavListSectionItemFragment
      }
      body {
        __typename
        ... on NavListSection {
          ...NavListSectionFragment
        }
        ... on NavTileSection {
          ...navTileSection
        }
        ... on RawHtmlSection {
          ...RawHtmlSectionFragment
        }
        ... on NavAccordion {
          id
          sections {
            id
            header {
              ...NavListSectionItemFragment
            }
            body {
              __typename
              ... on NavListSection {
                ...NavListSectionFragment
              }
              ... on NavTileSection {
                ...navTileSection
              }
              ... on RawHtmlSection {
                ...RawHtmlSectionFragment
              }
            }
          }
        }
      }
      alternateModelVariant {
        action
        name
        targetCriteria {
          name
          targetLogic
          targetCriteria
          inRegion
        }
      }
    }
  }
  fragment navListGroupSection on NavListGroupSection {
    id
    __typename
    navSections {
      ...NavListSectionFragment
    }
  }
  fragment navTileSection on NavTileSection {
    id
    __typename
    tiles {
      id
      __typename
      ctaText
      href
      image
    }
  }
`)
