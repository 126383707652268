'use client'

import { useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { CartServiceApiClient } from '../../lib/cart-client'
import { ICheckoutClient } from '../../lib/cart-client/interfaces'

import { useGraphqlClients } from './GraphqlClientsProvider.client'

function useCheckoutClientImpl(checkoutClient: ICheckoutClient | undefined) {
  return checkoutClient!
}

const CheckoutClientContainer = createContainer(useCheckoutClientImpl)
CheckoutClientContainer.Provider.displayName = 'CheckoutClientProviderImpl'

export const CheckoutClientProvider = ({ children }: { children?: React.ReactNode }) => {
  const clients = useGraphqlClients()

  const checkoutClient = useMemo(() => {
    return new CartServiceApiClient({
      apolloClient: clients.authorizedClient,
    })
  }, [clients.authorizedClient])

  return (
    <CheckoutClientContainer.Provider initialState={checkoutClient}>
      {children}
    </CheckoutClientContainer.Provider>
  )
}

export const useCheckoutClient = CheckoutClientContainer.useContainer
