'use client'

import { Hovercard, HovercardAnchor, HovercardProvider, useHovercardContext } from '@ariakit/react'
import { ComponentProps, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { NextLink } from '../navigation/NextLink'

type PopoverHovercardProviderProps = Omit<
  NonNullable<ComponentProps<typeof HovercardProvider>>,
  'timeout'
>

export const PopoverHovercardProvider = ({
  showTimeout = 0,
  hideTimeout = 0,
  ...props
}: PopoverHovercardProviderProps) => {
  return <HovercardProvider showTimeout={showTimeout} hideTimeout={hideTimeout} {...props} />
}

type PopoverHovercardTriggerProps = ComponentProps<typeof HovercardAnchor>

export const PopoverHovercardTrigger = ({
  render: renderProp,
  onFocusVisible: onFocusVisibleProp,
  href: hrefProp,
  ...props
}: PopoverHovercardTriggerProps) => {
  const hovercardContext = useHovercardContext()
  const onFocusVisible = useCallback<NonNullable<PopoverHovercardTriggerProps['onFocusVisible']>>(
    arg1 => {
      onFocusVisibleProp?.(arg1)
      hovercardContext?.setOpen(true)
    },
    [hovercardContext, onFocusVisibleProp]
  )
  const render = useMemo(() => {
    return renderProp ?? (hrefProp ? <NextLink href={hrefProp} /> : <span />)
  }, [hrefProp, renderProp])
  return (
    <HovercardAnchor onFocusVisible={onFocusVisible} render={render} href={hrefProp} {...props} />
  )
}

const hovercardAnimationDuration = 175

const StyledHovercard = styled(({ fixed = true, ...props }: ComponentProps<typeof Hovercard>) => {
  return <Hovercard fixed={fixed} {...props} />
})`
  width: 300px;

  border-radius: ${o => o.theme.spacing(2)};
  padding: ${o => o.theme.spacing(2)};
  border-width: 1px;
  border-color: ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  background-color: ${o => o.theme.color.fill.background};
  color: ${o => o.theme.color.text.primary.on.background};

  position: relative;
  z-index: ${o => o.theme.zIndex.popovers};
  display: flex;
  border-style: solid;

  max-height: 70vh;
  max-height: 75svh;
  overflow: auto;

  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);

  transform-origin: top;

  transition:
    opacity ${hovercardAnimationDuration}ms ease,
    transform ${hovercardAnimationDuration}ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: scaleY(0);
  opacity: 0;
  &[data-enter='true'] {
    opacity: 1;
    transform: scaleY(1);
  }
`

type PopoverHovercardProps = ComponentProps<typeof Hovercard>

export const PopoverHovercard = ({
  gutter = 16,
  overflowPadding = 8,
  fixed = true,
  ...props
}: PopoverHovercardProps) => {
  return (
    <StyledHovercard gutter={gutter} overflowPadding={overflowPadding} fixed={fixed} {...props} />
  )
}

export const PopoverHovercardStyles = {
  Hovercard: StyledHovercard,
  hovercardAnimationDuration,
}
