'use client'

import styled from 'styled-components'

import { focusOutline } from '@syconium/little-miss-figgy/dist/utility-classes'

const StyledSkipToContentLink = styled.a`
  font-weight: 700;
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  z-index: ${o => o.theme.zIndex.background};

  &:active,
  &:focus {
    background: white;
    border-radius: 100px;
    border: 2px ${o => o.theme.color.deprecated.obsidian} solid;
    color: ${o => o.theme.color.deprecated.obsidian};
    font-size: 14px;
    height: auto;
    left: 0;
    margin: 10px auto;
    overflow: auto;
    padding: 12px 32px;
    right: 0;
    text-align: center;
    top: auto;
    width: fit-content;
    z-index: ${o => o.theme.zIndex.modals + 1};
  }

  ${focusOutline};
`

const StyledSkipToContentLinkText = styled.span`
  border-style: solid;
  border-width: 0px 0px 2px;
  padding-bottom: 2px;
`

type SkipToContentLinkProps = {
  children?: React.ReactNode
}

export const SkipToContentLink = ({ children }: SkipToContentLinkProps) => {
  const scrollToMain = (e: React.KeyboardEvent<HTMLAnchorElement>): void => {
    if (e.key === 'Enter' && typeof window !== 'undefined') {
      const main = window.document.querySelector('main')
      main?.setAttribute('tabIndex', '-1')
      main?.focus()
    }
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <StyledSkipToContentLink tabIndex={1} onKeyDown={scrollToMain}>
        <StyledSkipToContentLinkText>{children}</StyledSkipToContentLinkText>
      </StyledSkipToContentLink>
    </>
  )
}
