'use client'

import { useEffect } from 'react'

import { useSession } from '../_providers/SessionProvider.client'

const menRegex = new RegExp(/[\/-]{1}men(s)?([-\/]{1}.*)?$/)
const womenRegex = new RegExp(/[\/-]{1}women(s)?([-\/]{1}.*)?$/)

const getHrefGender = (href: string | null | undefined) => {
  if (href && womenRegex.test(href)) return 'women'
  if (href && menRegex.test(href)) return 'men'
  return undefined
}

export const useGenderPreferencedNavigation = () => {
  const { setStylePreference } = useSession()

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target
      if (typeof HTMLElement !== 'undefined' && target instanceof HTMLElement) {
        const wrappingAnchor = target.closest('a')
        const href = wrappingAnchor?.getAttribute('href')
        const hrefGender = getHrefGender(href)
        if (hrefGender) setStylePreference(hrefGender)
      }
    }

    window.addEventListener('click', clickHandler)
    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [setStylePreference])

  return null
}

export const GenderPreferencedNavigation = () => {
  useGenderPreferencedNavigation()
  return null
}
