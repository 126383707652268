'use client'

import { ComponentProps, ElementType, ReactElement, ReactNode, forwardRef } from 'react'

import {
  LoadingSpinner,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { LoadingSpinnerWrapper, StyledCtaButton } from './styles'

export type CtaButtonProps<C extends ElementType = 'button'> = PolymorphicComponentProps<
  C,
  ComponentProps<typeof StyledCtaButton>
>

// @TODO: Workaround for polymorphism with `forwardRef` - remove when we are on React 19
export type CtaButtonComponent = <C extends ElementType = 'button'>(
  props: CtaButtonProps<C>
) => ReactElement | ReactNode

export const Template: CtaButtonComponent = forwardRef(function CtaButtonTemplate<
  C extends ElementType = 'button',
>({ children, isProcessing, ...rest }: PolymorphicComponentProps<C, {}>, ref: PolymorphicRef<C>) {
  const {
    magnolia: {
      general: { loading },
    },
  } = useTranslation()
  return (
    <StyledCtaButton ref={ref} isProcessing={isProcessing} size='large' {...rest}>
      {isProcessing && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner label={loading} />
        </LoadingSpinnerWrapper>
      )}
      {children}
    </StyledCtaButton>
  )
})
