'use client'

import { createContainer } from 'unstated-next'

type SiteWideMessaging = {
  siteWideEmbroideryDisclosure: string | null
  siteWidePromotionMessage: string | null
  siteWidePrecheckoutMessage: string | null
  siteWideRegionSpecificShippingMessage: string | null
  siteWideRegionSpecificDutiesAndFeesMessage: string | null
}

const useSiteWideMessagingImpl = (siteWideMessaging: SiteWideMessaging | undefined) => {
  return siteWideMessaging!
}

const SiteWideMessagingContainer = createContainer(useSiteWideMessagingImpl)
SiteWideMessagingContainer.Provider.displayName = 'SiteWideMessagingProviderImpl'

type SiteWideMessagingProviderProps = {
  siteWideMessaging: SiteWideMessaging
  children?: React.ReactNode
}

export const SiteWideMessagingProvider = (props: SiteWideMessagingProviderProps) => {
  return (
    <SiteWideMessagingContainer.Provider initialState={props.siteWideMessaging}>
      {props.children}
    </SiteWideMessagingContainer.Provider>
  )
}
export const useSiteWideMessaging = SiteWideMessagingContainer.useContainer
