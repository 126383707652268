'use client'

import { ElementType } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { MediaAsContents } from '@syconium/little-miss-figgy/dist/components/Media'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import {
  TypeStyleBodyDefault,
  TypeStyleBodyNano,
  TypeStyleHeadlineExtraExtraSmall,
  TypeStyleHeadlineMedium,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import {
  defaultDarkTheme,
  pageLayoutMaxWidth,
} from '@syconium/little-miss-figgy/dist/themes/default'
import { PolymorphicComponentProps } from '@syconium/little-miss-figgy/dist/types/polymorphic'

import { sectionSideGutterUntilMd } from '../pages/PageSection.client'

const StyledPrimaryNavFooter = styled.footer`
  background: ${o => o.theme.color.fill.subtle};
  color: ${o => o.theme.color.text.primary.on.subtle};
  padding: 56px 0 120px;
  top: 0;
  width: 100%;

  ${fromMd} {
    padding: 64px 0 52px;
  }
`

type PrimaryNavFooterProps = {
  className?: string
  children?: React.ReactNode
}

export const PrimaryNavFooter = <T extends ElementType>(
  props: PolymorphicComponentProps<T, PrimaryNavFooterProps>
) => {
  return (
    <ThemeProvider theme={defaultDarkTheme}>
      <StyledPrimaryNavFooter {...props} />
    </ThemeProvider>
  )
}

export const PrimaryNavFooterMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${pageLayoutMaxWidth};
  gap: 64px;

  ${fromMd} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    gap: ${o => o.theme.spacing(3)};
    padding: 0 72px;
  }
`

export const PrimaryNavFooterMainLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(6)};

  ${untilMd} {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 ${sectionSideGutterUntilMd}px;
  }
`

export const PrimaryNavFooterMainRight = styled.div`
  text-align: left;
  display: block;

  ${untilMd} {
    width: 100%;
  }
`

export const PrimaryNavFooterPostfix = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${pageLayoutMaxWidth};
  gap: ${o => o.theme.spacing(6)};

  ${untilMd} {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 56px ${sectionSideGutterUntilMd}px 0px;
  }

  ${fromMd} {
    align-items: flex-start;
    border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.subtle};
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    padding: 40px 72px 0;
  }
`

export const PrimaryNavFooterPostfixLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(3)};

  ${untilMd} {
    margin-bottom: ${o => o.theme.spacing(3)};
    align-items: center;
    justify-content: center;
  }
`

export const PrimaryNavFooterPromoTagline = styled(TypeStyleHeadlineMedium)`
  ${untilMd} {
    font-size: 32px;
  }
  ${fromMd} {
    font-size: 32px;
    width: min-content;
  }
`

export const PrimaryNavFooterPromoHashtag = styled(TypeStyleHeadlineExtraExtraSmall)`
  letter-spacing: 5px;
`

export const PrimaryNavFooterSocialIconDivider = styled.div`
  width: ${o => o.theme.spacing(8)};
  ${fromMd} {
    width: ${o => o.theme.spacing(2)};
  }
`

const StyledPrimaryNavFooterSocialIcon = styled(ResponsiveImage)`
  object-fit: fill;
  display: inline-block;
  height: ${o => o.theme.spacing(5)};
  min-width: 20px;
  width: auto;
  opacity: 0.5;

  ${fromMd} {
    height: ${o => o.theme.spacing(3)};
  }

  &:hover {
    opacity: 1;
  }
`

type PrimaryNavFooterSocialIconProps = {
  src: string
  text: string
}

export const PrimaryNavFooterSocialIcon = ({ src, text }: PrimaryNavFooterSocialIconProps) => {
  return (
    <>
      <StyledPrimaryNavFooterSocialIcon
        src={src}
        alt=''
        aspectRatios={{ sm: null, md: null }}
        widths={{ unit: 'px', sm: 20, md: 20 }}
        loading='lazy'
        transparentBackground={true}
      />
      <ScreenReaderOnly>{text}</ScreenReaderOnly>
    </>
  )
}

const StyledPrimaryNavFooterLinkIcon = styled(ResponsiveImage)`
  object-fit: fill;
  display: inline-block;
  width: 25px;
`

const StyledPrimaryNavFooterTextWithIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
  justify-content: flex-start;
  > * {
    flex-grow: 0;
    flex-basis: auto;
  }
`

type PrimaryNavFooterLinkIconProps = {
  text: React.ReactNode
  iconSrc?: string | null
}

export const PrimaryNavFooterTextWithIcon = ({ iconSrc, text }: PrimaryNavFooterLinkIconProps) => {
  return (
    <StyledPrimaryNavFooterTextWithIconWrapper>
      <MediaAsContents lessThan='md'>
        <TypeStyleBodyDefault asTag='span'>{text}</TypeStyleBodyDefault>
      </MediaAsContents>
      <MediaAsContents greaterThanOrEqual='md'>
        <TypeStyleBodyNano asTag='span'>{text}</TypeStyleBodyNano>
      </MediaAsContents>
      {iconSrc ? (
        <StyledPrimaryNavFooterLinkIcon
          src={iconSrc}
          alt=''
          aspectRatios={{ sm: null, md: null }}
          widths={{ unit: 'px', sm: 25, md: 25 }}
          loading='lazy'
          transparentBackground={true}
        />
      ) : null}
    </StyledPrimaryNavFooterTextWithIconWrapper>
  )
}

export const PrimaryNavFooterListItem = styled.li<{ $variant: 'text' | 'link' | 'heading' }>`
  ${o => (o.$variant === 'link' ? 'padding: 6px 0' : null)};
  ${fromMd} {
    ${o => (o.$variant === 'link' ? 'padding: 4px 0' : null)};
  }

  ${o => (o.$variant === 'heading' ? 'margin-bottom: 24px' : null)};
  &:not(:first-of-type) {
    ${o => (o.$variant === 'heading' ? 'margin-top: 24px' : null)};
  }

  &:last-of-type {
    margin-bottom: 24px;
  }
`

export const PrimaryNavFooterLinkColumn = styled.div`
  display: inline-block;
  max-width: 180px;
  margin-left: ${o => o.theme.spacing(8)};

  ${fromMd} {
    float: inline-start;
  }
`
