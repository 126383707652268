'use client'

import { useMemo } from 'react'

import {
  ShopRegion,
  SupportedLocaleIdentifier,
  languageNamesByLocale,
} from '@syconium/weeping-figs'

import { gql } from '../../__generated__/graphql/catalog/gql'
import { useFixturesContext } from '../../brunswick/containers/fixtures'
import { useLocalization } from '../_providers/LocalizationProvider.client'

import { useNextQuery } from './useNextQuery'

type UseTranslatedLocalizationProps = {
  localeData: ReturnType<typeof useFixturesContext>['localeData']
  regionData: ReturnType<typeof useFixturesContext>['regionData']
}

export const useTranslatedLocalization = (props?: UseTranslatedLocalizationProps) => {
  const wasProvidedDataManually = props?.localeData && props.regionData ? true : false
  const localization = useLocalization()
  const { data: contentfulQueryData, status: contentfulQueryStatus } = useNextQuery(
    gql(`
        query GetTranslationsForLocalizationState {
          siteLocalizationOptions(siteFixturesHandle: "default") {
            id
            __typename
            locales {
              id
              __typename
              language
              locale
            }
            regions {
              id
              __typename
              name
              flagSrc
            }
          }
        }
      `),
    {
      skip: wasProvidedDataManually,
    }
  )

  const localeData = useMemo(() => {
    return props?.localeData?.locales ?? contentfulQueryData?.siteLocalizationOptions.locales ?? []
  }, [contentfulQueryData?.siteLocalizationOptions.locales, props?.localeData?.locales])

  const regionData = useMemo(() => {
    return props?.regionData?.regions ?? contentfulQueryData?.siteLocalizationOptions.regions ?? []
  }, [contentfulQueryData?.siteLocalizationOptions.regions, props?.regionData?.regions])

  const data = useMemo(() => {
    const languageNameForLocale = (untranslatedLocale: SupportedLocaleIdentifier) => {
      return (
        localeData.find(locale => locale.locale === untranslatedLocale)?.language ??
        languageNamesByLocale[untranslatedLocale]
      )
    }

    const translateLanguages = (untranslatedLanguage: ShopRegion['languages']) => {
      return Object.entries(untranslatedLanguage)
        .map(([language, regionalLanguage]) => {
          return {
            ...regionalLanguage,
            language,
            name: languageNameForLocale(regionalLanguage.locale),
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }

    const translateRegion = (untranslatedRegion: ShopRegion) => {
      const flagSrc =
        regionData.find(region => region.id === untranslatedRegion.id)?.flagSrc ??
        untranslatedRegion.flagSrc

      const name =
        regionData.find(region => region.id === untranslatedRegion.id)?.name ??
        untranslatedRegion.name

      return {
        ...untranslatedRegion,
        flagSrc,
        name,
        languagesArray: translateLanguages(untranslatedRegion.languages),
      }
    }

    return {
      regions: localization.regions
        .map(region => translateRegion(region))
        .sort((a, b) => a.name.localeCompare(b.name)),
      region: translateRegion(localization.region),
      locale: localization.locale,
      languageName: languageNameForLocale(localization.locale),
    }
  }, [localization, localeData, regionData])

  const status = wasProvidedDataManually ? 'resolved' : contentfulQueryStatus

  return {
    data,
    status,
    changeLocale: localization.changeLocale,
  }
}
