'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import { focusOutline } from '@syconium/little-miss-figgy'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants'
import { pageLayoutMaxWidth } from '@syconium/little-miss-figgy/dist/constants/layout'
import { timingSlowerMs } from '@syconium/little-miss-figgy/dist/constants/timing'
import { useScrollDirection } from '@syconium/little-miss-figgy/dist/lib/hooks/useScrollDirection'

import { useSession } from '../../_providers/SessionProvider.client'
import { NextLink } from '../navigation/NextLink'

export const primaryNavHeaderMinHeightPixels = 60

type BaseProps = {
  children?: React.ReactNode
  className?: string
  ariaLabel: string
}

const StyledHeaderStickyElement = styled.div<{ $shiftUpOnMobile?: boolean }>`
  background-color: ${o => o.theme.color.fill.background};
  position: sticky;
  top: 0px;
  z-index: ${o => o.theme.zIndex.sticky};
  transition: top ${timingSlowerMs}ms ease;

  ${fromMd} {
    padding: 0px ${o => o.theme.spacing(3)};
  }

  ${o =>
    o.$shiftUpOnMobile &&
    `
    ${untilMd} {
      top: -50vh;
    }
  `};
`

type HeaderStickyElementProps = ComponentProps<typeof StyledHeaderStickyElement>

export const HeaderStickyElement = ({ $shiftUpOnMobile, ...props }: HeaderStickyElementProps) => {
  const scrollDirection = useScrollDirection()
  return (
    <StyledHeaderStickyElement
      $shiftUpOnMobile={$shiftUpOnMobile ?? scrollDirection === 'down'}
      {...props}
    />
  )
}

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: ${pageLayoutMaxWidth};
  margin: 0px auto;
`

export const LeftNav = styled(({ children, className, ariaLabel }: BaseProps) => {
  return (
    <nav aria-label={ariaLabel} className={className}>
      {children}
    </nav>
  )
})`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  align-items: center;

  ${untilMd} {
    flex-basis: 30%;
  }
`

export const CenterNav = styled(({ children, className, ariaLabel }: BaseProps) => {
  return (
    <nav aria-label={ariaLabel} className={className}>
      {children}
    </nav>
  )
})`
  flex-grow: 2.25;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: stretch;
  ${untilMd} {
    flex-wrap: wrap;
  }
  ${fromMd} {
    flex-wrap: nowrap;
  }
`

export const RightNav = styled(({ children, className, ariaLabel }: BaseProps) => {
  return (
    <nav aria-label={ariaLabel} className={className}>
      {children}
    </nav>
  )
})`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  ${untilMd} {
    flex-basis: 30%;
  }
`

export const FullRow = styled(({ children, className }: Omit<BaseProps, 'ariaLabel'>) => {
  return <div className={className}>{children}</div>
})`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
`

type PrimaryNavHeaderHomeLogoProps = {
  className?: string
}

export const PrimaryNavHeaderHomeLogo = styled((props: PrimaryNavHeaderHomeLogoProps) => {
  const { attributes } = useSession()
  return (
    <NextLink
      href={
        attributes.stylePreference === 'men'
          ? '/pages/men-home'
          : attributes.stylePreference === 'women'
          ? '/pages/women-home'
          : '/'
      }
      {...props}
    >
      <svg height='26px' width='91px' viewBox='0 0 91 26'>
        <path
          d='M22.873.876v24.45h2.59v-11.02h9.111v-2.375h-9.11v-8.68h12.298V.876zm20.57 24.451h2.588V.877h-2.588zM82.8 11.29c-3.373-.997-5.953-1.949-5.961-4.313 0-1.247.542-2.293 1.577-3.028.983-.699 2.38-1.08 3.939-1.08 2.681 0 5.564 1.12 5.612 4.263l.006.17h2.5l-.003-.174C90.422 3.104 87.237.504 82.355.504c-4.887 0-8.049 2.6-8.049 6.624 0 3.917 3.314 5.27 7.645 6.629l.113.04c3.36 1.062 6.26 1.98 6.26 4.772.002 1.453-.472 2.6-1.408 3.405-1.023.879-2.575 1.326-4.608 1.326-4.378 0-6.01-2.505-6.076-4.654l-.004-.166-2.5-.002.008.177c.066 3.394 2.805 7.043 8.646 7.043 5.385 0 8.473-2.6 8.473-7.133 0-4.367-3.872-6.076-8.055-7.274m-21.272 3.85h4.525v3.394c0 .034 0 .058-.003.085l-.016.112c-.4 2.803-2.274 4.463-5.27 4.673l-.038.003c-.047.002-.096.002-.152.005a5.867 5.867 0 01-.356.012c-3.76-.034-5.915-2.181-5.915-5.894V8.68c0-3.715 2.156-5.862 5.915-5.892.109 0 .214.001.343.01.048 0 .104.004.155.004l.047.005c2.915.203 4.781 1.784 5.243 4.454l.025.144h2.613l-.028-.195C68.064 2.881 65.025.4 60.28.4h-.053l-.015.176L60.188.4h-.04c-5.284 0-8.438 3.095-8.438 8.28v8.85c0 5.18 3.154 8.273 8.439 8.273l.054-.002.077.002c4.845 0 7.891-2.562 8.367-7.047v-5.904h-7.119v2.29zM6.957 11.6V8.128h3v3.476c3.423.05 3.472 2.997 3.472 2.997H9.957v3.474h-3v-3.474C3.799 14.4 3.482 11.6 3.482 11.6h3.475zM4.836 25.213c1.025.393 2.204.59 3.519.59h.203c1.34 0 2.543-.208 3.587-.61 2.893-1.085 4.718-3.617 4.718-7.61V8.617c0-4.017-1.853-6.558-4.784-7.628C11.053.6 9.877.4 8.559.4h-.204c-1.34 0-2.545.207-3.584.612C1.875 2.097.05 4.627.05 8.617v8.966c0 4.022 1.854 6.563 4.785 7.63z'
          fill='#222228'
          fillRule='evenodd'
        ></path>
      </svg>
    </NextLink>
  )
})`
  box-sizing: border-box;
  padding: ${o => o.theme.spacing(3)};
  min-height: ${primaryNavHeaderMinHeightPixels}px;
  display: inline-flex;
  align-items: center;

  ${focusOutline};
`
