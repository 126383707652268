import { FC, HTMLAttributes } from 'react'

import { useTranslation } from '../../../../../../app/_providers/TranslationProvider.client'

import { DetailLine, Icon, IconEmbroideryVisual, Initials } from './styles'
import { Template } from './Template'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string
  iconImageSource?: string
  iconInitials?: string
  iconInitialsColor?: string
  iconDesignName?: string
  iconPlacement?: string
}

export const IconSneakPeek: FC<Props> = ({
  backgroundColor,
  iconImageSource,
  iconInitials,
  iconInitialsColor,
  iconDesignName,
  iconPlacement,
  ...rest
}) => {
  const {
    embroidery: {
      summary: { iconInitialsColorLabel, iconInitialsLabel, iconNameLabel, iconPlacementLabel },
    },
  } = useTranslation()

  const displayIconNameTitle = iconInitials || iconPlacement

  if (!iconPlacement && !iconImageSource) return null

  return (
    <Template.Container {...rest}>
      <Template.Header backgroundColor={backgroundColor}>
        {iconImageSource && (
          <IconEmbroideryVisual>
            <Icon
              alt={iconDesignName ?? 'Embroidery Icon Sneak Peak'}
              src={iconImageSource}
              widths={{ unit: 'px', sm: 36, md: 36 }}
              aspectRatios={{ sm: null, md: null }}
              loading='default'
              transparentBackground={true}
            />

            {iconInitials && (
              <Initials initialsColor={iconInitialsColor?.toLowerCase()}>{iconInitials}</Initials>
            )}
          </IconEmbroideryVisual>
        )}
      </Template.Header>

      <Template.Body>
        {iconDesignName && (
          <DetailLine general>
            {displayIconNameTitle ? `${iconNameLabel}: ` : ''}
            {iconDesignName}
          </DetailLine>
        )}

        {iconInitials && (
          <DetailLine general>
            {iconInitialsLabel}: {iconInitials}
          </DetailLine>
        )}

        {iconInitials && iconInitialsColor && (
          <DetailLine>
            {iconInitialsColorLabel}: {iconInitialsColor}
          </DetailLine>
        )}

        {iconPlacement && (
          <DetailLine>
            {iconPlacementLabel}: {iconPlacement}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}
