'use client'

import Script from 'next/script'
import { FC } from 'react'

import { useConsentPolicy } from '../../../_providers/ConsentPolicyProvider.client'

export const FriendBuyScript: FC = () => {
  const { functional } = useConsentPolicy()

  if (functional !== 'allowed') return null

  return (
    <Script
      id='friendbuy-script'
      strategy='lazyOnload' // Marketing is set to lazy on load
      dangerouslySetInnerHTML={{
        __html: `
          window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
      
          // registers your merchant using your merchant ID found in the
          // retailer app https://retailer.friendbuy.io/settings/general
          friendbuyAPI.merchantId = "55773582-8d2f-4f00-9a3a-559bee7b2831";
          friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);
      
          // load the merchant SDK and your campaigns
          (function(f, r, n, d, b, u, y) {
            while ((u = n.shift())) {
              (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
              b.async = 1;
              b.src = u;
              y.parentNode.insertBefore(b, y);
            }
          })(document, "script", [
            "https://static.fbot.me/friendbuy.js",
            "https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
          ]);
        `,
      }}
    />
  )
}
