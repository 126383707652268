import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { CartItem } from '../../types/figs'
import { CheckoutLineItemInput, CustomAttribute } from '../../types/shopify-storefront-api'

import { RegionToShippingAddress, ShippingAddress } from './types'

export function mapPropertiesToCustomAttributes(
  properties: CartItem['properties']
): ReadonlyArray<CustomAttribute> {
  return Object.keys(properties).reduce((customAttributes, key) => {
    const value: string | undefined = properties[key]
    return value ? [...customAttributes, { key, value }] : customAttributes
  }, [] as ReadonlyArray<CustomAttribute>)
}

export function mapCartItemToCheckoutLineItemInput(
  variantGid: string,
  quantity: number,
  properties?: CartItem['properties']
): CheckoutLineItemInput {
  return {
    customAttributes: mapPropertiesToCustomAttributes(properties ?? {}),
    quantity,
    variantId: btoa(variantGid),
  }
}

export function mapFigsCartItemsToShopifyCheckoutItems(
  figsCartItems: CartItem[]
): CheckoutLineItemInput[] {
  return figsCartItems
    .map(o => {
      return mapCartItemToCheckoutLineItemInput(o.variantId, o.quantity, {
        ...o.properties,
      })
    })
    .flat()
}

const shopifyAddressReqsDefaults: RegionToShippingAddress = {
  AE: {
    city: 'na',
    country: 'united arab emirates',
    province: 'dubai',
  },
  AT: {
    city: 'na',
    country: 'austria',
    province: 'na',
  },
  AU: {
    city: 'na',
    country: 'australia',
    province: 'queensland',
  },
  BE: {
    city: 'na',
    country: 'belgium',
    province: 'na',
  },
  CA: {
    city: 'na',
    country: 'canada',
    province: 'ontario',
  },
  DE: {
    city: 'na',
    country: 'germany',
    province: 'na',
  },
  ES: {
    city: 'na',
    country: 'spain',
    province: 'toledo',
  },
  FI: {
    city: 'na',
    country: 'finland',
    province: 'na',
  },
  FR: {
    city: 'na',
    country: 'france',
    province: 'na',
  },
  GB: {
    city: 'na',
    country: 'united kingdom',
    province: 'na',
  },
  IE: {
    city: 'na',
    country: 'ireland',
    province: 'cork',
  },
  IL: {
    city: 'na',
    country: 'israel',
    province: 'na',
  },
  IT: {
    city: 'na',
    country: 'italy',
    province: 'na',
  },
  KW: {
    city: 'na',
    country: 'kuwait',
    province: 'al asimah',
  },
  LU: {
    city: 'na',
    country: 'luxembourg',
    province: 'na',
  },
  MX: {
    city: 'na',
    country: 'mexico',
    province: 'df',
  },
  NL: {
    city: 'na',
    country: 'netherlands',
    province: 'na',
  },
  NZ: {
    city: 'na',
    country: 'new zealand',
    province: 'na',
  },
  PH: {
    city: 'na',
    country: 'philippines',
    province: 'metro manila',
  },
  PL: {
    city: 'na',
    country: 'poland',
    province: 'na',
  },
  PT: {
    city: 'na',
    country: 'portugal',
    province: 'lisboa',
  },
  SA: {
    city: 'na',
    country: 'saudi arabia',
    province: 'na',
  },
  SG: {
    city: 'na',
    country: 'singapore',
    province: 'na',
  },
  US: {
    city: 'na',
    country: 'united states',
    province: 'california',
  },
  CR: {
    city: 'na',
    country: 'costa rica',
    province: 'san Jose',
  },
  NO: {
    city: 'na',
    country: 'norway',
    province: 'na',
  },
  CH: {
    city: 'na',
    country: 'switzerland',
    province: 'na',
  },
  PA: {
    city: 'na',
    country: 'panama',
    province: 'panama',
  },
  DO: {
    city: 'na',
    country: 'dominican republic',
    province: 'na',
  },
  CO: {
    city: 'na',
    country: 'colombia',
    province: 'bogota',
  },
  BR: {
    city: 'na',
    country: 'brazil',
    province: 'rio de janeiro',
  },
  TR: {
    city: 'na',
    country: 'turkey',
    province: 'na',
  },
  SE: {
    city: 'na',
    country: 'sweden',
    province: 'na',
  },
  DK: {
    city: 'na',
    country: 'denmark',
    province: 'na',
  },
  JP: {
    city: 'na',
    country: 'japan',
    province: 'tokyo',
  },
  KR: {
    city: 'na',
    country: 'south korea',
    province: 'seoul',
  },
}

export function shippingAddressForRegion(regionId: SupportedRegionIdentifier): ShippingAddress {
  return shopifyAddressReqsDefaults[regionId] ?? shopifyAddressReqsDefaults['US']
}
