import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_FILTERED_PRODUCT_REVIEWS = gql(`
  query getFilteredReviews(
    $filterInput: FilterInput!
    $pageNum: Int!
    $numPerPage: Int!
  ) {
    filteredReviews(
      filterInput: $filterInput
      pageNum: $pageNum
      numPerPage: $numPerPage
    ) {
      tag
      pagination {
        numPages
        pageNum
        numPerPage
      }
      reviews {
        reviewer
        title
        content
        createdAt
        score
        customFields {
          key
          title
          value
          formId
        }
        imageUrls
      }
    }
  }
`)
