'use client'

import { useServerInsertedHTML } from 'next/navigation'
import React, { useState } from 'react'
import { ServerStyleSheet, StyleSheetManager } from 'styled-components'

import { isPropValid } from '@syconium/little-miss-figgy'
import { ToplevelFigsThemeProvider } from '@syconium/little-miss-figgy/dist/themes/provider'

import { GlobalStyles } from '../../brunswick/styles/global'

const StyledComponentsRegistry = ({ children }: { children: React.ReactNode }) => {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet())

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement()
    styledComponentsStyleSheet.instance.clearTag()
    return <>{styles}</>
  })

  return (
    <StyleSheetManager
      sheet={typeof window === 'undefined' ? styledComponentsStyleSheet.instance : undefined}
      enableVendorPrefixes
      shouldForwardProp={isPropValid}
    >
      {children}
    </StyleSheetManager>
  )
}

export type StyledComponentsProviderProps = {
  children?: React.ReactNode
}

export const StyledComponentsProvider = ({ children }: StyledComponentsProviderProps) => {
  return (
    <StyledComponentsRegistry>
      <ToplevelFigsThemeProvider>
        <GlobalStyles />
        {children}
      </ToplevelFigsThemeProvider>
    </StyledComponentsRegistry>
  )
}
