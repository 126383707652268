'use client'

import { useField, useFormikContext } from 'formik'
import { ComponentProps, useId } from 'react'

import { ErrorMessage } from '@syconium/little-miss-figgy/dist/components/Input/InputErrorMessage'
import { Select } from '@syconium/little-miss-figgy/dist/components/Select/Select'

export type FormSelectProps = {
  name: string
  required?: ComponentProps<typeof Select>['required']
  label: ComponentProps<typeof Select>['label']
  renderSelectValue: ComponentProps<typeof Select>['renderSelectValue']
  children?: React.ReactNode
  onChange?: ComponentProps<typeof Select>['onChange']
}

export const FormSelect = ({
  name,
  label,
  children,
  renderSelectValue,
  required,
  onChange,
}: FormSelectProps) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta, fieldCommands] = useField(name)
  const hasError = meta.error !== undefined && meta.error.length > 0
  const displayError = meta.touched && hasError
  const onChangeCallback = (newValue: string) => {
    fieldCommands.setTouched(true)
    setFieldValue(name, newValue)
    onChange?.(newValue)
  }
  const errorId = useId()

  return (
    <>
      <Select
        label={label}
        required={required}
        errorId={errorId}
        isInvalid={!!displayError}
        value={field.value}
        onChange={onChangeCallback}
        renderSelectValue={renderSelectValue}
      >
        {children}
      </Select>
      {displayError ? <ErrorMessage id={errorId}>{meta.error}</ErrorMessage> : null}
    </>
  )
}
