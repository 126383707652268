import { gql } from '../../../__generated__/graphql/catalog'

export const GIFT_COLLECTION_ITEMS_FRAGMENT = gql(`
  fragment GiftCollectionItemsFragment on Collection {
    items(selectedFilters: $selectedFilters, first: $first, after: $after, grouping: PRODUCT_GROUP) {
      nodes {
        ...CollectionItemProduct
      }
      numItems
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

export const FETCH_GIFT_COLLECTION_ITEMS = gql(`
  query fetchGiftCollectionItems(
    $handle: String!
    $first: Int!
    $after: String
    $selectedFilters: [CollectionItemFilterInput!]
  ) {
    collection: optimizedCollection(handle: $handle, includeUnavailable: false) {
      id
      colors {
        name
        hexCodes
        swatchUrl
      }
      ...GiftCollectionItemsFragment
    }
  }
`)
