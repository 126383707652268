'use client'

import { styled } from 'styled-components'

import { fromLg, fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants'

import { gql } from '../../../__generated__/graphql/catalog'
import { NavSectionsFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'

import { PrimaryNavAccordionSection } from './PrimaryNavAccordionSection.client'
import { PrimaryNavItemListGroupSection } from './PrimaryNavItemListGroupSection.client'
import { PrimaryNavItemListSection } from './PrimaryNavItemListSection.client'
import { PrimaryNavTileSection } from './PrimaryNavTileSection.client'

export const navSectionQueryFragment = gql(`
  fragment NavSectionsFragment on NavSection {
    __typename
    ... on NavListSection {
      ...NavListSectionFragment
    }
    ... on NavListGroupSection {
      ...NavListGroupSectionFragment
    }
    ... on NavTileSection {
      ...NavTileSectionFragment
    }
    ... on NavAccordion {
      ...NavAccordionSectionFragment
    }
  }
`)

export const PrimaryNavContentfulSectionGap = styled.div`
  display: block;
  height: ${o => o.theme.spacing(6)};
`

export const PrimaryNavContentfulSections = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${o => o.theme.spacing(6)};
  > * {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }

  ${untilMd} {
    flex-direction: column;
  }

  ${fromMd} {
    gap: ${o => o.theme.spacing(8)};
  }

  ${fromLg} {
    column-gap: ${o => o.theme.spacing(16)};
  }
`

export const PrimaryNavContentfulSection = (props: NavSectionsFragmentFragment) => {
  switch (props.__typename) {
    case 'NavAccordion':
      return <PrimaryNavAccordionSection {...props} />
    case 'NavListSection':
      return <PrimaryNavItemListSection {...props} />
    case 'NavListGroupSection':
      return <PrimaryNavItemListGroupSection {...props} />
    case 'NavTileSection':
      return <PrimaryNavTileSection {...props} />
    default:
      return null
  }
}
