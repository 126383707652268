import { gql } from '../../../../__generated__/graphql/catalog'

export const GET_GUEST_ORDER_DETAILS = gql(`
  query getGuestOrderDetails($name: String!, $email: String!) {
    guestOrderDetails(name: $name, email: $email) {
      discountCode
      netPayment
      status
      subtotal
      total
      totalRefunded
      totalShipping
      totalTax
      transactions {
        accountNumber
        amount
        authorizationCode
        errorCode
        formattedGateway
        gateway
        id
        kind
        paymentIcon {
          altText
          url
        }
        processedAt
        status
      }
      paymentMethod {
        description
        expiration
        icon {
          url
        }
      }
      shippingAddress {
        phone
        address1
        address2
        city
        zip
        province
        country
      }
      createdAt
      id
      displayFinancialStatus
      displayFulfillmentStatus
      fulfillments {
        displayStatus
        deliveredAt
        trackingInfo {
          url
          number
          company
        }
      }
      cancellable
      cancelledAt
      fulfillmentOrderStatus
      name
      total
      lineItems {
        nodes {
          id
          title
          quantity
          sku
          total
          name
          image {
            url
          }
          fulfillmentStatus
          customAttributes {
            key
            value
          }
          variant {
            size
            fit
            colorInfo {
              name
            }
          }
        }
      }
      refunds {
        refundLineItems {
          nodes {
            lineItem {
              id
            }
          }
        }
      }
    }
  }
`)
