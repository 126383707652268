'use client'

import { Tab, TabList, TabPanel, TabProvider } from '@ariakit/react'
import { ComponentProps } from 'react'
import styled from 'styled-components'

import { focusOutlineInset } from '@syconium/little-miss-figgy'
import { TypeStyleHeadlineExtraSmall } from '@syconium/little-miss-figgy/dist/components/TypeStyle'

import { PrimaryNavMenuButton } from './PrimaryNavMenus.client'

export const PrimaryNavTabProvider = TabProvider

export const PrimaryNavTabList = styled((props: ComponentProps<typeof TabList>) => {
  return <TabList {...props} />
})`
  background-color: ${o => o.theme.color.fill.background};
  color: ${o => o.theme.color.text.primary.on.background};
  border-bottom: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  display: flex;
  align-items: stretch;
  justify-content: center;
`

export const PrimaryNavTab = ({ render, ...props }: ComponentProps<typeof Tab>) => {
  return (
    <Tab
      render={
        render
          ? render
          : htmlProps => {
              return (
                <PrimaryNavMenuButton {...htmlProps}>
                  <TypeStyleHeadlineExtraSmall>
                    <b>{htmlProps.children}</b>
                  </TypeStyleHeadlineExtraSmall>
                </PrimaryNavMenuButton>
              )
            }
      }
      {...props}
    />
  )
}

export const PrimaryNavTabPanel = styled((props: ComponentProps<typeof TabPanel>) => {
  return <TabPanel {...props} />
})`
  background-color: ${o => o.theme.color.fill.background};
  color: ${o => o.theme.color.text.primary.on.background};

  ${focusOutlineInset};
`
