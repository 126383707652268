import * as React from 'react'

import { Animate } from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../../../../app/_providers/TranslationProvider.client'

import { Badge } from './Badge'
import { IProps } from './IProps'
import { BadgeWrapper, Container } from './styles'

export const BadgesSection: React.FC<IProps> = ({ badges, leftOffset, topOffset, className }) => {
  const { cart: miniCartCopy } = useTranslation()

  const badgeContent = badges.map(text => {
    const isFinalSale = text.toLowerCase() === miniCartCopy.finalSale?.toLowerCase()
    return (
      <Animate.FadeIn key={text}>
        <BadgeWrapper isFinalSale={isFinalSale}>
          <Badge text={text} isFinalSale={isFinalSale} />
        </BadgeWrapper>
      </Animate.FadeIn>
    )
  })

  return (
    <Container {...{ leftOffset, topOffset }} className={className}>
      {badges.length > 0 ? (
        badgeContent
      ) : (
        <BadgeWrapper>
          <Badge isPlaceholder />
        </BadgeWrapper>
      )}
    </Container>
  )
}
