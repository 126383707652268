import { gql } from '../../../__generated__/graphql/catalog'

export const FetchShopTheLook = gql(`
  query FetchShopTheLook($handle: String!) {
    product(handle: $handle) {
      shopTheLook {
        nodes {
          color
          colorInfo {
            availability
            name
            rawName
          }
          fit
          swPromoEligible
          waitlistable
          finalSale
          handle
          images {
            nodes {
              kind
              source
              color
            }
          }
          priceRange
          productGroup {
            id
            colorGroups {
              ...ColorGroup
            }
            colors {
              availability
              hexCodes
              name
              rawName
              swatchUrl
            }
            defaultVariant {
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                }
              }
            }
            description
            fits
            handle
            productsForAvailability: products {
              availableSizes
              colorInfo {
                rawName
              }
              rawFit
              swPromoEligible
            }
            rawFits
            sizes
            style
            sizeChart: sizeChartCG {
              ...SizeChartFragment
            }
          }
          variants {
            category
            color
            fit
            priceExplicitFmt
            discountPriceExplicitFmt
            priceDetails {
              discountPrice {
                amount
              }
              price {
                amount
                currency
              }
            }
            rawColor
            shopifyId
            size
            sku
            soldOut
          }
        }
      }
    }
  }
`)
