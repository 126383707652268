import { produce } from 'immer'
import isEqual from 'lodash-es/isEqual'
import omit from 'lodash-es/omit'
import { useCallback, useEffect, useState } from 'react'
import { v4 as generateUuid } from 'uuid'

import { useBrowserStoredState } from '@syconium/little-miss-figgy'

import { CustomAttribute } from '../../../types/shopify-storefront-api'
import { QueryStatus } from '../../hooks/types'

export const localCartStorageKey = `@figs:localCartItems`
export const localCartIdStorageKey = `@figs:localCartId`
export const localCartAttributesStorageKey = `@figs:localCartAttributes`

export type LocalCartItem = {
  key: string
  productType: string
  properties: Record<string, string | undefined>
  quantity: number
  sku: string
  swPromoEligible?: boolean | undefined
  variantId: string
  isPortalColor?: boolean
  isPortalLogo?: boolean
}

export type LocalCart = Record<string, LocalCartItem>

type AddLocalCartItems = (newItems: Omit<LocalCartItem, 'key'>[]) => void
type RemoveLocalCartItems = (keys: string[]) => void
type SetLocalCartItemsQuantities = (keys: string[], newQuantity: number) => void
type AddLocalCartAttributes = (attributes: CustomAttribute[]) => void

export function useLocalCart() {
  const [status, setStatus] = useState<Exclude<QueryStatus, 'rejected' | 'idle'>>('pending')

  const { state: browserStoredCartItems, setState: _setBrowserStoredCartItems } =
    useBrowserStoredState<LocalCart>({
      storageKey: localCartStorageKey,
      initialState: {},
      storage: globalThis.localStorage,
    })

  const setBrowserStoredCartItems = useCallback<typeof _setBrowserStoredCartItems>(
    newItems => {
      if (browserStoredCartItems !== newItems) {
        setStatus('pending')
        _setBrowserStoredCartItems(newItems)
      }
    },
    [_setBrowserStoredCartItems, browserStoredCartItems]
  )

  const { state: browserStoredCartId, setState: setBrowserStoredCartId } =
    useBrowserStoredState<string>({
      storageKey: localCartIdStorageKey,
      initialState: crypto.randomUUID(),
      storage: globalThis.localStorage,
    })

  const { state: browserStoredCartAttributes, setState: setBrowserStoredCartAttributes } =
    useBrowserStoredState<CustomAttribute[]>({
      storageKey: localCartAttributesStorageKey,
      initialState: [],
      storage: globalThis.localStorage,
    })

  /**
   * We can’t consume browserStoredCartItems directly because its value will be
   * different between server-side renders and client-side renders since on the
   * server there is no browser-stored anything. This mismatch leads to
   * problems, errors and bugs with Next.js.
   *
   * Since browserStoredCartItems will be an empty object server-side, but
   * might begin as a populated object client-side, we us a derived
   * localCartItems that also begins as an empty object client-side to
   * match the server-rendered value.
   *
   * We accomplish this with useState({}) and update its value via a useEffect
   * which by definition will only run on the client, and will keep its value
   * synced with the browser-stored cart items after the initial render.
   */
  const [localCartItems, setLocalCartItems] = useState<LocalCart>({})
  const [localCartId, setLocalCartId] = useState<string>('')
  const [localCartAttributes, setLocalCartAttributes] = useState<CustomAttribute[]>([])

  useEffect(() => {
    setLocalCartItems(browserStoredCartItems)
    setLocalCartId(browserStoredCartId)
    setLocalCartAttributes(browserStoredCartAttributes)
    setStatus('resolved')
  }, [browserStoredCartItems, browserStoredCartId, browserStoredCartAttributes])

  const mutateCartItems = useCallback(
    (mutate: (draft: LocalCart) => void) => {
      setBrowserStoredCartItems(current => produce(current, mutate))
    },
    [setBrowserStoredCartItems]
  )

  const mutateCartAttributes = useCallback(
    (mutate: (draft: CustomAttribute[]) => void) => {
      setBrowserStoredCartAttributes(current => produce(current, mutate))
    },
    [setBrowserStoredCartAttributes]
  )

  const addGlobalAttributesToCart = useCallback<AddLocalCartAttributes>(
    attributes => {
      mutateCartAttributes(draft => {
        const lookup = Object.fromEntries([...draft, ...attributes].map(attr => [attr.key, attr]))
        return Object.values(lookup)
      })
    },
    [mutateCartAttributes]
  )

  const addItemsToCart = useCallback<AddLocalCartItems>(
    items => {
      mutateCartItems(draft => {
        items.forEach(
          ({
            productType,
            properties = {},
            quantity = 1,
            sku,
            swPromoEligible,
            variantId,
            isPortalColor,
            isPortalLogo,
          }) => {
            /**
             * Shopify combines variants into one line item with the quantity
             * the sum of the individual variant quantities if:
             * 1. The variant IDs match
             * 2. The custom line item properties match
             * We replicate that here by either updating an existing line item
             * or adding a new one.
             */
            const existingMatchingLineItem = Object.values(draft).find(
              o =>
                o.variantId === variantId &&
                isEqual(
                  omit(properties, '_collectionAttribution'),
                  omit(o.properties, '_collectionAttribution')
                )
            )

            // if the `_collectionAttribution` prop is present, we must merge the values into one prop
            if (existingMatchingLineItem && properties._collectionAttribution) {
              const collectionAttributionsSet = existingMatchingLineItem.properties
                ._collectionAttribution
                ? new Set(existingMatchingLineItem.properties._collectionAttribution.split(','))
                : new Set()
              collectionAttributionsSet.add(properties._collectionAttribution)
              existingMatchingLineItem.properties._collectionAttribution =
                Array.from(collectionAttributionsSet).join(',')
            }

            const itemToAddOrUpdate: LocalCartItem = existingMatchingLineItem
              ? {
                  ...existingMatchingLineItem,
                  quantity: existingMatchingLineItem.quantity + quantity,
                }
              : {
                  key: generateUuid(),
                  productType,
                  properties,
                  quantity,
                  sku,
                  swPromoEligible,
                  variantId,
                  isPortalColor,
                  isPortalLogo,
                }
            draft[itemToAddOrUpdate.key] = itemToAddOrUpdate
          }
        )
      })
    },
    [mutateCartItems]
  )

  const removeItemsFromCart = useCallback<RemoveLocalCartItems>(
    keys => {
      mutateCartItems(draft => {
        keys.forEach(key => {
          delete draft[key]
        })
      })
    },
    [mutateCartItems]
  )

  const setItemsQuantitiesInCart = useCallback<SetLocalCartItemsQuantities>(
    (keys, newQuantity) => {
      mutateCartItems(draft => {
        keys.forEach(key => {
          if (key in draft === false || draft[key] === undefined) {
            return
          } else {
            draft[key]!.quantity = newQuantity
          }
        })
      })
    },
    [mutateCartItems]
  )

  const clearCart = useCallback(() => {
    setBrowserStoredCartItems({})
    setBrowserStoredCartId(crypto.randomUUID())
    setBrowserStoredCartAttributes([])
  }, [setBrowserStoredCartId, setBrowserStoredCartItems, setBrowserStoredCartAttributes])

  const _testing_internals_syncLocalStorageAcrossTabs = useCallback(() => {
    if (document.visibilityState === 'visible') {
      const localCartData = globalThis.localStorage?.getItem(localCartStorageKey)
      if (localCartData && localCartData !== JSON.stringify(browserStoredCartItems)) {
        try {
          const localCartObj = JSON.parse(localCartData)
          clearCart()
          addItemsToCart(Object.keys(localCartObj).map(k => localCartObj[k]))
        } catch {}
      }
    }
  }, [addItemsToCart, browserStoredCartItems, clearCart])

  useEffect(() => {
    document.addEventListener('visibilitychange', _testing_internals_syncLocalStorageAcrossTabs)
    setBrowserStoredCartId(browserStoredCartId)
    return () => {
      document.removeEventListener(
        'visibilitychange',
        _testing_internals_syncLocalStorageAcrossTabs
      )
    }
  }, [_testing_internals_syncLocalStorageAcrossTabs, browserStoredCartId, setBrowserStoredCartId])

  return {
    cartId: localCartId,
    cartItems: localCartItems,
    cartAttributes: localCartAttributes,
    status,
    clearCart,
    addItemsToCart,
    removeItemsFromCart,
    setItemsQuantitiesInCart,
    addGlobalAttributesToCart,
    _testing_internals_syncLocalStorageAcrossTabs,
  }
}
