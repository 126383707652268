'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import {
  TypeStyleBodyMicro,
  TypeStyleHeadlineExtraExtraSmall,
  typeStyleMap,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { isUppercase } from '@syconium/little-miss-figgy/dist/lib/utils/isUppercase'

import { gql } from '../../../__generated__/graphql/catalog'
import { NavListSectionFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'
import { trackEvent } from '../../../lib/analytics'
import { AlternateModelVariantAction } from '../../../types/graphql/site-fixtures'
import { usePersonalization } from '../../_providers/PersonalizationProvider.client'
import { NextLink } from '../navigation/NextLink'

export const navListSectionQueryFragment = gql(`
  fragment NavListSectionFragment on NavListSection {
    __typename
    id
    variant
    items {
      __typename
      ... on NavListItem {
        ...NavListSectionItemFragment
      }
    }
  }
  fragment NavListSectionItemFragment on NavListItem {
    ...NavListSectionItemFieldsFragment
    alternateModelVariant {
      __typename
      action
      targetCriteria {
        __typename
        targetLogic
        targetCriteria
        inRegion
      }
      validContentfulModel {
        ... on NavListItem {
          ...NavListSectionItemFieldsFragment
        }
      }
    }
  }
  fragment NavListSectionItemFieldsFragment on NavListItem {
    __typename
    id
    badge
    badgeColor
    badgeTextColor
    href
    icon
    isBold
    isHeading
    text
    textColor
    htmlId
    htmlClass
    isBtn
  }
`)

type NavListItem = Omit<
  Extract<NavListSectionFragmentFragment['items'][number], { __typename: 'NavListItem' }>,
  'alternateModelVariant'
>

const getItemToRender = ({
  items,
  index,
  validatePersonalizationTargetCriteria,
}: {
  items: NavListSectionFragmentFragment['items']
  index: number
  validatePersonalizationTargetCriteria: ReturnType<
    typeof usePersonalization
  >['validatePersonalizationTargetCriteria']
}): NavListItem | null => {
  const item = items[index]
  if (item && item.__typename === 'NavListItem') {
    let itemToRender: NavListItem = item
    if (item.alternateModelVariant?.targetCriteria) {
      if (validatePersonalizationTargetCriteria(item.alternateModelVariant.targetCriteria)) {
        if (item.alternateModelVariant.action === AlternateModelVariantAction.hideOriginal) {
          return null
        } else if (
          !!item.alternateModelVariant.validContentfulModel &&
          item.alternateModelVariant.validContentfulModel.__typename === 'NavListItem'
        ) {
          itemToRender = item.alternateModelVariant.validContentfulModel
        }
      }
    }
    return itemToRender
  } else {
    return null
  }
}

export const PrimaryNavItemItemIcon = styled(ResponsiveImage)`
  height: 16px;
  width: 16px;
  flex-grow: 0;
  flex-shrink: 0;
`

export const PrimaryNavItemItemBadge = styled(TypeStyleHeadlineExtraExtraSmall)<{
  $badgeColor: string | null
  $badgeTextColor: string | null
}>`
  background: ${o => o.$badgeColor || o.theme.color.fill.disabled.subtle};
  border-radius: 11px;
  color: ${o => o.$badgeTextColor || o.theme.color.text.primary.on.subtle};
  font-size: 9px;
  padding: 5px 9px 4px;
  text-transform: uppercase;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: 700;
`

export const PrimaryNavItemList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(3)};
  ${fromMd} {
    max-width: 320px;
  }
`

export const PrimaryNavItemListDivider = styled.hr`
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-top-color: ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
`

type PrimaryNavItemProps = ComponentProps<typeof TypeStyleBodyMicro> & {
  className?: string
  children?: React.ReactNode
  $variant?:
    | 'heading'
    | 'default'
    | {
        sm: 'heading' | 'default'
        md: 'heading' | 'default'
      }
  $includeTopMargin?: boolean
}

export const PrimaryNavItem = styled(
  ({ asTag = 'li', className, children, ...props }: PrimaryNavItemProps) => {
    return (
      <TypeStyleBodyMicro asTag={asTag} className={className} {...props}>
        {children}
      </TypeStyleBodyMicro>
    )
  }
)`
  ${o =>
    o.$includeTopMargin
      ? `
    margin-top: ${o.theme.spacing(6)};
    `
      : null}

  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
  text-align: left;

  ${untilMd} {
    font-size: 15px;

    ${o =>
      o.$variant === 'heading' ||
      (typeof o.$variant === 'object' && 'sm' in o.$variant && o.$variant.sm === 'heading')
        ? typeStyleMap['headlineExtraSmall']
        : typeStyleMap['bodyMicro']};
  }

  ${fromMd} {
    ${o =>
      o.$variant === 'heading' ||
      (typeof o.$variant === 'object' && 'md' in o.$variant && o.$variant.md === 'heading')
        ? typeStyleMap['headlineExtraExtraSmall']
        : typeStyleMap['bodyMicro']};
  }
`

type PrimaryNavItemLinkProps = ComponentProps<typeof TextLink> & { href: string }

export const PrimaryNavItemLink = ({
  inheritTypography = true,
  variant = 'underline-on-hover',
  ...props
}: PrimaryNavItemLinkProps) => {
  return (
    <TextLink
      inheritTypography={inheritTypography}
      variant={variant}
      as={props.as ?? NextLink}
      {...props}
    />
  )
}

type PrimaryNavItemListSectionProps = NavListSectionFragmentFragment

export const PrimaryNavItemListSection = ({ items }: PrimaryNavItemListSectionProps) => {
  const { validatePersonalizationTargetCriteria } = usePersonalization()

  return (
    <div>
      <PrimaryNavItemList>
        {items.map((_, index) => {
          const item = getItemToRender({ items, index, validatePersonalizationTargetCriteria })
          if (item === null) return null

          const {
            badge,
            badgeColor,
            badgeTextColor,
            href,
            htmlClass,
            htmlId,
            id,
            icon,
            isBold,
            isHeading,
            text,
            textColor,
          } = item

          const textStyle = { color: textColor ?? undefined }
          const StyledText = <span style={textStyle}>{text}</span>

          return (
            <PrimaryNavItem
              key={id}
              $includeTopMargin={index !== 0 && isHeading ? true : false}
              isUppercase={isUppercase(text)}
              $variant={isHeading || isBold ? 'heading' : 'default'}
            >
              {icon ? (
                <PrimaryNavItemItemIcon
                  alt=''
                  src={icon}
                  aspectRatios={{ sm: 1, md: 1 }}
                  widths={{ unit: 'px', sm: 16, md: 16 }}
                  role='presentation'
                  transparentBackground={true}
                  loading='lazy'
                />
              ) : null}
              <span>
                {href ? (
                  <PrimaryNavItemLink
                    href={href}
                    className={htmlClass ?? undefined}
                    id={htmlId ?? undefined}
                    {...trackEvent({
                      category: 'navigation',
                      action: 'click list item',
                      label: text,
                    })}
                  >
                    {StyledText}
                  </PrimaryNavItemLink>
                ) : (
                  StyledText
                )}
              </span>
              {badge ? (
                <PrimaryNavItemItemBadge
                  asTag={'span'}
                  $badgeColor={badgeColor}
                  $badgeTextColor={badgeTextColor}
                >
                  {badge}
                </PrimaryNavItemItemBadge>
              ) : null}
            </PrimaryNavItem>
          )
        })}
      </PrimaryNavItemList>
    </div>
  )
}
