import { gql } from '../../../__generated__/graphql/catalog'

/**
 * IMPORTANT: DO NOT add anything into this query that is on the
 * productGroup level. ONLY use the featuredProduct field.
 * Altering fields on the productGroup as of now can alter
 * the scope of the `featuredProduct` and `products` fields
 * that also exist on `productGroup`. It can cause us to only
 * get data for products that are within the provided `collectionHandle`.
 *
 * The default selection scope for `products` and `featuredProduct` is `SCOPE_PLUS_ALL`,
 * which will include basically anything in the collection plus anything else.
 *
 * Until we can specify that, we have to be careful not to break that wonderful
 * default with other fields.
 */
export const PDP_FEATURED_PRODUCT_FRAGMENT = gql(`
  fragment PdpFeaturedProductFragment on ProductGroup {
    featuredProduct(color: $color, fit: $fit) {
      id
      availableForSale
      collectionLinks {
        nodes {
          href
          text
        }
      }
      color
      colorInfo {
        rawName
        accentColor
      }
      embroidery(logoScope: $logoScope) {
        ...ProductEmbroidery
      }
      detailImages
      finalSale
      fit
      fitModel {
        description
        height
        images
        name
        pantsSize
        topSize
      }
      fitModelCount
      fitModelsDescriptions
      badgeList
      iconBadge
      iconBadgePlacement
      iconList {
        image
        label
        title
      }
      images {
        nodes {
          color
          embroideryOffsets
          fit
          index
          source
          kind
          type
        }
      }
      noindex
      handle
      rtfFeatures
      rawFit
      rawMaterials
      seoTitle
      seoDescription
      shopifyId
      soldOut
      swPromoEligible
      whyWeLove
      careInstructions
      variants {
        sku
        category
        color
        currencyType
        discountPrice
        discountPriceExplicitFmt
        size
        fit
        handle
        price
        priceExplicitFmt
        soldOut
        upc
        priceDetails {
          ...PriceDetail
        }
        product {
          id
          finalSale
          waitlistable
          shopifyId
        }
        rawColor
        rawFit
        shopifyId
      }
      videos {
        nodes {
          kind
          source
          type
        }
      }
      waitlistable
    }
  }
`)
