'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import { StyledInterstitial } from '../../../components/chrome/Interstitial/Interstitial'

export const Interstitial = () => {
  const pathname = usePathname()
  const searchparams = useSearchParams()
  const [isVisible, setIsVisisble] = useState(false)

  useEffect(() => {
    setIsVisisble(false)

    const clickHandler = (event: MouseEvent) => {
      const target = event.target
      if (typeof HTMLElement !== 'undefined' && target instanceof HTMLElement) {
        const wrappingAnchor = target.closest('a')
        const href = wrappingAnchor?.getAttribute('href')
        if (href && !href.includes(window.location.pathname)) {
          setIsVisisble(true)
        }
      }
    }

    window.addEventListener('click', clickHandler)
    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [pathname, searchparams])

  return isVisible ? <StyledInterstitial /> : null
}
