import { FC } from 'react'

import { LoadingSpinner } from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { PriceAmount, PriceLineLabel, PriceLineWrapper } from '../Cart/styles'

export interface PriceLineProps {
  loading?: boolean
  label?: string
  price: string
  type: 'subtotal' | 'stipend' | 'shipping' | 'discount'
}

/**
 * Display a price line in the Mini Cart.
 *
 * @param opts
 * @param [opts.loading] - If the total balance is pending
 * @param [opts.label] - The label to display for the price line.
 * @param [opts.price] - The price to display for the price line. This value is expected to already be formatted/localized.
 * @param [opts.type] - The type of `PriceLine` to render.
 **/
export const PriceLine: FC<PriceLineProps> = ({ loading, label, price, type }) => {
  const {
    magnolia: {
      general: { loading: loadingCopy },
    },
  } = useTranslation()

  return (
    <PriceLineWrapper $type={type}>
      {label && <PriceLineLabel>{label}:</PriceLineLabel>}
      {loading ? <LoadingSpinner label={loadingCopy} /> : <PriceAmount>{price}</PriceAmount>}
    </PriceLineWrapper>
  )
}
