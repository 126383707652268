'use client'

import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { ComponentProps } from 'react'

type FormElementProps = {
  ariaLabel: string
  className?: string
  children?: React.ReactNode
}

const FormElement = ({ children, className, ariaLabel }: FormElementProps) => {
  const { handleSubmit } = useFormikContext()
  return (
    <form noValidate onSubmit={handleSubmit} aria-label={ariaLabel} className={className}>
      {children}
    </form>
  )
}

type FormProps<V extends Record<string, string>> = {
  initialValues: V
  onSubmit: (values: V, helpers: FormikHelpers<V>) => void | Promise<void>
  children?: ComponentProps<typeof Formik<V>>['children']
} & Omit<FormElementProps, 'children'>

export const Form = <V extends Record<string, string>>({
  children,
  className,
  ariaLabel,
  initialValues,
  onSubmit,
}: FormProps<V>) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      children={props => {
        return (
          <FormElement className={className} ariaLabel={ariaLabel}>
            {typeof children === 'function' ? children(props) : children}
          </FormElement>
        )
      }}
    />
  )
}
