'use client'

import { FormikHelpers } from 'formik'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { FlagIconImage } from '@syconium/little-miss-figgy/dist/components/FlagIconImage'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import { SelectOption } from '@syconium/little-miss-figgy/dist/components/Select/Select'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import { SupportedLocaleIdentifier, SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { useTranslatedLocalization } from '../../_hooks/useTranslatedLocalization'
import { useFeatureFlag } from '../../_providers/ExperimentationProvider.client'
import { useTranslation } from '../../_providers/TranslationProvider.client'
import {
  NextDialogBody,
  NextDialogFooter,
  NextDialogHeader,
  NextDialogTitle,
} from '../dialogs/NextDialog.client'
import { Form } from '../form/Form'
import { FormFieldSkeleton } from '../form/FormFieldSkeleton'
import { FormSelect } from '../form/FormSelect'
import { FormSubmit } from '../form/FormSubmit'
import { NextLink } from '../navigation/NextLink'

const StyledLocalizationForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${o => o.theme.spacing(5)};
  text-align: left;
  min-width: 280px;
`

type ChangeLocaleFormValues = {
  locale: SupportedLocaleIdentifier
  region: SupportedRegionIdentifier
}

const SkeletonLocalizationForm = () => {
  return (
    <>
      <FormFieldSkeleton />
      <FormFieldSkeleton />
      <FormFieldSkeleton excludeLabel />
    </>
  )
}

const LocalizationDialogContentFooter = () => {
  const translations = useTranslation()
  return (
    <NextDialogFooter>
      <div>
        <TextLink as={NextLink} color='secondary' href='/pages/non-us-privacy-policy'>
          {translations.regionAndLocalePicker.modal.figsPrivacyLink}
        </TextLink>
      </div>
      <div>
        <TextLink
          color='secondary'
          href='https://s3.global-e.com/merchantscontent/Figs/docs/FIGS_Privacy_Policy--2022_06_03.pdf'
        >
          {translations.regionAndLocalePicker.modal.gePrivacyLink}
        </TextLink>
      </div>
    </NextDialogFooter>
  )
}

type LocalizationDialogContentProps = {
  variant: 'form-only' | 'full'
}

const LocalizationDialogContent = ({ variant }: LocalizationDialogContentProps) => {
  const translations = useTranslation()
  const { data, status, changeLocale } = useTranslatedLocalization()
  const { regions, region: currentRegion, locale: currentLocale } = data
  const flagSrc = currentRegion.flagSrc
  const dialogText = translations.regionAndLocalePicker.modal
  const dialogTitleText = `${dialogText.title} ${dialogText.titleLanguage}`

  const [userSelectedRegion, setUserSelectedRegion] = useState<typeof currentRegion | null>(null)

  const japanFeatureFlag = useFeatureFlag({
    key: 'ja-jp-locale',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    defaultVariant: 'false',
  })
  const isJapanEnabled = japanFeatureFlag && japanFeatureFlag === 'true'

  const koreaFeatureFlag = useFeatureFlag({
    key: 'ko-kr-locale',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    defaultVariant: 'false',
  })
  const isKoreaEnabled = koreaFeatureFlag && koreaFeatureFlag === 'true'

  const regionOptions = useMemo(() => {
    return regions.filter(supportedRegion => {
      if (supportedRegion.id === 'JP' && isJapanEnabled) return true
      if (supportedRegion.id === 'KR' && isKoreaEnabled) return true
      return supportedRegion.isEnabled || supportedRegion.id === currentRegion.id
    })
  }, [currentRegion.id, isJapanEnabled, isKoreaEnabled, regions])

  const localeOptions = useMemo(() => {
    return (userSelectedRegion ?? currentRegion).languagesArray.filter(
      regionalLanguage => regionalLanguage.isEnabled
    )
  }, [userSelectedRegion, currentRegion])

  const initialValues = useMemo<ChangeLocaleFormValues>(() => {
    return {
      region: currentRegion.id,
      locale: currentLocale,
    }
  }, [currentLocale, currentRegion.id])

  const onSubmit = (
    values: ChangeLocaleFormValues,
    _actions: FormikHelpers<ChangeLocaleFormValues>
  ) => {
    changeLocale(
      regionOptions.find(regionOption => regionOption.id === values.region) ??
        userSelectedRegion ??
        currentRegion,
      values.locale
    )
  }

  const BodyElement = variant === 'full' ? NextDialogBody : 'div'

  return (
    <>
      {variant === 'full' ? (
        <NextDialogHeader closeText={translations.magnolia.general.close}>
          <div>
            <FlagIconImage src={flagSrc} />
          </div>
          <div>
            <NextDialogTitle>{dialogTitleText}</NextDialogTitle>
          </div>
        </NextDialogHeader>
      ) : null}
      <BodyElement>
        {status !== 'resolved' ? (
          <StyledLocalizationForm>
            <ScreenReaderOnly>{translations.magnolia.general.loading}</ScreenReaderOnly>
            <SkeletonLocalizationForm />
          </StyledLocalizationForm>
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            ariaLabel={dialogTitleText}
            children={({ getFieldHelpers }) => {
              return (
                <StyledLocalizationForm>
                  <div>
                    <FormSelect
                      label={dialogText.labelCountry}
                      name='region'
                      onChange={newRegionId => {
                        const newSelectedRegion = regionOptions.find(
                          regionOption => regionOption.id === newRegionId
                        )
                        if (newSelectedRegion) {
                          const localeFieldHelpers = getFieldHelpers('locale')
                          localeFieldHelpers.setValue(newSelectedRegion.defaultLocale)
                          setUserSelectedRegion(newSelectedRegion)
                        }
                      }}
                      renderSelectValue={selectedRegionId => {
                        return (
                          regionOptions.find(regionOption => regionOption.id === selectedRegionId)
                            ?.name ?? selectedRegionId
                        )
                      }}
                    >
                      {regionOptions.map(regionOption => {
                        return (
                          <SelectOption key={regionOption.id} value={regionOption.id}>
                            {regionOption.name}
                          </SelectOption>
                        )
                      })}
                    </FormSelect>
                  </div>

                  <div>
                    <FormSelect
                      label={dialogText.labelLanguage}
                      name='locale'
                      renderSelectValue={selectedLocaleId => {
                        return (
                          localeOptions.find(
                            localeOption => localeOption.locale === selectedLocaleId
                          )?.name ?? selectedLocaleId
                        )
                      }}
                    >
                      {localeOptions.map(localeOption => {
                        return (
                          <SelectOption key={localeOption.locale} value={localeOption.locale}>
                            {localeOption.name}
                          </SelectOption>
                        )
                      })}
                    </FormSelect>
                  </div>

                  <FormSubmit submittingLabel={translations.magnolia.general.submitting}>
                    {dialogText.ctaText}
                  </FormSubmit>
                </StyledLocalizationForm>
              )
            }}
          />
        )}
      </BodyElement>
      {variant === 'full' ? <LocalizationDialogContentFooter /> : null}
    </>
  )
}

const LocalizationMenu = styled.div`
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
`

export {
  LocalizationDialogContentFooter as PrimaryNavLocalizationDialogContentFooter,
  LocalizationDialogContent as PrimaryNavLocalizationDialogContent,
  LocalizationMenu as PrimaryNavLocalizationMenu,
}
