import { FC, memo, useEffect, useRef, useState } from 'react'

import { CheckMark } from '@syconium/little-miss-figgy'

import { useFeatureFlag } from '../../../../app/_providers/ExperimentationProvider.client'
import { useLocalization } from '../../../../app/_providers/LocalizationProvider.client'
import { useTranslation } from '../../../../app/_providers/TranslationProvider.client'
import { intlPrettyCurrency } from '../../../../lib/utils'

import {
  CheckMarkCircleFade,
  CheckMarkFade,
  CheckMarkWrap,
  FreeShippingAchievedContainer,
  FreeShippingContainer,
  FreeShippingFadeInAndDown,
  FreeShippingGoal,
  FreeShippingText,
  FreeShippingTranslate,
  ProgressBar,
  SpendXMoreContainer,
} from './styles'

const FREE_SHIPPING_THRESHOLD = 50 as const

const FreeShippingProgressBar: FC<{ progressAsPercent: number }> = ({ progressAsPercent }) => {
  return (
    <ProgressBar.Bar>
      <ProgressBar.Progress $progressAsPercent={progressAsPercent} />
    </ProgressBar.Bar>
  )
}

function usePreviousCartTotal(value: number) {
  const ref = useRef<number>(value ?? 0)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const FreeShippingSection: FC<{ cartTotal: number }> = memo(
  ({ cartTotal }) => {
    const { freeShipping } = useTranslation()
    const { isUSRegion, locale, region } = useLocalization()

    const minicartCleanupDecision = useFeatureFlag({
      key: 'minicart-cleanup-test',
      defaultVariant: 'no-new-minicart',
      ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    })

    const showNewMinicartFeatures = minicartCleanupDecision === 'yes-new-minicart'

    // totalPrice is given in cents, so convert to dollars
    const cartPriceInDollars: number = cartTotal / 100
    const amountTilGoalReachedInCents: number = (FREE_SHIPPING_THRESHOLD - cartPriceInDollars) * 100
    const sanitizedAmountTilGoalReached: number =
      amountTilGoalReachedInCents >= 0 ? amountTilGoalReachedInCents : 0
    const freeShippingThresholdMet = cartPriceInDollars >= FREE_SHIPPING_THRESHOLD

    const formattedCartPrice = intlPrettyCurrency({
      cents: sanitizedAmountTilGoalReached,
      region: region,
      locale: locale,
    })

    const [enjoyFadeDirection, setEnjoyFadeDirection] = useState<'in' | 'out'>('in')
    const prevCartPrice = usePreviousCartTotal(cartPriceInDollars)

    useEffect(() => {
      if (cartPriceInDollars > prevCartPrice) {
        setEnjoyFadeDirection('in')
      } else if (cartPriceInDollars < prevCartPrice) {
        setEnjoyFadeDirection('out')
      }
    }, [cartPriceInDollars, prevCartPrice])

    return (
      <>
        {isUSRegion && cartPriceInDollars > 0 ? (
          <FreeShippingContainer>
            {/*
             * "Enjoy Free Shipping!"
             *
             * Displayed when (and after) the FREE_SHIPPING_THRESHOLD has been met.
             * and only animated once, static otherwise unless the threshold
             * is gone below again, in which case the animation is run once again.
             */}
            {showNewMinicartFeatures ? null : (
              <FreeShippingAchievedContainer
                id='FreeShippingAchieved'
                $fadeDirection={enjoyFadeDirection}
                $hide={!freeShippingThresholdMet}
              >
                <FreeShippingTranslate $runAnimation={freeShippingThresholdMet}>
                  <FreeShippingFadeInAndDown $runAnimation={freeShippingThresholdMet}>
                    <CheckMarkCircleFade
                      id='CheckMarkCircle'
                      $fadeDirection={'in'}
                      $runAnimation={freeShippingThresholdMet}
                    >
                      <CheckMarkWrap>
                        <CheckMarkFade
                          id='CheckMarkFade'
                          $fadeDirection={'in'}
                          $runAnimation={freeShippingThresholdMet}
                        >
                          <CheckMark />
                        </CheckMarkFade>
                      </CheckMarkWrap>
                    </CheckMarkCircleFade>
                    <FreeShippingText asTag='p'>{freeShipping.enjoyFreeShipping}</FreeShippingText>
                  </FreeShippingFadeInAndDown>
                </FreeShippingTranslate>
              </FreeShippingAchievedContainer>
            )}

            {/*
             * "Spend $X more for Free Shipping!" (with progress bar)
             *
             * Displayed until the FREE_SHIPPING_THRESHOLD is met, otherwise hidden.
             */}
            <SpendXMoreContainer
              id='SpendXMore'
              $slideDirection={freeShippingThresholdMet ? 'out' : 'in'}
              $hide={!showNewMinicartFeatures && freeShippingThresholdMet}
            >
              <FreeShippingGoal>
                <FreeShippingText asTag='p'>
                  {`${formattedCartPrice} ${freeShipping.moreForFreeShipping}`}
                </FreeShippingText>
              </FreeShippingGoal>
              <FreeShippingProgressBar
                progressAsPercent={(cartPriceInDollars / FREE_SHIPPING_THRESHOLD) * 100}
              />
            </SpendXMoreContainer>
          </FreeShippingContainer>
        ) : null}
      </>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.cartTotal === nextProps.cartTotal
  }
)
