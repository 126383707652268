'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import { timingSlower } from '@syconium/little-miss-figgy/dist/constants/timing'

import { NextDialog, NextDialogBackdrop, NextDialogDismiss } from './NextDialog.client'

type NextDrawerDialogBackdropProps = ComponentProps<typeof NextDialogBackdrop>

export const NextDrawerDialogBackdrop = styled((props: NextDrawerDialogBackdropProps) => {
  return <NextDialogBackdrop {...props} />
})`
  padding: 0px;
  overflow: hidden;
  display: block;

  transition:
    background-color ${timingSlower} ease,
    backdrop-filter ${timingSlower} ease;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  &[data-enter] {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
  }
`

export const NextDrawerDialogFooter = styled.footer`
  border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  padding: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  background-color: ${o => o.theme.color.fill.background};
  color: ${o => o.theme.color.text.primary.on.background};
`

const NextDrawerDialogDismiss = styled(NextDialogDismiss)<{ $pinTo: 'top-left' | 'top-right' }>`
  position: fixed;
  top: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  ${o =>
    o.$pinTo === 'top-left'
      ? `
      left: ${o.theme.dimensions.layout.sidePadding.md}px;
    `
      : `
      right: ${o.theme.dimensions.layout.sidePadding.md}px;
    `};
  z-index: 1;
`

type NextDrawerDialogProps = ComponentProps<typeof NextDialog> & {
  variant: 'left' | 'right'
  closeText: string
}

export const NextDrawerDialog = styled(
  ({
    backdrop = NextDrawerDialogBackdrop,
    children,
    variant,
    closeText,
    nestDialogInBackdrop = false,
    ...props
  }: NextDrawerDialogProps) => {
    return (
      <NextDialog backdrop={backdrop} nestDialogInBackdrop={nestDialogInBackdrop} {...props}>
        <NextDrawerDialogDismiss
          $pinTo={variant === 'left' ? 'top-left' : 'top-right'}
          closeText={closeText}
        />
        {children}
      </NextDialog>
    )
  }
)`
  position: fixed;
  top: 0px;
  ${o => (o.variant === 'left' ? 'left: 0px;' : 'right: 0px;')};
  box-sizing: border-box;
  min-width: unset;
  max-width: min(100%, 600px);
  min-height: unset;
  max-height: unset;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: min(100dvh, var(--dialog-viewport-height));
  border-radius: 0px;
  margin: 0px;
  text-align: unset;
  overflow: auto;
  overscroll-behavior: contain;
  opacity: 1;

  transition: transform ${timingSlower} ease;
  transform: ${o => (o.variant === 'left' ? 'translateX(-100%)' : 'translateX(100%)')};
  &[data-enter] {
    transform: translateX(0%);
  }
`
