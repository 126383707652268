'use client'

import { gql } from '../../../__generated__/graphql/catalog'
import { NavListGroupSectionFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'

import { PrimaryNavContentfulSections } from './PrimaryNavContentfulSections.client'
import { PrimaryNavItemListSection } from './PrimaryNavItemListSection.client'

export const navListGroupSectionQueryFragment = gql(`
  fragment NavListGroupSectionFragment on NavListGroupSection {
    __typename
    id
    navSections {
      ...NavListSectionFragment
    }
  }
`)

type PrimaryNavItemListGroupSectionProps = NavListGroupSectionFragmentFragment

export const PrimaryNavItemListGroupSection = ({
  navSections,
}: PrimaryNavItemListGroupSectionProps) => {
  return (
    <PrimaryNavContentfulSections>
      {navSections.map((navSection, index) => {
        return <PrimaryNavItemListSection key={index} {...navSection} />
      })}
    </PrimaryNavContentfulSections>
  )
}
