import styled from 'styled-components'

import { Tooltip, TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'

import { deriveFontFamilyFromStyle } from '../../../../../../brunswick/containers/embroidery/utils'

export const FormBody = styled.form`
  ${untilMd} {
    padding: ${o => o.theme.spacing(2.5)} ${o => o.theme.spacing(4)} ${o => o.theme.spacing(5)};
  }

  ${fromMd} {
    border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  }
`

export const Label = styled.h4<{
  inset?: boolean
  disabled?: boolean
}>`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: ${o => o.theme.spacing(2)};
  text-transform: uppercase;
  ${o => (o.disabled ? 'color: #cccccc;' : null)}
`

export const SelectedOptionText = styled.span<{ disabled?: boolean }>`
  ${TypeStyle.css.headlineMicro}
  color: ${o => o.theme.color.deprecated.obsidianLight};
  text-transform: none;
  font-weight: ${o => o.theme.typography.font.weight[400]};
  ${o => (o.disabled ? 'visibility: hidden;' : null)}
`

export const EmbroideryOptionWrapper = styled.div`
  margin-top: ${o => o.theme.spacing(6)};
`

export const IconPlacementWrapper = styled(EmbroideryOptionWrapper)`
  margin-top: ${o => o.theme.spacing(2)};
`

export const EmbroideryInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(2.5)};
`

export const SelectionButton = styled.button<{
  fontStyle?: string
  isSelected: boolean
}>`
  background-color: ${o => (o.isSelected ? o.theme.color.deprecated.obsidian : 'white')};
  border-color: ${o => (o.isSelected ? 'black' : o.theme.color.deprecated.gray.light)};
  border-style: solid;
  border-width: 1px;
  border-radius: ${o => o.theme.spacing(1)};
  color: ${o => (o.isSelected ? 'white' : o.theme.color.deprecated.obsidian)};
  font-family: ${o => o.fontStyle && deriveFontFamilyFromStyle(o.fontStyle)};
  font-size: 11px;
  font-weight: bold;
  height: 40px;
  letter-spacing: 0.5px;
  outline: none;
  width: ${o => o.theme.spacing(30)};

  &:focus {
    outline: 2px solid ${props => props.theme.color.deprecated.blue.light};
    outline-offset: 2px;
  }

  &:hover {
    background-color: ${o =>
      o.isSelected ? o.theme.color.deprecated.obsidian : o.theme.color.deprecated.stoneLighter};
  }
`

export const ErrorMessage = styled.span<{
  inset?: boolean
}>`
  color: ${o => o.theme.color.deprecated.red.error};
  display: block;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 10px;
`

export const ScrollableItemsScrollableArea = styled.div`
  ${untilMd} {
    overflow-x: auto;
    overflow-y: hidden;

    /* Hide scrollbars */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
`

export const ScrollableItemsWrapper = styled.div`
  margin: 0 -${o => o.theme.spacing(4)};
  position: relative;
`

const spaceBetweenItems = '10px'

export const ScrollableItemsContent = styled.div`
  display: table;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: ${o => o.theme.spacing(4)};
  padding-inline-end: calc(${o => o.theme.spacing(4)} - ${spaceBetweenItems});

  margin-top: 0;
  margin-bottom: -${spaceBetweenItems};
  margin-inline-start: 0;
  margin-inline-end: -${spaceBetweenItems};

  > * {
    margin-top: 0;
    margin-bottom: ${spaceBetweenItems};
    margin-inline-start: 0;
    margin-inline-end: ${spaceBetweenItems};
  }

  hr {
    padding: 0;
    margin: 0;
    visibility: hidden;
  }

  ${fromMd} {
    flex-wrap: wrap;
    padding-inline-end: 0;
    display: inline-flex;
    white-space: unset;

    hr {
      display: none;
    }
  }
`

export const TextInputSpacing = styled.div`
  height: ${o => o.theme.spacing(6)};
`

export const RequiredAsterisk = styled.span`
  &::before {
    content: '*';
    color: ${o => o.theme.color.deprecated.red.error};
    font-size: 15px;
    line-height: ${o => o.theme.spacing(4)};
    font-weight: ${o => o.theme.typography.deprecated.font.weight.bold};
    vertical-align: 1px;
  }
`

export const DividingLine = styled.hr`
  border-width: 1px 0 0;
  border-color: ${o => o.theme.color.deprecated.gray.light};
  border-style: solid;
  margin-top: 20px;
`

export const WarningEmojiWrapper = styled.span`
  font-size: 18px;
  margin-top: 4px;
`
export const StyledTooltip = styled(Tooltip)`
  ${TypeStyle.css.bodySmall}
  font-weight: ${o => o.theme.typography.font.weight[400]};
  text-transform: none;
`
export const PriceLabel = styled(TypeStyle.HeadlineMicro)`
  font-weight: ${o => o.theme.typography.font.weight[700]};
  line-height: 20px;
  font-size: 13px;
`
