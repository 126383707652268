import * as React from 'react'

import { CartItem } from '../../../../../types/figs'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const KitCartItem: React.FC<{
  cartItem: CartItem
  eventCategory: 'minicart' | 'cart'
}> = passedProps => {
  const viewModel = useViewModel(passedProps.cartItem.properties)
  return <Template {...passedProps} {...viewModel} />
}
