import styled from 'styled-components'

import { ProgressiveAsset, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  line-height: 18px;
  padding: ${o => o.theme.spacing(6, 0)};

  &:first-of-type {
    border-top-width: 0;
  }
`

const imageSize = '100px'

export const Top = styled.div`
  display: flex;

  ${ProgressiveAsset.Styles.Container} {
    height: ${imageSize};
    width: ${imageSize};
  }

  ${ProgressiveAsset.Styles.Image} {
    background: ${o => o.theme.color.fill.subtle};
  }
`

export const ImagePlaceholder = styled.div`
  background: ${o => o.theme.color.fill.subtle};
  height: ${imageSize};
  width: ${imageSize};
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100px;
  margin-inline-start: 12px;
`

export const DetailsRow = styled.div<{
  $hasOffsetBelow?: boolean
  $hasMarginTop?: boolean
}>`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: ${o => (o.$hasOffsetBelow ? 'auto' : '0px')};
  margin-top: ${o => (o.$hasMarginTop ? '20px' : 0)};

  &:last-child {
    padding-bottom: 0;
  }
`

export const DetailsColumn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-inline-start: auto;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)``

export const OptionsText = styled.p`
  ${TypeStyle.css.headlineMicro};
  color: ${o => o.theme.color.stroke.neutral.text.tertiary.on.background};

  ${fromMd} {
    ${TypeStyle.css.bodySmall};
  }
`

export const FitDescription = styled.span`
  text-transform: capitalize;
`

export const Bottom = styled.div`
  padding-left: 112px;
`
export const SiteWidePromoWrap = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.stroke.critical.text.on.background};
  padding-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
`
/**
 * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
 * @deprecated do not use outside of temporary Product Bundle Discounts work.
 * @todo remove this after we remove this feature.
 */
export const DiscountAppliedAtCheckoutWrap = styled(TypeStyle.BodyNano)`
  ${({ theme: { color, spacing } }) => `
    color: ${color.stroke.info.text.on.background};
    padding-bottom: ${spacing(5)};
    padding-top: ${spacing(1)};
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    line-height: ${spacing(4)};
  `}
`
