import * as React from 'react'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const EmbroideryDisclosure: React.FC<{
  style?: React.CSSProperties
}> = passedProps => {
  const {
    embroidery: { disclosureText },
  } = useTranslation()
  const viewModel = useViewModel()

  return <Template {...passedProps} text={viewModel.text ?? disclosureText} />
}
