import { useQuery } from '@apollo/client'
import compact from 'lodash-es/compact'
import { Slide, Slider } from 'pure-react-carousel'
import { ReactElement, useMemo } from 'react'

import { LoadingSpinner } from '@syconium/little-miss-figgy'
import { ViewportSizes, useIsViewport } from '@syconium/little-miss-figgy'

import { gql } from '../../../../__generated__/graphql/catalog'
import { isValidNostoPlacementId } from '../../../../app/_components/chrome/scripts/NostoScript'
import { useNextQuery } from '../../../../app/_hooks/useNextQuery'
import { useTranslation } from '../../../../app/_providers/TranslationProvider.client'
import { useNostoRecos } from '../../../../lib/hooks/useNostoRecos'

import { CarouselControls } from './CarouselControls'
import { Body, Carousel, Dots, LoadingSpinnerWrapper, Title } from './styles'
import { UpsellTile } from './UpsellTile'

export const FETCH_UPSELL_CONFIGURATION = gql(`
  query FetchUpsellConfiguration {
    siteFixtures(handle: "default") {
      __typename
      id
      upsellHandles
      nostoUpsell
      nostoUpsellId
    }
  }
`)

export const FETCH_UPSELL_PRODUCTS = gql(`
  query FetchUpsellProducts($handles: [String!]) {
    products(handles: $handles) {
      nodes {
        __typename
        id
        shopifyId
        handle
        color
        colorInfo {
          rawName
        }
        rawFit
        finalSale
        swPromoEligible
        images(first: 1) {
          nodes {
            source
          }
        }
        productGroup {
          __typename
          id
          handle
          title
          description
          category
          style
          sizes
          defaultVariant {
            ...UpsellProductVariant
          }
        }
        variants {
          ...UpsellProductVariant
        }
      }
    }
  }
  fragment UpsellProductVariant on Variant {
    __typename
    shopifyId
    sku
    handle
    size
    externalParentId
    soldOut
    priceDetails {
      ...PriceDetail
    }
  }
`)

export const Upsells = (): ReactElement | null => {
  const {
    cart: { beforeYouGo: beforeYouGoText },
    magnolia: {
      general: { loading: loadingText },
    },
  } = useTranslation()

  const isMediumOrLargeViewport = useIsViewport([ViewportSizes.medium, ViewportSizes.large])
  const naturalSlideHeight = isMediumOrLargeViewport ? 150 : 160

  const {
    data: upsellConfigurationData,
    status: upsellConfigurationStatus,
    error: upsellConfigurationError,
  } = useNextQuery(FETCH_UPSELL_CONFIGURATION)

  const {
    nostoUpsell: nostoEnabled,
    nostoUpsellId: requestedNostoPlacementId,
    upsellHandles: defaultUpsellHandles,
  } = upsellConfigurationData?.siteFixtures ?? {}

  const defaultNostoPlacementId = 'cartpage-nosto-4'
  const nostoPlacementId = isValidNostoPlacementId(requestedNostoPlacementId)
    ? requestedNostoPlacementId
    : defaultNostoPlacementId

  const {
    error: nostoError,
    productSummaries: nostoProducts,
    status: nostoStatus,
  } = useNostoRecos({
    nostoPlacementId: nostoPlacementId,
    productCountLimit: 3,
    skip: !nostoEnabled,
  })

  const upsellHandles: string[] = useMemo(() => {
    if (nostoEnabled && nostoStatus === 'resolved') {
      return compact(nostoProducts.map(o => o.handle))
    }
    return defaultUpsellHandles ?? []
  }, [defaultUpsellHandles, nostoProducts, nostoStatus, nostoEnabled])

  const {
    data: productsData,
    error: productsError,
    loading: productsLoading,
  } = useQuery(FETCH_UPSELL_PRODUCTS, {
    skip: upsellHandles?.length === undefined || upsellHandles.length === 0,
    variables: { handles: upsellHandles },
  })

  const error = useMemo(() => {
    return upsellConfigurationError ?? productsError ?? nostoError
  }, [nostoError, productsError, upsellConfigurationError])

  const loading = useMemo(() => {
    return (
      upsellConfigurationStatus === 'pending' ||
      productsLoading ||
      (nostoEnabled && nostoStatus === 'pending')
    )
  }, [nostoEnabled, nostoStatus, productsLoading, upsellConfigurationStatus])

  const products = productsData?.products?.nodes ?? []

  if (!loading && !error && products.length === 0) return null

  // TODO: Handle errors
  // Why do we never handle errors? -_-

  return (
    <Body>
      <Title>{beforeYouGoText}</Title>

      {loading ? (
        <LoadingSpinnerWrapper>
          <LoadingSpinner label={loadingText} size='24px' />
        </LoadingSpinnerWrapper>
      ) : (
        <Carousel
          naturalSlideHeight={naturalSlideHeight}
          dragEnabled={!isMediumOrLargeViewport}
          naturalSlideWidth={280}
          totalSlides={products.length}
        >
          <Slider
            aria-label={`You are currently on a slider for adding additional products to your cart that has ${products.length} tiles. The next element is the product name, then product size, then add to card button.`}
            style={{ overflowX: 'clip', overflowY: 'visible' }}
            tabIndex={-1}
          >
            {products.map((product, i: number) => {
              if (!product.productGroup?.defaultVariant || !product.handle || !product.color) return

              return (
                <Slide index={i} key={i + product.handle} tabIndex={-1}>
                  <UpsellTile
                    product={product}
                    i={i}
                    nostoPlacementId={nostoEnabled ? nostoPlacementId : undefined}
                  />
                </Slide>
              )
            })}
          </Slider>
          {isMediumOrLargeViewport && <CarouselControls />}
          <Dots />
        </Carousel>
      )}
    </Body>
  )
}
