'use client'

import { useDisclosureContext, useStoreState } from '@ariakit/react'
import styled from 'styled-components'

import { Carat } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Carat'
import { Minus } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Minus'
import { Plus } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Plus'
import { timingDefault } from '@syconium/little-miss-figgy/dist/constants/timing'

const StyledCaret = styled(Carat)<{
  $isOpen: boolean
}>`
  height: ${o => (o.$isOpen ? '13px' : '15px')};
  margin-inline-end: ${o =>
    o.$isOpen
      ? o.theme.dimensions.layout.sidePadding.md + 1
      : o.theme.dimensions.layout.sidePadding.md}px;
  transform: rotate(90deg) ${o => (o.$isOpen ? 'rotateY(180deg)' : '')};
  transition: all ${timingDefault};
  width: ${o => (o.$isOpen ? '13px' : '15px')};
`

export const DisclosureSummaryCaratIcon = () => {
  const disclosureContext = useDisclosureContext()
  const disclosureState = useStoreState(disclosureContext)
  const isOpen = disclosureState?.open ?? false
  return <StyledCaret $isOpen={isOpen} />
}

export const DisclosureSummaryPlusIcon = () => {
  const disclosureContext = useDisclosureContext()
  const disclosureState = useStoreState(disclosureContext)
  const isOpen = disclosureState?.open ?? false
  return isOpen ? <Minus stroke='currentcolor' /> : <Plus stroke='currentcolor' />
}
