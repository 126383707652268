import * as React from 'react'

import { CartItem } from '../../../../types/figs'

import { Template } from './Template'

export const KitGroup: React.FC<{
  cartIsUpdating: boolean
  cartItems: ReadonlyArray<CartItem>
  decrementQuantity(): void
  embroideryLabel: string
  eventCategory: 'minicart' | 'cart'
  incrementQuantity(): void
  removeAll(): void
  removeItem(key: string): void
  setQuantity(newQuantity: number): void
}> = passedProps => {
  return <Template {...passedProps} />
}
