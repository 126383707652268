'use client'

import { useFormikContext } from 'formik'
import { ComponentProps } from 'react'
import styled from 'styled-components'

import { Button } from '@syconium/little-miss-figgy/dist/components/Button/Button'
import { LoadingSpinner } from '@syconium/little-miss-figgy/dist/components/LoadingSpinner/LoadingSpinner'

export const FormSubmitSpinner = styled(
  ({ size = '20px', ...rest }: ComponentProps<typeof LoadingSpinner>) => {
    return <LoadingSpinner size={size} {...rest} />
  }
)`
  position: absolute;
  inset: auto auto auto auto;
`

export const FormSubmit = styled(
  ({
    children,
    className,
    submittingLabel,
  }: {
    className?: string
    children: React.ReactNode
    submittingLabel: string
  }) => {
    const formContext = useFormikContext()
    return (
      <Button className={className} type='submit'>
        <span style={formContext.isSubmitting ? { opacity: 0 } : {}}>{children}</span>
        {formContext.isSubmitting ? <FormSubmitSpinner label={submittingLabel} /> : null}
      </Button>
    )
  }
)`
  position: relative;
`
