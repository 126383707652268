import * as React from 'react'

import { Accordion } from '@syconium/little-miss-figgy'

import { LogoDigitizationSetting } from '../../../../../__generated__/graphql/catalog/graphql'
import { useLocalization } from '../../../../../app/_providers/LocalizationProvider.client'
import { useTranslation } from '../../../../../app/_providers/TranslationProvider.client'
import { DTCLogoSneakPeek } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/DTCLogoSneakpeek'
import { IconSneakPeek } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/IconSneakPeek'
import { LogoSneakPeek } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/LogoSneakPeek'
import { SneakPeeksWrapper } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/styles'
import { TextSneakPeek } from '../../../../../components/pages/products/ProductPage/embroidery/EmbroiderySneakPeek/TextSneakPeek'
import { intlPrettyCurrency } from '../../../../../lib/utils'
import { CartItemProperties } from '../../../../../types/figs'
import { PriceAndDeleteButton } from '../PriceAndDeleteButton'

import {
  Body,
  CostBreakdownContainer,
  CostBreakdownLabel,
  CostBreakdownRow,
  Inner,
  Label,
  StyledAccordionBody,
  StyledAccordionHeader,
  Top,
} from './styles'

interface Props {
  cartItemProperties: CartItemProperties
  label: string
  effectivePrice: number
  eventCategory: 'minicart' | 'cart'
  fullPrice: number
  removeItem(): void
  title: string
  inKit?: boolean
}

const EmbroideryCostBreakdown: React.FC<{
  logoSetupFee: number
  effectivePrice: number
  title: string
}> = ({ logoSetupFee, effectivePrice, title }) => {
  const { locale, region } = useLocalization()
  const {
    embroidery: { embroideryCostBreakdown },
  } = useTranslation()

  /**
   * Maps original embroidery title to localized version if available.
   * e.g. Converts "Logo Embroidery Text Color" to "LOGO_EMBROIDERY_TEXT_COLOR"
   * for dictionary lookup in embroideryCostBreakdown.embroideryTitles
   */
  const getMappedEmbroideryTitle = (originalTitle: string) => {
    const key = originalTitle.replace(/\s+/g, '_').toUpperCase()
    return key in embroideryCostBreakdown.embroideryTitles
      ? embroideryCostBreakdown.embroideryTitles[
          key as keyof typeof embroideryCostBreakdown.embroideryTitles
        ]
      : originalTitle
  }

  const translatedTitle = getMappedEmbroideryTitle(title)
  const formattedPrice = intlPrettyCurrency({
    cents: effectivePrice,
    region: region,
    locale: locale,
  })
  const formmatedLogoSetupFee = intlPrettyCurrency({
    cents: logoSetupFee,
    region: region,
    locale: locale,
  })

  return (
    <Accordion
      variant='light'
      icon='carat'
      ariaLabel='Embroidery Cost Breakdown'
      withAnimation={true}
    >
      <Accordion.Section index={0}>
        <StyledAccordionHeader>
          <CostBreakdownLabel>{embroideryCostBreakdown.title}</CostBreakdownLabel>
        </StyledAccordionHeader>
        <StyledAccordionBody>
          <CostBreakdownContainer>
            <CostBreakdownRow>
              <CostBreakdownLabel>{translatedTitle}</CostBreakdownLabel>
              <CostBreakdownLabel>{formattedPrice}</CostBreakdownLabel>
            </CostBreakdownRow>
            {logoSetupFee > 0 && (
              <CostBreakdownRow>
                <CostBreakdownLabel>
                  {embroideryCostBreakdown.logoDigitizationFee}
                </CostBreakdownLabel>
                <CostBreakdownLabel>{formmatedLogoSetupFee}</CostBreakdownLabel>
              </CostBreakdownRow>
            )}
          </CostBreakdownContainer>
        </StyledAccordionBody>
      </Accordion.Section>
    </Accordion>
  )
}

export const Template: React.FC<Props> = ({
  cartItemProperties,
  effectivePrice,
  eventCategory,
  fullPrice,
  label,
  removeItem,
  title,
  inKit,
}) => {
  const hasLogo = cartItemProperties._logo_image_source
  const hasIcon = cartItemProperties._icon_image_source && cartItemProperties.icon_placement
  const hasDtcLogo = cartItemProperties.logo_preview
  const hasDtcLogoSetup = cartItemProperties._logo_setup_fee ? true : false
  const logoSetupFee = hasDtcLogoSetup ? Number(cartItemProperties._logo_setup_fee) : 0

  return (
    <Body inKit={inKit} data-testid='embroidery-cart-item'>
      <Inner inKit={inKit}>
        <Top inKit={inKit}>
          <Label>{label}</Label>

          <PriceAndDeleteButton
            effectivePrice={effectivePrice + logoSetupFee}
            eventCategory={eventCategory}
            fullPrice={fullPrice + logoSetupFee}
            removeItem={removeItem}
            title={title}
          />
        </Top>
        <SneakPeeksWrapper>
          {title && (
            <EmbroideryCostBreakdown
              logoSetupFee={logoSetupFee}
              effectivePrice={effectivePrice}
              title={title}
            />
          )}
          <TextSneakPeek
            role='listitem'
            backgroundColor={cartItemProperties._scrub_color}
            textColor={cartItemProperties.text_color}
            textFirstLine={cartItemProperties.text_first_line}
            textFont={cartItemProperties.text_font}
            textPlacement={cartItemProperties.text_placement}
            textSecondLine={cartItemProperties.text_second_line}
          />
          {hasLogo && (
            <LogoSneakPeek
              role='listitem'
              backgroundColor={cartItemProperties._scrub_color}
              imgSrc={cartItemProperties._logo_image_source}
              logoName={cartItemProperties.logo_name}
              logoPlacement={cartItemProperties.logo_placement}
            />
          )}
          {hasDtcLogo && (
            <DTCLogoSneakPeek
              role='listitem'
              backgroundColor={cartItemProperties._scrub_color}
              imgSrc={cartItemProperties.logo_preview}
              logoName={cartItemProperties.logo_name}
              logoPlacement={cartItemProperties.logo_placement}
              logoColor={cartItemProperties.logo_color_option as LogoDigitizationSetting}
              logoNote={cartItemProperties.logo_note}
              hasLogoSetup={hasDtcLogoSetup}
            />
          )}
          {hasIcon && (
            <IconSneakPeek
              role='listitem'
              backgroundColor={cartItemProperties._scrub_color}
              iconImageSource={cartItemProperties._icon_image_source}
              iconInitials={cartItemProperties.icon_initials}
              iconInitialsColor={cartItemProperties.icon_initials_color}
              iconDesignName={cartItemProperties.icon_design}
              iconPlacement={cartItemProperties.icon_placement}
            />
          )}
        </SneakPeeksWrapper>
      </Inner>
    </Body>
  )
}
