'use client'

import { useMemo, useState } from 'react'
import { useSearchParam } from 'react-use'

import { gql } from '../../__generated__/graphql/catalog/gql'
import { CartContainer } from '../../brunswick/containers/cart'
import { reportClientError } from '../_components/chrome/scripts/DataDogRumScript'

import { useNextQuery } from './useNextQuery'

export const getRecoveredCartItemsQuery = gql(`
  query GetRecoveredCartItems($skus: [String]) {
    variants(skus: $skus) {
      nodes {
        __typename
        handle
        rawCategory
        shopifyId
        currencyType
        discountPrice
        sku
        category
        externalParentId
        price
        product {
          __typename
          id
          handle
          availableForSale
          finalSale
          swPromoEligible
          shopifyId
        }
      }
    }
  }
`)

export const useCartRecovery = () => {
  const queryKey = 'cart'
  const initialQueryValue = useSearchParam(queryKey)
  const { addItems, cart, status: cartStatus } = CartContainer.useContainer()

  // The initial comma-separated string of SKU's from the cart recovery URL parameter
  // This will be set to null once all items in the query are recovered
  const [recoveryQuery, setRecoveryQuery] = useState<string | null>(initialQueryValue)

  // Get applicable skus that are present in the recovery query
  const recoveredSkus = useMemo(() => {
    if (recoveryQuery && recoveryQuery.length > 0 && cartStatus !== 'pending') {
      const skus = (recoveryQuery as string).split(',')
      // Filter out skus that are already in the cart
      return skus.filter(sku => {
        const alreadyExists = Object.values(cart.items).some(item => item.sku === sku)
        return !alreadyExists
      })
    }
    return []
  }, [recoveryQuery, cartStatus, cart.items])

  // Fetch variants for pending skus
  useNextQuery(getRecoveredCartItemsQuery, {
    variables: { skus: recoveredSkus },
    skip: recoveredSkus.length === 0,
    ssr: false,
    onCompleted: data => {
      // create local cart item objects from response, excluding embroidery
      const recoveredCartItems: Parameters<typeof addItems>[0] = (data.variants?.nodes ?? [])
        .filter(recoveredVariant => recoveredVariant.rawCategory.toLowerCase() !== 'embroidery')
        .map(recoveredVariant => {
          if (!recoveredVariant.sku) return null
          return {
            finalSale: recoveredVariant.product.finalSale,
            item: {
              shopifyId: recoveredVariant.shopifyId,
              currencyType: recoveredVariant.currencyType,
              discountPrice: recoveredVariant.discountPrice,
              handle: recoveredVariant.handle,
              sku: recoveredVariant.sku,
              category: recoveredVariant.category,
              externalParentId: recoveredVariant.externalParentId,
              product: {
                shopifyId: recoveredVariant.product.shopifyId,
              },
            },
            properties: {},
            quantity: 1,
            swPromoEligible: recoveredVariant.product.swPromoEligible,
          }
        })
        .filter((ci): ci is NonNullable<typeof ci> => !!ci)

      if (recoveredCartItems.length > 0) {
        addItems(recoveredCartItems)
      }
      setRecoveryQuery(null)
    },
    onError: error => {
      reportClientError({
        error,
        context: {
          scope: 'Cart Recovery Error',
        },
      })
    },
  })

  return null
}

export const CartRecovery = () => {
  useCartRecovery()
  return null
}
