import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_BULK_LAYOUT = gql(`
  query FetchBulkLayout($handle: String!) {
    collectionView(handle: $handle) {
      title
      sections {
        __typename
        ... on CollectionViewHeroSection {
          id
          title
          subtitle
          imagePlacement
          imageDesktop
          imageMobile
          name
        }
        ... on CollectionGridSection {
          visualFiltersSectionV2 {
            tiles {
              id
              ctaText
              filter
              value
            }
          }
        }
      }
    }
  }
`)
