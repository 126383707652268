'use client'

import { DialogDismiss, Popover, PopoverDisclosure, PopoverProvider } from '@ariakit/react'
import { ComponentProps } from 'react'
import styled from 'styled-components'

import { IconButton } from '@syconium/little-miss-figgy/dist/components/IconButton/IconButton'
import { SelectMark } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/SelectMark'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'

import { PopoverHovercardStyles } from './PopoverHovercard.client'

export const PopoverDialogBackdrop = styled.div<{ $clearBackdrop?: boolean }>`
  ${o =>
    o.$clearBackdrop
      ? null
      : `
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  `};

  position: fixed;
  inset: 0px;
  z-index: ${o => o.theme.zIndex.modals};
  display: block;
`

type PopoverDialogProviderProps = ComponentProps<typeof PopoverProvider>

export const PopoverDialogProvider = (props: PopoverDialogProviderProps) => {
  return <PopoverProvider {...props} />
}

type PopoverDialogTriggerButtonProps = ComponentProps<typeof PopoverDisclosure> & {
  render: NonNullable<ComponentProps<typeof PopoverDisclosure>['render']>
}

export const PopoverDialogTriggerButton = ({
  render,
  ...props
}: PopoverDialogTriggerButtonProps) => {
  return <PopoverDisclosure render={render} {...props} />
}

type PopoverDialogDismissProps = ComponentProps<typeof IconButton> & {
  closeText: string
}

export const PopoverDialogDismiss = ({
  closeText,
  forwardedAs = DialogDismiss,
  variant = 'black-on-white',
  borderless = true,
  ...props
}: PopoverDialogDismissProps) => {
  return (
    <IconButton forwardedAs={forwardedAs} variant={variant} borderless={borderless} {...props}>
      <ScreenReaderOnly>{closeText}</ScreenReaderOnly>
      <SelectMark aria-hidden='true' />
    </IconButton>
  )
}

type PopoverDialogProps = ComponentProps<typeof Popover> & {
  clearBackdrop?: boolean
}

export const PopoverDialog = styled(
  ({
    backdrop = <PopoverDialogBackdrop />,
    preventBodyScroll = true,
    modal = true,
    focusable = false,
    ...props
  }: PopoverDialogProps) => {
    return (
      <PopoverHovercardStyles.Hovercard
        as={Popover}
        preventBodyScroll={preventBodyScroll}
        modal={modal}
        backdrop={backdrop}
        focusable={focusable}
        {...props}
      />
    )
  }
)`
  z-index: ${o => o.theme.zIndex.modals};
`
