import { gql } from '../../../__generated__/graphql/catalog'

export const GET_COLLECTION_SECTION_PRODUCTS = gql(`
  query GetCollectionSectionProducts(
    $first: Int!
    $handle: String!
  ) {
    collection: optimizedCollection(handle: $handle) {
      items(first: $first) {
        nodes {
          id
          shopifyId
          handle
          category
          colorInfo {
            handle
            name
            rawName
          }
          images {
            nodes {
              source
            }
          }
          productGroup {
            handle
            title
            numColors
            priceRange {
              min
              max
            }
          }
          defaultVariant {
            priceDetails {
              price {
                amount
                currency
              }
              discountPrice {
                amount
              }
            }
          }
        }
      }
    }
  }
`)
