import styled from 'styled-components'

import { TypeStyle, untilMd } from '@syconium/little-miss-figgy'

export const Price = styled.span`
  ${TypeStyle.css.headlineExtraExtraSmall};
  color: ${o => o.theme.color.text.primary.on.background};
  display: inline-block;
`

export const DiscountPrice = styled(Price)<{
  $displayDiscountAsValue?: boolean
}>`
  ${untilMd} {
    margin: 0 0 0 0;
  }
  color: ${o =>
    o.$displayDiscountAsValue
      ? o.theme.color.text.secondary.on.background
      : o.theme.color.text.critical.on.background};
`

export const CrossedOutPrice = styled.span`
  ${TypeStyle.css.headlineExtraExtraSmall};
  color: ${o => o.theme.color.text.tertiary.on.background};
  display: inline-block;
  text-decoration: line-through;
`

export const ValuePrice = styled(Price)`
  color: ${o => o.theme.color.text.tertiary.on.background};
`
