import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import Cookies from 'universal-cookie'

import { CheckoutErrorCode } from '../../__generated__/graphql/shopify/graphql'
import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'
import { convertCookiesToAccountAuthorization } from '../../app/_config/Authentication.config'
import { cookieKeys } from '../../app/_config/Cookies.config'
import { sessionStorageKeys } from '../../app/_config/Session.config'
import { Checkout, LanguageCode } from '../../types/shopify-storefront-api'

import { ICheckoutClient } from './interfaces'
import {
  ASSOCIATE_CUSTOMER_TO_CHECKOUT,
  CREATE_CHECKOUT_WITH_LINE_ITEMS,
  FETCH_CHECKOUT,
} from './shopifyQueries'
import {
  CheckoutCreate,
  CheckoutCreateVariables,
  CheckoutCustomerAssociateV2,
  CheckoutUserError,
  IterableKeys,
} from './types'
import { shippingAddressForRegion } from './utils'

export class ShopifyStorefrontApiClient implements ICheckoutClient {
  private cookies = new Cookies()
  private apolloClient: ApolloClient<NormalizedCacheObject>

  constructor({ apolloClient }: { apolloClient: ApolloClient<NormalizedCacheObject> }) {
    this.apolloClient = apolloClient
  }

  private get iterableAttributionValues(): { key: string; value: string }[] {
    const iterableKeys = [
      IterableKeys.CAMPAIGN_ID,
      IterableKeys.MESSAGE_ID,
      IterableKeys.TEMPLATE_ID,
      IterableKeys.USER_ID,
      IterableKeys.SMS_CAMPAIGN_ID,
    ]
    const values: { key: string; value: string }[] = []

    iterableKeys.forEach(key => {
      const value = this.cookies.get(key) ?? ''

      // currently we map both email and sms campaigns to the same checkout attribute but sms takes precedence
      if (key === IterableKeys.SMS_CAMPAIGN_ID) key = IterableKeys.CAMPAIGN_ID

      values.push({ key, value })
    })

    return values
  }

  private async _createCheckoutWithCartItems({
    items,
    regionId,
    languageGroup,
    globalCustomAttributes = [],
  }: Omit<
    Parameters<ICheckoutClient['createCheckoutWithCartItems']>[0],
    'customerShopifyToken'
  >): Promise<CheckoutCreate> {
    const account = convertCookiesToAccountAuthorization({
      authCookie: this.cookies.get(cookieKeys.authToken.key) ?? undefined,
      authRefreshCookie: this.cookies.get(cookieKeys.authRefreshToken.key) ?? undefined,
      shopifyCustomerCookie: this.cookies.get(cookieKeys.shopifyCustomerToken.key) ?? undefined,
    })
    const isLoggedIn = !!account
    const iterableValues = this.iterableAttributionValues
    const language = languageGroup.toUpperCase() as LanguageCode

    const currentLocation =
      globalThis.document.location.protocol +
      '//' +
      globalThis.document.location.hostname +
      globalThis.document.location.pathname +
      globalThis.document.location.search
    const landingLocation = globalThis.sessionStorage?.getItem(sessionStorageKeys.landingPage)

    const customAttributes = [
      {
        key: 'landing_site',
        value: landingLocation ?? currentLocation,
      },
      {
        key: 'is_customer_logged_in',
        value: String(isLoggedIn),
      },
    ]

    iterableValues.forEach(item => {
      if (item.value) customAttributes.push(item)
    })

    const checkout = await this.apolloClient.mutate<CheckoutCreate, CheckoutCreateVariables>({
      mutation: CREATE_CHECKOUT_WITH_LINE_ITEMS,
      variables: {
        allowPartialAddresses: true,
        lineItems: items,
        shippingAddress: regionId === 'US' ? undefined : shippingAddressForRegion(regionId),
        customAttributes: [...customAttributes, ...globalCustomAttributes],
        country: regionId,
        language: language,
      },
      context: {
        retryOnFailure: true,
      },
    })

    return checkout as unknown as CheckoutCreate
  }

  private filterInvalidCartItemsOnError<T>(items: T[], errors: CheckoutUserError[]): T[] {
    let validItems: T[] = []
    const invalidIndices: number[] = []
    const errorCodesToHandle: CheckoutErrorCode[] = ['NOT_ENOUGH_IN_STOCK', 'PRODUCT_NOT_AVAILABLE']
    for (let e of errors) {
      // check for specific codes, as well as any that begin with `INVALID`
      if (errorCodesToHandle.includes(e.code) || e.code.startsWith('INVALID')) {
        invalidIndices.push(Number.parseInt(e.field[2]!))
      }
    }
    items.forEach((item: T, index: number) => {
      if (!invalidIndices.includes(index)) validItems.push(item)
    })
    return validItems
  }

  /*
     In some cases a client can have items in their cart which have become unavailable in the shop
     between the time it was added and the time they attempt to checkout (purchase). In this event
     we have an array of INVALID errors returned to us from the Shopify API that indicates which item(s)
     is/are no longer available (i.e. "invalid") by its/their index-position(s) in the items array. We then filter
     those items out and re-attempt the checkout creation. (matt 5.25.21)
  */
  public async createCheckoutWithCartItems({
    items,
    regionId,
    languageGroup,
    customerShopifyToken,
    globalCustomAttributes = [],
  }: Parameters<ICheckoutClient['createCheckoutWithCartItems']>[0]): ReturnType<
    ICheckoutClient['createCheckoutWithCartItems']
  > {
    // Custom attributes that should always be applied for orders from Magnolia
    const staticCustomAttributes = [{ key: 'appSource', value: 'web' }]

    let checkout = await this._createCheckoutWithCartItems({
      items,
      regionId,
      languageGroup,
      globalCustomAttributes: [...staticCustomAttributes, ...globalCustomAttributes],
    })

    let validatedItems: typeof items = []
    if (checkout.data?.checkoutCreate.checkoutUserErrors.length) {
      reportClientError({
        error: new Error('[ERROR]: checkoutCreate failed'),
        context: {
          scope: 'checkout',
          userErrors: checkout.data.checkoutCreate.checkoutUserErrors,
        },
      })

      validatedItems = this.filterInvalidCartItemsOnError(
        items,
        checkout.data.checkoutCreate.checkoutUserErrors
      )
    }

    if (validatedItems.length) {
      checkout = await this._createCheckoutWithCartItems({
        items: validatedItems,
        regionId,
        languageGroup,
      })
    }

    if (
      !checkout.data ||
      !checkout.data.checkoutCreate?.checkout ||
      checkout.data.checkoutCreate.checkoutUserErrors.length
    ) {
      if (!validatedItems.length) {
        reportClientError({
          error: new Error('[ERROR]: checkout attempted with no valid items'),
          context: {
            scope: 'checkout',
          },
        })
      } else {
        throw new Error('Checkout failed!')
      }
    }

    const checkoutId = checkout.data.checkoutCreate.checkout.id
    const checkoutUrlString = checkout.data.checkoutCreate.checkout.webUrl
    const checkoutUrl = new URL(checkoutUrlString)
    const discountCode = globalThis.sessionStorage?.getItem(sessionStorageKeys.discount)
    if (discountCode) checkoutUrl.searchParams.append('discount', discountCode)
    if (customerShopifyToken) await this.associateCustomer({ checkoutId, customerShopifyToken })

    return {
      checkoutUrl,
      checkoutId,
    }
  }

  public async isCheckoutCompleted({
    checkoutId,
  }: Parameters<ICheckoutClient['isCheckoutCompleted']>[0]): ReturnType<
    ICheckoutClient['isCheckoutCompleted']
  > {
    const checkout = await this.fetchCheckout(checkoutId)
    return checkout?.completedAt ? true : false
  }

  private async associateCustomer({
    customerShopifyToken,
    checkoutId,
  }: {
    customerShopifyToken: string
    checkoutId: string
  }) {
    ;(await this.apolloClient.mutate<
      CheckoutCustomerAssociateV2,
      { checkoutId: string; customerAccessToken: string }
    >({
      mutation: ASSOCIATE_CUSTOMER_TO_CHECKOUT,
      variables: {
        checkoutId,
        customerAccessToken: customerShopifyToken,
      },
    })) as unknown as CheckoutCustomerAssociateV2

    return Promise.resolve()
  }

  private async fetchCheckout(checkoutId: string): Promise<Checkout | null> {
    const { data, error } = await this.apolloClient.query<{ node: Checkout }>({
      query: FETCH_CHECKOUT,
      variables: {
        id: checkoutId,
      },
      fetchPolicy: 'network-only',
    })
    if (error) throw error
    return data?.node
  }
}
