'use client'

import { ApolloError } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { gql } from '../../__generated__/graphql/catalog/gql'
import { MyPortalProfileQuery } from '../../__generated__/graphql/catalog/graphql'
// It is very weird that Portal ends up with a dependency on QuickBuy... which is a UI feature. Not sure why EmbroideryConfig type would be in there :cry:
import { type EmbroideryConfig } from '../../brunswick/containers/QuickBuyContainer/types'
import { QueryStatus } from '../../lib/hooks/types'
import { EmbroideryVariant } from '../../types/graphql/product'
import { useNextQuery } from '../_hooks/useNextQuery'

import { useAuthentication } from './AuthenticationProvider.client'

interface UsePortalConfigResult {
  error?: ApolloError
  portal?: MyPortalProfileQuery['my']['portal']
  stipend: number | null
  status: Exclude<QueryStatus, 'idle'>
  redirectWhenNotAvailable: (
    options: Parameters<ReturnType<typeof useAuthentication>['navigateToLogin']>[0]
  ) => void
  hasMandatoryTextEmbroidery: (category?: string) => boolean
  hasMandatoryLogoEmbroidery: (category?: string) => boolean
  hasPortalLogoEmbroideryOptions: <T extends { kind: string }>(
    embroideryOptions: readonly T[]
  ) => boolean
  createEmbroideryConfig: (args: {
    embroideryVariant?: EmbroideryVariant
    productCategory: string
  }) => EmbroideryConfig | undefined
}

/* List of portal-specific embroidery options. Used to identify if a given set of embroidery options contain the expected portal logo options. */
const PORTAL_EMBROIDERY_OPTION_KINDS: Readonly<Array<string>> = ['LOGO_ONLY', 'LOGO_TEXT']

const usePortalConfigImpl = (): UsePortalConfigResult => {
  const { isLoggedIn, navigateToLogin } = useAuthentication()

  const {
    loading: myProfileLoading,
    error: myProfileError,
    data: myProfileData,
  } = useNextQuery(
    gql(`
      query GetUserForPortalContainer {
        my {
          id
          portal {
            id
          }
        }
      }`),
    {
      client: 'auth-supported',
      skip: !isLoggedIn,
    }
  )

  const {
    loading: myPortalProfileLoading,
    error: myPortalProfileError,
    data: myPortalProfileData,
  } = useNextQuery(
    gql(`
      query myPortalProfile {
        my {
          id
          profile {
            stipend
          }
          portal {
            id
            collectionHandle
            collectionHeroSectionCG {
              backgroundColor
              ctaText
              id
              imageDesktop
              imageMobile
              imagePlacement
              inlineVideoDesktop
              inlineVideoMobile
              inlineVimeoIdDesktop
              inlineVimeoIdMobile
              subtitle
              textColor
              title
              videoId
              videoPlatform
            }
            promoBarPages {
              id
              content
            }
            embroidery {
              image
              addText
              yesText
              noText
              logoName
              eligibleProductCategories
              mandatoryTextEmbroideryCategories
              mandatoryLogoEmbroideryCategories
              embroideryDiscountPercentage
              defaultTextEmbroideryValues {
                textColor
                font
                textPlacement
              }
              textEmbroiderySecondLineOptions
              hiddenTextEmbroideryFields
            }
            name
            discountPercentage
            pdpCtaText
            quickBuyText
          }
        }
      }
    `),
    {
      client: 'auth-supported',
      skip: myProfileData?.my?.portal?.id ? false : true,
    }
  )

  const portal = useMemo<UsePortalConfigResult['portal']>(() => {
    if (!isLoggedIn) return undefined
    return myPortalProfileData?.my?.portal ?? undefined
  }, [myPortalProfileData?.my?.portal, isLoggedIn])

  const stipend = useMemo<UsePortalConfigResult['stipend']>(() => {
    if (!isLoggedIn) return null
    return myPortalProfileData?.my?.profile?.stipend ?? null
  }, [myPortalProfileData?.my?.profile?.stipend, isLoggedIn])

  const status = useMemo<UsePortalConfigResult['status']>(() => {
    if (!isLoggedIn) return 'resolved'
    if (myProfileLoading || myPortalProfileLoading) return 'pending'
    if (myProfileError || myPortalProfileError) return 'rejected'
    return 'resolved'
  }, [myProfileLoading, myPortalProfileLoading, myProfileError, myPortalProfileError, isLoggedIn])

  const redirectWhenNotAvailable = useCallback<UsePortalConfigResult['redirectWhenNotAvailable']>(
    options => {
      if (!isLoggedIn) {
        navigateToLogin(options)
      } else {
        window.location.replace('/')
      }
    },
    [isLoggedIn, navigateToLogin]
  )

  // Check if the current portal enforces mandatory text embroidery for a given product category
  const hasMandatoryTextEmbroidery = useCallback<
    UsePortalConfigResult['hasMandatoryTextEmbroidery']
  >(
    category =>
      Boolean(category && portal?.embroidery?.mandatoryTextEmbroideryCategories.includes(category)),
    [portal?.embroidery?.mandatoryTextEmbroideryCategories]
  )

  // Check if the current portal enforces mandatory logo embroidery for a given product category
  const hasMandatoryLogoEmbroidery = useCallback<
    UsePortalConfigResult['hasMandatoryLogoEmbroidery']
  >(
    category =>
      Boolean(category && portal?.embroidery?.mandatoryLogoEmbroideryCategories.includes(category)),
    [portal?.embroidery?.mandatoryLogoEmbroideryCategories]
  )

  // Check if the current embroidery options contain the expected portal-specific `LOGO_ONLY` and `LOGO_TEXT` options
  const hasPortalLogoEmbroideryOptions = useCallback<
    UsePortalConfigResult['hasPortalLogoEmbroideryOptions']
  >(embroideryOptions => {
    return embroideryOptions.some(e => PORTAL_EMBROIDERY_OPTION_KINDS.includes(e.kind))
  }, [])

  // Create the portal-specific embroidery config object
  const createEmbroideryConfig = useCallback<UsePortalConfigResult['createEmbroideryConfig']>(
    ({ embroideryVariant, productCategory }) => {
      const embroiderySettings = portal?.embroidery
      const enabledProductCategory =
        embroiderySettings?.eligibleProductCategories.includes(productCategory)

      // Return undefined if:
      // 1) No embroidery variant was provided
      // 2) No embroidery settings exist for the current portal
      // 3) The current product category is not specified as eligible for portal embroidery
      if (!embroideryVariant || !embroiderySettings || !enabledProductCategory) return undefined
      return {
        image: embroiderySettings.image,
        logoName: embroiderySettings.logoName,
        noText: embroiderySettings.noText ?? '',
        questionText: embroiderySettings.addText,
        yesText: embroiderySettings.yesText ?? '',
        variant: embroideryVariant,
        quickBuyText: portal?.quickBuyText ?? '',
        mandatoryLogoEmbroidery: hasMandatoryLogoEmbroidery(productCategory),
      }
    },
    [hasMandatoryLogoEmbroidery, portal?.embroidery, portal?.quickBuyText]
  )

  return useMemo(() => {
    return {
      portal,
      stipend,
      status,
      error: myPortalProfileError,
      redirectWhenNotAvailable,
      hasMandatoryTextEmbroidery,
      hasMandatoryLogoEmbroidery,
      hasPortalLogoEmbroideryOptions,
      createEmbroideryConfig,
    }
  }, [
    portal,
    stipend,
    status,
    myPortalProfileError,
    hasMandatoryLogoEmbroidery,
    hasMandatoryTextEmbroidery,
    hasPortalLogoEmbroideryOptions,
    redirectWhenNotAvailable,
    createEmbroideryConfig,
  ])
}

const PortalConfigContainer = createContainer(usePortalConfigImpl)
PortalConfigContainer.Provider.displayName = 'PortalProviderImpl'
export const PortalProvider = PortalConfigContainer.Provider
export const usePortal = PortalConfigContainer.useContainer
