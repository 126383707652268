'use client'

import { useCallback } from 'react'
import { createContainer } from 'unstated-next'

import {
  PersonalizationTargetCriteria,
  validatePersonalizationTargetCriteria as validatePersonalizationTargetCriteriaImpl,
} from '../_config/Personalization.config'

import { useAuthentication } from './AuthenticationProvider.client'
import { useLocalization } from './LocalizationProvider.client'
import { useSession } from './SessionProvider.client'

const usePersonalizationImpl = () => {
  const { isLoggedIn } = useAuthentication()
  const { attributes: sessionAttributes } = useSession()
  const localizationAttributes = useLocalization()

  const validatePersonalizationTargetCriteria = useCallback(
    (targetting: PersonalizationTargetCriteria) => {
      return validatePersonalizationTargetCriteriaImpl({
        targetting,
        isLoggedIn,
        sessionAttributes,
        localizationAttributes,
      })
    },
    [localizationAttributes, sessionAttributes, isLoggedIn]
  )

  return {
    validatePersonalizationTargetCriteria,
  }
}

const PersonalizationContainer = createContainer(usePersonalizationImpl)
PersonalizationContainer.Provider.displayName = 'PersonalizationProviderImpl'
export const PersonalizationProvider = ({ children }: { children: React.ReactNode }) => {
  return <PersonalizationContainer.Provider>{children}</PersonalizationContainer.Provider>
}
export const usePersonalization = PersonalizationContainer.useContainer
