import pickBy from 'lodash-es/pickBy'
import { useCallback } from 'react'

import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

type SkuBearingItem =
  | {
      sku: string | null
      defaultVariant?: never
    }
  | {
      defaultVariant: {
        sku: string | null
      }
    }

const regionSpecificBaseSkuConfig: Record<string, SupportedRegionIdentifier[]> = {
  QRGCARD: ['US'],
  GIFTCARD: ['US'],
}

export function useInternationalAvailability() {
  const isAvailableForThisRegion = useCallback(
    <T extends SkuBearingItem>({
      item,
      regionId,
    }: {
      item: T
      regionId: SupportedRegionIdentifier
    }) => {
      const itemSku = item.defaultVariant ? item.defaultVariant.sku : item.sku
      const regionSpecificBaseSkus = Object.keys(regionSpecificBaseSkuConfig)

      const unavailableForThisRegion = regionSpecificBaseSkus.some(regionSpecificBaseSku => {
        const whiteListedRegions = regionSpecificBaseSkuConfig[regionSpecificBaseSku]
        return (
          itemSku &&
          itemSku.startsWith(regionSpecificBaseSku) &&
          whiteListedRegions &&
          !whiteListedRegions.includes(regionId)
        )
      })

      return !unavailableForThisRegion
    },
    []
  )

  const filterUnavailableItemsFromArray = useCallback(
    <T extends SkuBearingItem>({
      items,
      regionId,
    }: {
      items: T[]
      regionId: SupportedRegionIdentifier
    }) => {
      return items.filter(item => {
        return isAvailableForThisRegion({
          item,
          regionId,
        })
      })
    },
    [isAvailableForThisRegion]
  )

  const filterUnavailableItemsFromMap = useCallback(
    <T extends SkuBearingItem>({
      itemsMap,
      regionId,
    }: {
      itemsMap: Record<string, T>
      regionId: SupportedRegionIdentifier
    }) => {
      return pickBy(itemsMap, (item: T) => {
        return isAvailableForThisRegion({
          item,
          regionId,
        })
      })
    },
    [isAvailableForThisRegion]
  )

  return {
    filterUnavailableItemsFromArray,
    filterUnavailableItemsFromMap,
  }
}
