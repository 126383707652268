import { gql } from '../../../__generated__/graphql/catalog'

export const EMBROIDERY_ICON_GROUPS_QUERY = gql(`
  query FetchEmbroideryIconGroups($isPreview: Boolean) {
    embroideryIconGroups(preview: $isPreview) {
      id
      name
      displayName
      sortOrder
      maxIconsPerRowMobile
      disabledInitialsText
    }
  }
`)
