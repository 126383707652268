import styled from 'styled-components'

import { TypeStyle, fromMd, timingDefault } from '@syconium/little-miss-figgy'

export const FreeShippingContainer = styled.div`
  background: white;
  padding: ${o => o.theme.spacing(0, 4)};
  position: relative;

  ${fromMd} {
    padding: ${o => o.theme.spacing(0, 10)};
  }
`

export const FreeShippingGoal = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`

export const FreeShippingTranslate = styled.div<{ $runAnimation?: boolean }>`
  display: ${o => !o.$runAnimation && 'flex'};
  transition: all 1s ease-out;
  padding-top: 10px;

  ${o =>
    o.$runAnimation &&
    `
      & > div {
        display: inline-flex;
        transform: translateY(23%);
      }
  `}
`

export const FreeShippingText = styled(TypeStyle.BodySmall)``

const Bar = styled.div`
  background: ${o => o.theme.color.fill.disabled.subtle};
  border-radius: 4px;
  box-sizing: content-box;
  height: 6px;
  margin-top: ${o => o.theme.spacing(3)};
  max-width: 100%;
  position: relative;
`

const Progress = styled.div<{ $progressAsPercent: number }>`
  background: ${o => o.theme.color.text.primary.on.background};
  border-radius: 100px;
  height: 100%;
  max-width: 100%;
  transition: width ${timingDefault} ease-in-out;
  width: ${o => o.$progressAsPercent}%;
`

export const ProgressBar = {
  Bar,
  Progress,
}

export const CheckMarkWrap = styled.div`
  background-color: black;
  border-radius: 100px;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;
  margin-right: ${o => o.theme.spacing(4)};

  & > div > svg {
    color: white;
    display: block;
    margin-top: 6px;
  }
`

const Fade = styled.div<{
  $fadeDirection: 'in' | 'out'
  // Used to give unique names to each animation and for testing
  id: string
  $runAnimation?: boolean
}>`
  ${o =>
    o.$runAnimation &&
    `
    animation-direction: normal;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: ${o.id};
    animation-play-state: running;
    animation-timing-function: ease-${o.$fadeDirection};

    @keyframes ${o.id}Fade${o.$fadeDirection} {
      0% {
        opacity: ${o.$fadeDirection === 'in' ? 0 : 1};
      }
      100% {
        opacity: ${o.$fadeDirection === 'in' ? 1 : 0};
      }
    }
`}
`

export const FreeShippingFadeInAndDown = styled.div<{ $runAnimation?: boolean }>`
  ${o =>
    o.$runAnimation &&
    `
    animation-delay: 0.4s;
    animation-direction: normal;
    animation-duration: ${timingDefault};
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: FreeShippingFade;
    animation-play-state: running;
    animation-timing-function: ease-in;
    transition: transform 0.1s ease-in;
    @keyframes FreeShippingFade {
      0% {
        opacity: 0;
        transform: translateY(-35%);
      }
      100% {
        opacity: 1;
        transform: translateY(-12%);
      }
    }
  `}
`

export const CheckMarkCircleFade = styled(Fade)<{ $runAnimation?: boolean }>`
  ${o =>
    o.$runAnimation &&
    `
    animation-delay: 0.5s;
    animation-direction: normal;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: FreeShippingCheckMarkCircleFade;
    animation-play-state: running;
    animation-timing-function: ease-in;
    transition: transform 0.1s ease-in;
    @keyframes FreeShippingCheckMarkCircleFade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `}
`

export const CheckMarkFade = styled(Fade)<{ $runAnimation?: boolean }>`
  ${o =>
    o.$runAnimation &&
    `
    animation-delay: 1.8s;
    animation-duration: 0.8s;
  `}
`

export const FreeShippingAchievedContainer = styled(Fade)<{
  $runAnimation?: boolean
  $hide: boolean
}>`
  height: 40px;
  background: ${o => o.theme.color.fill.subtle};
  opacity: ${o => o.$hide && 0};
  display: ${o => (o.$hide ? 'none' : 'block')};
  text-align: center;

  ${o =>
    o.$runAnimation &&
    `
    animation-delay: 0.3s;
    animation-duration: 1s;
  `}
`
/**
 * @param $slideDirection used to drive transition/animation for new variant of `minicart-cleanup-test`
 * @param $hide used for legacy/control in `minicart-cleanup-test`. If variant is selected, then this can be removed
 */
export const SpendXMoreContainer = styled.div<{
  $slideDirection: 'in' | 'out'
  $hide: boolean
}>`
  display: ${o => (o.$hide ? 'none' : 'block')};
  height: ${o => (o.$slideDirection === 'out' ? '0px' : '40px')};
  opacity: ${o => (o.$slideDirection === 'out' ? 0 : 1)};
  transition: all ${timingDefault} ease-${o => o.$slideDirection};
  overflow: hidden;
`
