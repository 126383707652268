import styled from 'styled-components'

import { Minus as MinusIcon, atXs, fromMd } from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { CartContainer } from '../../containers/cart'
import { ItemCount, Title } from '../Cart/styles'

export const MiniCartDialogHeader = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  padding: 20px 16.5px;
  position: sticky;
  top: 0;
  z-index: ${o => o.theme.zIndex.foregroundContent};

  svg {
    path {
      stroke-width: 1;
    }
  }

  ${fromMd} {
    padding: 20px 40px;
  }

  ${atXs} {
    padding: 0 16.5px;
  }
`

export const MiniCartDialogHeaderContent = () => {
  const { cart } = CartContainer.useContainer()
  const { cart: miniCartCopy } = useTranslation()

  return (
    <>
      <Title asTag='h2' textTransform='uppercase'>
        {miniCartCopy.title}
      </Title>
      {cart.itemCount > 0 ? (
        <>
          <MinusIcon stroke='currentColor' />
          <ItemCount>{cart.itemCount}</ItemCount>
        </>
      ) : null}
    </>
  )
}
