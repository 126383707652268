import { useSiteWideMessaging } from '../../../app/_providers/SiteWideMessagingProvider.client'

export function IntlShippingAndTaxMsg() {
  const { siteWideRegionSpecificDutiesAndFeesMessage, siteWideRegionSpecificShippingMessage } =
    useSiteWideMessaging()
  return (
    <>
      {siteWideRegionSpecificShippingMessage && <div>{siteWideRegionSpecificShippingMessage}</div>}
      {siteWideRegionSpecificDutiesAndFeesMessage && (
        <div>{siteWideRegionSpecificDutiesAndFeesMessage}</div>
      )}
    </>
  )
}
