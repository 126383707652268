'use client'

import { useHovercardContext, useStoreState } from '@ariakit/react'
import { ComponentProps } from 'react'
import { styled } from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy/dist/constants'
import { withHoverSupport } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { pageLayoutMaxWidthPixels } from '@syconium/little-miss-figgy/dist/constants/layout'
import { focusOutlineInset } from '@syconium/little-miss-figgy/dist/utility-classes'

import { NextLink } from '../navigation/NextLink'
import {
  PopoverDialog,
  PopoverDialogBackdrop,
  PopoverDialogDismiss,
  PopoverDialogProvider,
  PopoverDialogTriggerButton,
} from '../popovers/PopoverDialog.client'
import {
  PopoverHovercard,
  PopoverHovercardProvider,
  PopoverHovercardStyles,
  PopoverHovercardTrigger,
} from '../popovers/PopoverHovercard.client'

import { primaryNavHeaderMinHeightPixels } from './PrimaryNavHeader.client'

type PrimaryNavMenuButtonProps = {
  $textColor?: string | undefined | null
  $underline?: boolean
  $underlineOnFocus?: boolean
  $underlineOnHover?: boolean
  className?: string
  children?: React.ReactNode
}

export const PrimaryNavMenuButton = styled.button<PrimaryNavMenuButtonProps>`
  padding: 0px ${o => o.theme.spacing(4)};
  display: inline-flex;
  align-items: center;
  position: relative;
  user-select: none;
  white-space: nowrap;

  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 18px;
  min-height: ${primaryNavHeaderMinHeightPixels}px;

  color: ${o => o.$textColor ?? o.theme.color.text.primary.on.background};
  -webkit-tap-highlight-color: transparent;

  &:after {
    background: currentColor;
    bottom: 0;
    content: '';
    display: none;
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${o =>
    o.$underline
      ? `
    &:after {
      display: block;
    }
  `
      : null};

  ${withHoverSupport} {
    ${o =>
      o.$underlineOnFocus
        ? `
      &:hover {
        &:after {
          display: block;
        }
      }
    `
        : null};

    ${o =>
      o.$underlineOnFocus
        ? `
      &:focus-visible {
        &:after {
          display: block;
        }
      }
    `
        : null};
  }

  &[aria-selected='true'] {
    &:after {
      display: block;
    }
  }

  &[aria-selected='false'] {
    color: ${o => o.theme.color.text.tertiary.on.background};
  }

  ${focusOutlineInset};
`

type PrimaryNavMenuLinkProps = PrimaryNavMenuButtonProps & {
  href: string
}

export const PrimaryNavMenuLink = ({
  href,
  $underlineOnFocus = true,
  $underlineOnHover = true,
  ...props
}: PrimaryNavMenuLinkProps) => {
  return (
    <PrimaryNavMenuButton
      as={NextLink}
      href={href}
      $underlineOnFocus={$underlineOnFocus}
      $underlineOnHover={$underlineOnHover}
      {...props}
    />
  )
}

export const PrimaryNavMenuHovercardProvider = ({
  placement = 'bottom-start',
  ...props
}: ComponentProps<typeof PopoverHovercardProvider>) => {
  return <PopoverHovercardProvider placement={placement} {...props} />
}

export const PrimaryNavMenuHovercardTrigger = ({
  textColor,
  render: renderProp,
  ...props
}: ComponentProps<typeof PopoverHovercardTrigger> & {
  textColor?: string | undefined | null
}) => {
  const hoverCardContext = useHovercardContext()
  const hoverCardState = useStoreState(hoverCardContext)
  const open = hoverCardState?.open ?? false
  const render = renderProp ?? (props.href ? <NextLink href={props.href} /> : undefined)
  return (
    <PrimaryNavMenuButton
      as={PopoverHovercardTrigger}
      render={render}
      $underline={open}
      $textColor={textColor}
      {...props}
    />
  )
}

const StyledPrimaryNavMenuBody = styled.div`
  padding: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  ${fromMd} {
    padding: ${o => o.theme.spacing(6)};
  }

  box-sizing: border-box;
  width: 100%;
  max-width: ${pageLayoutMaxWidthPixels}px;
  margin: 0px auto;
  max-height: min(var(--popover-available-height), 100vh);
  max-height: min(var(--popover-available-height), 100dvh);
  overflow: auto;
`

export const PrimaryNavMenuHovercard = styled(
  ({
    gutter = 0,
    overflowPadding = 0,
    shift = -16,
    children,
    ...props
  }: ComponentProps<typeof PopoverHovercard> & { $fillViewport?: boolean }) => {
    return (
      <PopoverHovercard gutter={gutter} overflowPadding={overflowPadding} shift={shift} {...props}>
        <StyledPrimaryNavMenuBody>{children}</StyledPrimaryNavMenuBody>
      </PopoverHovercard>
    )
  }
)`
  max-width: 100vw;
  min-width: min(calc(var(--popover-anchor-width) + 32px), 100vw);
  width: unset;
  box-sizing: border-box;
  border: none;
  border-radius: 0px;

  padding: unset;
  max-height: unset;
  overflow: unset;

  ${o =>
    o.$fillViewport
      ? `
    width: 100vw;
  `
      : null};
`

export const PrimaryNavMenuDialogProvider = PopoverDialogProvider

type PrimaryNavMenuDialogTriggerProps = Omit<
  ComponentProps<typeof PopoverDialogTriggerButton>,
  'render'
> & {
  render?: ComponentProps<typeof PopoverDialogTriggerButton>['render']
}

export const PrimaryNavMenuDialogTrigger = ({
  render = <PrimaryNavMenuButton />,
  ...props
}: PrimaryNavMenuDialogTriggerProps) => {
  return <PopoverDialogTriggerButton render={render} {...props} />
}

const StyledPrimaryNavMenuDialogBackdrop = styled.div<{
  $color: 'contrast-background' | 'match-background'
  $position: 'above' | 'below'
}>`
  display: block;
  z-index: ${o => (o.$position === 'above' ? o.theme.zIndex.modals : o.theme.zIndex.modals - 1)};

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${o => (o.$position === 'above' ? 'bottom: 100%;' : 'top: 0;')};

  width: 100vw;
  height: 100vh;

  background-color: ${o =>
    o.$color === 'contrast-background'
      ? o.theme.color.text.highContrast.on.background
      : o.theme.color.fill.background};
  pointer-events: none;
  ${o =>
    o.$color === 'contrast-background'
      ? `
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
      `
      : null};

  transition: opacity ${PopoverHovercardStyles.hovercardAnimationDuration}ms ease-out;
  opacity: 0;
  &[data-enter='true'] {
    opacity: ${o => (o.$color === 'contrast-background' ? '0.6' : '0.85')};
  }
  &[hidden] {
    display: none;
  }
`

const StyledPrimaryNavMenuDialogDismiss = styled(
  ({ flushRight = true, ...props }: ComponentProps<typeof PopoverDialogDismiss>) => {
    return <PopoverDialogDismiss flushRight={flushRight} {...props} />
  }
)`
  z-index: ${o => o.theme.zIndex.modals};
  position: absolute;
  right: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  top: -${o => o.theme.dimensions.layout.sidePadding.md}px;
  transform: translateY(-100%);
  opacity: 0;

  ${fromMd} {
    right: ${o => o.theme.dimensions.layout.sidePadding.md * 2}px;
    top: -${o => o.theme.dimensions.layout.sidePadding.md / 2}px;
  }

  transition: opacity ${PopoverHovercardStyles.hovercardAnimationDuration / 2}ms ease-out 0ms;
  &[data-open='true'] {
    transition: opacity ${PopoverHovercardStyles.hovercardAnimationDuration / 2}ms ease-in
      ${PopoverHovercardStyles.hovercardAnimationDuration / 2}ms;
  }
  &[data-enter='true'] {
    opacity: 1;
  }
  &[hidden] {
    display: none;
  }
`

type PrimaryNavMenuDialogProps = ComponentProps<typeof PopoverHovercard> & {
  closeText: string
}

export const PrimaryNavMenuDialog = ({
  gutter = 0,
  overflowPadding = 0,
  backdrop = <PopoverDialogBackdrop $clearBackdrop />,
  children,
  fixed = true,
  closeText,
  ...props
}: PrimaryNavMenuDialogProps) => {
  return (
    <PrimaryNavMenuHovercard
      as={PopoverDialog}
      fixed={fixed}
      $fillViewport
      gutter={gutter}
      overflowPadding={overflowPadding}
      backdrop={backdrop}
      render={htmlProps => {
        return (
          <>
            <StyledPrimaryNavMenuDialogBackdrop
              // @ts-ignore
              data-enter={!!htmlProps['data-enter'] ? 'true' : undefined}
              hidden={htmlProps.hidden}
              $color='match-background'
              $position='above'
            />
            <div {...htmlProps}>
              <StyledPrimaryNavMenuDialogDismiss
                // @ts-ignore
                data-enter={!!htmlProps['data-enter'] ? 'true' : undefined}
                // @ts-ignore
                data-open={!!htmlProps['data-open'] ? 'true' : undefined}
                closeText={closeText}
                hidden={htmlProps.hidden}
              />
              <StyledPrimaryNavMenuBody>{htmlProps.children}</StyledPrimaryNavMenuBody>
            </div>
            <StyledPrimaryNavMenuDialogBackdrop
              // @ts-ignore
              data-enter={!!htmlProps['data-enter'] ? 'true' : undefined}
              hidden={htmlProps.hidden}
              $color='contrast-background'
              $position='below'
            />
          </>
        )
      }}
      {...props}
    >
      {children}
    </PrimaryNavMenuHovercard>
  )
}
