import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_FAVORITES = gql(`
  query FetchFavorites($id: String!, $selector: ProductGroupColorSelector!) {
    favorites(id: $id) {
      id
      __typename
      title
      items {
        __typename
        ... on CustomerFavoriteProduct {
          productHandle
          preferences {
            size
            fit
            color
          }
          product {
            id
            __typename
            handle
            shopifyId
            soldOut
            category
            rawCategory
            title
            images(first: 2) {
              nodes {
                source
              }
            }
            colorInfo {
              availability
              hexCodes
              name
              rawName
            }
            tiers
            priceRange
            waitlistable
            availableSizes
            availableForSale
            defaultVariant {
              __typename
              handle
              shopifyId
              priceDetails {
                price {
                  amount
                  base {
                    amount
                    currency
                  }
                  currency
                }
                discountPrice {
                  amount
                }
                discountPct
              }
              color
              rawColor
              sku
              fit
              rawFit
              size
              rawSize
            }
            finalSale
            swPromoEligible
            productGroup(selector: $selector) {
              id
              __typename
              handle
              ...FavoriteTileProductGroup
              colors {
                availability
                hexCodes
                name
                swatchUrl
              }
              productsForAvailability: products {
                availableSizes
                colorInfo {
                  rawName
                }
                rawFit
                swPromoEligible
              }
            }
          }
        }
      }
    }
  }
`)
