'use client'

import { Skeleton } from '@syconium/little-miss-figgy/dist/components/Skeleton'

export const FormFieldSkeleton = (props: { excludeLabel?: boolean }) => {
  return (
    <div>
      {props.excludeLabel ? null : (
        <Skeleton animated height='16px' width='100px' style={{ marginBottom: '4px' }} />
      )}
      <Skeleton animated height='42px' width='100%' style={{ marginBottom: '0px' }} />
    </div>
  )
}
