'use client'

import { useStoreState } from '@ariakit/react'
import styled from 'styled-components'

import { TypeStyleHeadlineMicro } from '@syconium/little-miss-figgy/dist/components/TypeStyle'

import { MiniCartContent } from '../../../brunswick/components/MiniCart/MiniCartContent'
import {
  MiniCartDialogHeader,
  MiniCartDialogHeaderContent,
} from '../../../brunswick/components/MiniCart/MiniCartDialogHeader'
import { useCart } from '../../_providers/CartProvider.client'
import { useNextDialogContext } from '../dialogs/NextDialog.client'
import { NextDrawerDialog } from '../dialogs/NextDrawerDialog.client'

export const PrimaryNavCartMenuBody = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;

  > * {
    white-space: nowrap;
  }
`

const PrimaryNavCartHeader = styled(MiniCartDialogHeader)`
  padding-right: 60px;
  position: static;
  z-index: unset;
`

export const PrimaryNavCartDialog = styled(NextDrawerDialog)`
  background-color: ${o => o.theme.color.fill.subtle};
`

export const PrimaryNavCartDialogContent = () => {
  const dialogContext = useNextDialogContext()
  const dialogState = useStoreState(dialogContext)
  const isOpen = dialogState?.open

  if (!isOpen) return null
  return (
    <>
      <PrimaryNavCartHeader>
        <MiniCartDialogHeaderContent />
      </PrimaryNavCartHeader>
      <MiniCartContent isRevealed={true} />
    </>
  )
}

export const CartItemCountBadge = styled(({ className }: { className?: string }) => {
  const { cart } = useCart()
  if (cart.itemCount === 0) return null
  return (
    <span className={className}>
      <TypeStyleHeadlineMicro>
        {cart.itemCount > 99 ? '99+' : cart.itemCount}
      </TypeStyleHeadlineMicro>
    </span>
  )
})`
  box-sizing: border-box;
  min-width: ${o => o.theme.spacing(5)};
  min-height: ${o => o.theme.spacing(5)};
  padding: 0px ${o => o.theme.spacing(1)};
  position: absolute;
  right: 50%;
  top: ${o => o.theme.spacing(2.5)};
  border-radius: calc(${o => o.theme.spacing(5)} / 2);
  transform: translateX(min(100%, ${o => o.theme.spacing(6.5)}));

  background: ${o => o.theme.color.stroke.info.text.on.background};
  color: ${o =>
    o.theme.color.fill
      .background}; // If we use a text stroke for the background, the foreground needs to be a background color to ensure proper contrast ratios
`
