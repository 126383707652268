import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { timingSlower } from '@syconium/little-miss-figgy'

import { fadeIn } from '../../../brunswick/styles/shared'

export const StyledInterstitial = styled.div`
  align-items: center;
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${o => o.theme.zIndex.modals + 1};
  pointer-events: none;
`
export const Interstitial: FC<{ initEnabled?: boolean }> = ({ initEnabled }) => {
  const router = useRouter()
  const [enabled, setEnabled] = useState(initEnabled)

  useEffect(() => {
    const onRouteChangeStart = () => setEnabled(true)
    const onRouteChangeComplete = () => setEnabled(false)
    const onRouteChangeError = () => setEnabled(false)

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    router.events.on('routeChangeError', onRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      router.events.off('routeChangeError', onRouteChangeError)
    }
  }, [router, setEnabled])

  return enabled ? <StyledInterstitial /> : null
}
