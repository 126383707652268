import { CSSProperties } from 'react'
import { v4 as generateUuid } from 'uuid'

import { ShopRegion, SupportedLocaleIdentifier } from '@syconium/weeping-figs'

import { EmbroideryTypeEnum } from '../../../../__generated__/graphql/catalog/graphql'
import { hashPrefixed, intlPrettyCurrency } from '../../../../lib/utils'
import { Embroidery } from '../../../../types/graphql'
import {
  DTCAddLogoFormValues,
  DTCLogoSetupFormValues,
  EmbroideryFormKind,
  EmbroideryFormOptions,
  EmbroideryFormValues,
  EmbroideryItemCustomProperties,
  IconFormValues,
  LogoAndTextFormValues,
  LogoFormValues,
  TextFormValues,
} from '../types'

export interface EmbroideryPriceRange {
  lowestPrice: string
  highestPrice: string
}

export function derivePriceRange(
  embroideryOptions: ReadonlyArray<Embroidery>,
  region: ShopRegion,
  locale: SupportedLocaleIdentifier
): EmbroideryPriceRange {
  const allEmbroideryOptionPrices: number[] = embroideryOptions.flatMap(embroideryOption =>
    embroideryOption.variants.flatMap(variant => variant?.priceDetails?.price?.amount)
  )

  if (allEmbroideryOptionPrices.length === 0) return { highestPrice: '', lowestPrice: '' }

  if (allEmbroideryOptionPrices.length === 1) {
    const onlyPrice: string = intlPrettyCurrency({
      cents: allEmbroideryOptionPrices[0],
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    })
    return {
      lowestPrice: onlyPrice,
      highestPrice: onlyPrice,
    }
  }
  const lowestPrice: number = allEmbroideryOptionPrices.reduce((lowest, current) =>
    Math.min(lowest, current)
  )
  const highestPrice: number = allEmbroideryOptionPrices.reduce((lowest, current) =>
    Math.max(lowest, current)
  )

  return {
    lowestPrice: intlPrettyCurrency({
      cents: lowestPrice,
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    }),
    highestPrice: intlPrettyCurrency({
      cents: highestPrice,
      region: region,
      locale: locale,
      truncateTrailingZeros: true,
    }),
  }
}

export function deriveFontFamilyFromStyle(fontStyle: string): string {
  switch (fontStyle) {
    case 'Script':
      return 'VarsityScriptJF'
    case 'Block':
    default:
      return 'Helvetica'
  }
}

export function deriveColorValueFromColorName(name: string): string {
  switch (name) {
    case 'Black':
      return 'rgb(0, 0, 0)'
    case 'White':
    default:
      return 'rgb(255, 255, 255)'
  }
}

export function generateLineItemProperties(
  options: EmbroideryFormOptions | undefined,
  productColorHex: string | undefined,
  shopifyId: number | undefined,
  logoSetupId?: number | undefined
): EmbroideryItemCustomProperties {
  if (!options) return {}
  const {
    iconDesignFile,
    iconDesignName,
    iconInitials,
    iconInitialsColor,
    iconImageSource,
    iconPlacement,
    logoName,
    logoDesignFile,
    logoPlacement,
    logoImageSource,
    textColor,
    textFirstLine,
    textFont,
    textPlacement,
    textSecondLine,
    logoId,
    logoPreview,
    logoColor,
    logoNote,
  } = options

  const allProperties: EmbroideryItemCustomProperties = {
    _guid: generateUuid(),
    text_first_line: textFirstLine,
    text_second_line: textSecondLine,
    text_color: textColor,
    text_placement: textPlacement,
    text_font: textFont,
    icon_placement: iconPlacement,
    icon_design: iconDesignName,
    icon_initials: iconInitials,
    icon_initials_color: iconInitials && iconInitialsColor,
    _icon_file_name: iconDesignFile,
    _text_font_family_value: textFont ? deriveFontFamilyFromStyle(textFont) : undefined,
    _icon_font_family_value: iconInitials ? 'Helvetica' : undefined,
    _icon_image_source: iconImageSource,
    _logo_file_name: logoDesignFile,
    _logo_image_source: logoImageSource,
    _logo_id: logoId,
    logo_placement: logoPlacement,
    logo_preview: logoPreview,
    logo_name: logoName,
    logo_color_option: logoColor,
    logo_note: logoNote,
    _LPROP: 'final sale',
    _embroideryId: shopifyId?.toString(),
    _scrub_color: hashPrefixed(productColorHex),
    _text_thread_color_value: textColor ? deriveColorValueFromColorName(textColor) : undefined,
    _icon_thread_color_value: iconInitialsColor
      ? deriveColorValueFromColorName(iconInitialsColor)
      : undefined,
    _embroidery_item: '',
    _embroidery_version: '1.5',
    _logo_setup_embroideryId: logoSetupId?.toString(),
    _logo_setup_guid: logoSetupId ? generateUuid() : undefined,
  }

  return removeUnusedProperties(allProperties)
}

function removeUnusedProperties(o: EmbroideryItemCustomProperties): EmbroideryItemCustomProperties {
  return Object.entries(o).reduce((accumulator, [key, val]) => {
    // Shopify Checkout page reads the _embroidery_item empty string value to style embroidery items in the cart. (Kyle Corbelli 07/21/20)
    return val || key === '_embroidery_item' ? { ...accumulator, [key]: val } : accumulator
  }, {})
}

export const emptyIconFormValues: IconFormValues = {
  __type: 'IconFormValues',
  iconPlacement: undefined,
  initials: '',
  initialsColor: '',
  iconImage: undefined,
}

export const emptyTextFormValues: TextFormValues = {
  __type: 'TextFormValues',
  textColor: '',
  font: undefined,
  firstLine: '',
  textPlacement: undefined,
  secondLine: '',
}

export const emptyLogoFormValues: LogoFormValues = {
  __type: 'LogoFormValues',
  logoName: '',
  logoImageSource: '',
}

export const emptyLogoAndTextFormValues: LogoAndTextFormValues = {
  ...emptyTextFormValues,
  ...emptyLogoFormValues,
  __type: 'LogoAndTextFormValues',
}

export function textDefaultValues(
  embroideryOption: Embroidery,
  productColorHex: string | undefined
): TextFormValues {
  let textColor: string | undefined
  if (embroideryOption.textColors) {
    if (isLightProductColor(productColorHex)) {
      textColor = embroideryOption.textColors?.[0]
    } else {
      textColor = embroideryOption.textColors?.[1]
    }
  }

  return {
    __type: 'TextFormValues',
    textColor: textColor ?? '',
    font: embroideryOption?.textFonts?.[0] ?? undefined,
    firstLine: '',
    textPlacement: embroideryOption?.textPlacements?.[0] ?? undefined,
    secondLine: '',
  }
}

export function iconDefaultValues(
  embroideryOption: Embroidery,
  productColorHex: string | undefined
): IconFormValues {
  const iconPlacement = embroideryOption?.iconPlacements?.[0] ?? undefined

  let initialsColor: string | undefined
  if (embroideryOption.initialsColors) {
    if (isLightProductColor(productColorHex)) {
      initialsColor = embroideryOption.initialsColors?.[0]
    } else {
      initialsColor = embroideryOption.initialsColors?.[1]
    }
  }

  return {
    __type: 'IconFormValues',
    // We always want to display the "flatlay-chest" overlay image for icon embroidery
    iconPlacement: iconPlacement ? { ...iconPlacement, flatlayKind: 'flatlay-chest' } : undefined,
    initials: '',
    initialsColor: initialsColor ?? '',
    iconImage: undefined,
  }
}

export function logoAndTextDefaultValues(
  embroideryOption: Embroidery,
  productColorHex: string | undefined
): LogoAndTextFormValues {
  return {
    ...textDefaultValues(embroideryOption, productColorHex),
    ...emptyLogoFormValues,
    __type: 'LogoAndTextFormValues',
  }
}

export const logoSetupDefaultValues: DTCLogoSetupFormValues = {
  __type: 'DTCLogoEmbroideryFormValues',
  name: undefined,
  notes: undefined,
  tos: undefined,
  file: undefined,
  digitization_setting: undefined,
}

export function addLogoDefaultValues(embroideryOption: Embroidery): DTCAddLogoFormValues {
  const logoPlacement = embroideryOption?.logoPlacements?.[0] ?? undefined

  return {
    __type: 'AddLogoFormValues',
    logoFileId: undefined,
    logoFile: undefined,
    logoPlacement: logoPlacement ?? undefined,
  }
}

export function mapEmbroideryOptionToInitialFormValues<T extends EmbroideryFormKind>(
  kind: T,
  embroideryOption: Embroidery,
  productColorHex: string | undefined
): EmbroideryFormValues[T] {
  const map = {
    ICON_ONLY: iconDefaultValues(embroideryOption, productColorHex),
    TEXT_ONLY: textDefaultValues(embroideryOption, productColorHex),
    LOGO_ONLY: emptyLogoFormValues,
    LOGO_TEXT: emptyLogoAndTextFormValues,
    LOGO_SETUP: logoSetupDefaultValues,
    ADD_LOGO: addLogoDefaultValues(embroideryOption),
  } as const

  return map[kind]
}

/** Type guard for checking if a string is a valid embroidery form kind */
export function isValidFormKind(kind: string): kind is EmbroideryFormKind {
  return ['ICON_ONLY', 'TEXT_ONLY', 'LOGO_ONLY', 'LOGO_SETUP', 'ADD_LOGO'].includes(kind)
}

/** Get the embroidery form kind associated with a given embroidery option */
export function getFormKind(embroideryKind: EmbroideryTypeEnum): EmbroideryFormKind | undefined {
  if ('LOGO_COLOR' === embroideryKind || 'LOGO_BLACK_WHITE' === embroideryKind) {
    return 'ADD_LOGO'
  }

  if (isValidFormKind(embroideryKind)) return embroideryKind
  return undefined
}

export const allowedTextInputCharactersPattern = /^[!@#$%&*()+=\-:“;’'<>.?,\/a-zA-Z0-9\s]+$/
export const allowedIconTextInputCharactersPattern = /^[A-Z]+$/
export const allowedLogoNameInputCharactersPattern = /^[a-zA-Z0-9\s'-.]+$/

// adapted from `isLightBackground` in `@syconium/little-miss-figgy/dist/components/ColorSwatch/styles.ts`
function isLightProductColor(hexColor: CSSProperties['color']): boolean {
  if (!hexColor) return false
  if (hexColor === 'white') return true
  if (hexColor?.startsWith('#')) {
    try {
      const r = Number(`0x${hexColor.slice(1, 3)}`)
      const g = Number(`0x${hexColor.slice(3, 5)}`)
      // const b = Number(`0x${hexColor.slice(5, 7)}`)

      if (r > 235) return true
      if (g > 140) return true
    } catch {}
  }
  return false
}
