import React from 'react'
import { Interpolation } from 'styled-components'

import {
  GetSiteFixturesQuery,
  NavAccordionSectionFragment,
  NavListGroupSectionFragmentFragment,
  NavListSectionFragmentFragment,
  NavListSectionItemFragmentFragment,
  NavTileSectionFragment,
  SizeChartFragmentFragment,
} from '../../__generated__/graphql/catalog/graphql'

import { RawHtmlSection } from './page-view'

export enum AlternateModelVariantAction {
  replaceOriginal = 'Replace Original',
  hideOriginal = 'Hide Original',
}

type ClickishEvent = React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
type ClientSideNavListItemAdditions = {
  asTag?: keyof JSX.IntrinsicElements
  role?: string
  textRole?: string
  isInFooter?: boolean
  hasDivider?: boolean
  onClickish?: (event: ClickishEvent) => void
}
type ClientSideNavSectionAdditions = {
  styles?: Interpolation<object>
}
type ClientSideNavTabAdditions = {
  ariaLabel?: string
  onClickish?: (event: ClickishEvent) => void
  render?: () => React.ReactNode
  wrapperElement?: 'div' | 'button' | 'a'
}

export type NavListItemData = NavListSectionItemFragmentFragment
export type NavListItem = NavListItemData & ClientSideNavListItemAdditions

export type NavListSectionData = NavListSectionFragmentFragment
export type NavListSectionVariant = NavListSectionData['variant']
export type NavListSection = Omit<NavListSectionData, 'items'> & {
  items: Array<NavListSectionData['items'][number] & ClientSideNavListItemAdditions>
} & ClientSideNavSectionAdditions

export type NavListGroupSectionData = NavListGroupSectionFragmentFragment
export type NavListGroupSection = NavListGroupSectionData & ClientSideNavSectionAdditions

export type NavTileSectionData = NavTileSectionFragment
export type NavTileSection = NavTileSectionData & ClientSideNavSectionAdditions

export type NavTileData = NavTileSectionData['tiles'][number]
export type NavTile = NavTileData & {}

export type NavAccordionData = NavAccordionSectionFragment
export type NavAccordion = NavAccordionData & {
  startOpenSet?: number[]
}
export type NavAccordionVariant = NavAccordionData['variant']
export type NavAccordionSection = NonNullable<NavAccordionData['sections'][number]>

export type NavSectionData =
  | NavListSectionData
  | NavListGroupSectionData
  | NavTileSectionData
  | NavAccordionData
  | RawHtmlSection
  | NavRegionLocaleSection

export type NavSection =
  | NavListSection
  | NavListGroupSection
  | NavTileSection
  | NavAccordion
  | RawHtmlSection
  | NavRegionLocaleSection

// This is a fake section someone made and injected as a section for a NavTab
export type NavRegionLocaleSection = {
  __typename: 'NavRegionLocaleSection'
  id: string
}

export type NavTabData =
  | GetSiteFixturesQuery['siteFixtures']['navigation']['desktopTabs'][number]
  | GetSiteFixturesQuery['siteFixtures']['navigation']['mobileTabs'][number]
export type NavTabSectionData = NavTabData['sections'][number] | NavRegionLocaleSection
export type NavTab = Omit<NavTabData, 'sections'> &
  ClientSideNavTabAdditions & {
    sections: Array<NavTabSectionData & ClientSideNavSectionAdditions>
  }

export interface PromoBar
  extends Readonly<{
    backgroundColor: string
    textColor: string
    pages: {
      id: string
      content: string
    }[]
  }> {}

export type SizeChartTip = NonNullable<SizeChartFragmentFragment['tips']>[number]

export type SizeChartTableCells = ReadonlyArray<ReadonlyArray<string>>

export type SizeChartTable = NonNullable<SizeChartFragmentFragment['tables']>[number]

export type SizeChart = SizeChartFragmentFragment

export enum TooltipContentTypes {
  ColorCategory = 'colorCategory',
}

export interface Tooltip
  extends Readonly<{
    id: string
    contentType: TooltipContentTypes
    enumValue: {
      name: string
    }
    title: string
    description: string
  }> {}

export type ContentfulRegionData = GetSiteFixturesQuery['siteFixtures']['regionData']

export type ContentfulRegion = NonNullable<
  GetSiteFixturesQuery['siteFixtures']['regionData']
>['regions'][number]

export interface ContentfulLocale
  extends Readonly<{
    language: string
    locale: string
  }> {}

export interface LocaleData
  extends Readonly<{
    locales: ContentfulLocale[]
    name: string
  }> {}

export type SiteFooterData = NonNullable<GetSiteFixturesQuery['siteFixtures']['footer']>

export type SiteNavigationData = NonNullable<GetSiteFixturesQuery['siteFixtures']['navigation']>
