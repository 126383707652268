import { LocalizationAttributes } from './Localization.config'
import { SessionAttributes } from './Session.config'

export type PersonalizationTargetCriteria = {
  targetLogic: string | null | undefined
  targetCriteria: string[] | null | undefined
  inRegion?: string[] | null | undefined
}

type PersonalizationTargetCriteriaOptions = {
  sessionAttributes: SessionAttributes
  isLoggedIn: boolean
  localizationAttributes: LocalizationAttributes
  targetting: PersonalizationTargetCriteria
}

const inRegion = ({
  inRegion,
  localizationAttributes,
}: {
  inRegion?: string[] | null
  localizationAttributes: LocalizationAttributes
}) => {
  if (!inRegion) return false
  if (inRegion.includes(localizationAttributes.region.id)) return true
  return false
}

export const validatePersonalizationTargetCriteria = (
  options: PersonalizationTargetCriteriaOptions
): boolean => {
  const results = options.targetting.targetCriteria?.map(targetCriteria => {
    switch (targetCriteria) {
      case 'Initial Session':
        return options.sessionAttributes.customerType === 'firstTime'
      case 'Has Prior Session':
        return options.sessionAttributes.customerType === 'repeat'
      case 'Logged In':
        return options.isLoggedIn
      case 'In Region':
        return inRegion({
          inRegion: options.targetting.inRegion,
          localizationAttributes: options.localizationAttributes,
        })
      default:
        return false
    }
  })

  if (!results) return false

  switch (options.targetting.targetLogic) {
    case 'Match Any':
      return results.includes(true)
    case 'Match All':
      return !results.includes(false)
    default:
      return false
  }
}
