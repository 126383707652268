'use client'

import {
  Disclosure,
  DisclosureContent as DisclosureContentImpl,
  DisclosureProvider,
  useDisclosureContext,
  useStoreState,
} from '@ariakit/react'
import { ComponentProps, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { timingDefault } from '@syconium/little-miss-figgy/dist/constants/timing'
import { focusOutlineInset } from '@syconium/little-miss-figgy/dist/utility-classes'

type DisclosureListProps = {
  children?: ReactNode
  className?: string
}

export const DisclosureList = (props: DisclosureListProps) => {
  return <ul {...props} />
}

type StyledDisclosureListItemProps = {
  className?: string
  children?: React.ReactNode
}

const StyledDisclosureListItem = styled((props: StyledDisclosureListItemProps) => {
  const disclosureContext = useDisclosureContext()
  const disclosureState = useStoreState(disclosureContext)
  const isOpen = disclosureState?.open ?? false
  return <li {...props} data-open={`${isOpen}`} />
})`
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  &[data-open='true'] {
    background-color: ${o => o.theme.color.fill.subtle};
  }
  transition-property: background-color;
  transition-timing-function: ease;
  transition-duration: ${timingDefault};
  animation-duration: ${timingDefault};
`

type DisclosureListItemProps = {
  children?: ReactNode
  className?: string
} & ComponentProps<typeof DisclosureProvider>

export const DisclosureListItem = ({ className, children, ...props }: DisclosureListItemProps) => {
  return (
    <DisclosureProvider {...props}>
      <StyledDisclosureListItem className={className}>{children}</StyledDisclosureListItem>
    </DisclosureProvider>
  )
}

const disclosureListItemActionCss = css`
  display: flex;
  gap: ${o => o.theme.spacing(2)};
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  min-height: 42px;
  ${focusOutlineInset};
  text-align: inherit;
`

export const DisclosureListItemLink = styled.a`
  ${disclosureListItemActionCss};
`

type DisclosureSummaryProps = Omit<ComponentProps<typeof Disclosure>, 'render'> & {
  renderIcon: React.ReactNode
}

export const DisclosureListItemSummary = styled(
  ({ children, renderIcon, ...props }: DisclosureSummaryProps) => {
    return (
      <Disclosure {...props}>
        <div style={{ flex: 1 }}>{children}</div>
        <div style={{ flexGrow: 0, flexShrink: 0, transform: 'translate3d(0, 0, 0)' }}>
          {renderIcon}
        </div>
      </Disclosure>
    )
  }
)`
  ${disclosureListItemActionCss};
`

const delayedClippingClosing = keyframes`
  0% {
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
`

const delayedClippingOpenning = keyframes`
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: unset;
  }
`

export const DisclosureListItemContent = styled(DisclosureContentImpl)`
  display: grid;
  padding-top: 0px;
  padding-right: 24px;
  padding-bottom: 0px;
  padding-left: 60px;
  grid-template-rows: 0fr;

  transition:
    padding-bottom ${timingDefault} ease 0s,
    padding-top ${timingDefault} ease 0s,
    grid-template-rows ${timingDefault} ease 0s;

  > * {
    animation: ${delayedClippingClosing} calc(${timingDefault} + 25ms);
  }
  &[data-open] {
    > * {
      animation: ${delayedClippingOpenning} calc(${timingDefault} + 25ms);
    }
  }

  &[data-enter] {
    padding-bottom: 24px;
    grid-template-rows: 1fr;
  }
`
