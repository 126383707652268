import styled from 'styled-components'

import { TypeStyle, fromMd, timingSlower } from '@syconium/little-miss-figgy'

import { miniCartWidthDesktop, miniCartWidthMobile } from '../../constants'

export const Container = styled.div<{
  isRevealed: boolean
}>`
  background: ${o => o.theme.color.fill.subtle};
  bottom: 0;
  overflow: auto;
  position: fixed;
  inset-inline-end: 0;
  top: 0;
  width: ${miniCartWidthMobile};
  transform: translate3d(${o => (o.isRevealed ? '0' : '100%')}, 0, 0);
  transition: transform ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.modals};

  .rtl & {
    transform: translate3d(${o => (o.isRevealed ? '0' : '-100%')}, 0, 0);
  }

  ${fromMd} {
    width: ${miniCartWidthDesktop};
  }
`

export const CartItemsWrapper = styled.div`
  background: ${o => o.theme.color.fill.background};
  padding: ${o => o.theme.spacing(0, 4)};

  ${fromMd} {
    padding: ${o => o.theme.spacing(0, 10)};
  }
`

export const CloseButton = styled.button`
  background: unset;
  border: unset;
  bottom: auto;
  cursor: pointer;
  inset-inline-end: 0;
  padding: 20px;
  position: absolute;
  top: auto;

  ${fromMd} {
    inset-inline-end: 20px;
    &:hover g {
      stroke: ${o => o.theme.color.stroke.neutral.text.tertiary.on.background};
    }
  }
`

export const TotalAndCheckout = styled.div`
  background-color: ${o => o.theme.color.fill.background};
  bottom: 0px;
  position: sticky;
  z-index: 10000;

  // to not affect iphone SE
  @media screen and (max-width: 374px) {
    position: static;
  }
`

export const CheckoutErrorMessage = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.text.critical.on.background};
  font-weight: bold;
  margin-top: 10px;
`

export const BuyNowPayLaterContainer = styled.div`
  background-color: ${o => o.theme.color.fill.background};
  padding-bottom: 20px;
`

export const IntlMsgWrap = styled(TypeStyle.HeadlineMicro)`
  color: ${o => o.theme.color.text.secondary.on.background};
  margin-top: ${o => o.theme.spacing(2)};
  text-align: right;
`

export const TotalLineItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(2)};
  ${fromMd} {
    gap: ${o => o.theme.spacing(3)};
  }
`

export const PortalDiscountText = styled(TypeStyle.BodyLarge)`
  color: ${o => o.theme.color.text.critical.on.background};
  font-weight: 700;
`

export const CheckoutDisclosureWrapper = styled.div`
  background-color: ${o => o.theme.color.fill.background};
  padding: ${o => o.theme.spacing(0, 4, 4)};

  @media screen and (min-width: 395px) {
    // fix for weird line that appears in Chrome-based browsers
    margin-top: -1px;
  }

  ${fromMd} {
    padding: ${o => o.theme.spacing(0, 10, 5)};
  }
`
export const CheckoutDisclosureText = styled(TypeStyle.BodySmall)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`
