import * as React from 'react'

import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { SiteWideMessagingProvider } from '../../../app/_providers/SiteWideMessagingProvider.client'
import { createContextHook } from '../../../lib/utils/createContextHook'

import { useCmsProvidedSiteFixtures } from './hooks/useCmsProvidedSiteFixtures'

const FixturesContext = React.createContext<
  ReturnType<typeof useCmsProvidedSiteFixtures> | undefined
>(undefined)

export const FixturesContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const cmsSiteFixtures = useCmsProvidedSiteFixtures()

  const { region: currentRegion } = useLocalization()
  const currentRegionSiteFixtures = cmsSiteFixtures.regionData?.regions?.find(
    nextRegion => nextRegion.id === currentRegion.id
  )

  return (
    <SiteWideMessagingProvider
      siteWideMessaging={{
        siteWidePromotionMessage: cmsSiteFixtures.siteWidePromo,
        siteWidePrecheckoutMessage: cmsSiteFixtures.miniCartMessage,
        siteWideRegionSpecificDutiesAndFeesMessage:
          currentRegionSiteFixtures?.taxAndDutyText ?? null,
        siteWideRegionSpecificShippingMessage: currentRegionSiteFixtures?.shippingText ?? null,
        siteWideEmbroideryDisclosure: cmsSiteFixtures.embroideryWarningMessage,
      }}
    >
      <FixturesContext.Provider value={cmsSiteFixtures}>{children}</FixturesContext.Provider>
    </SiteWideMessagingProvider>
  )
}

/**
 * Fixtures are a giant JSON object containing many unrelated pieces of information pulled from Contentful.
 * @deprecated do not use this. We can query directly for what is needed based on a component's needs rather than pulling in thousands of lines of unrelated JSON values.
 * @todo remove this after we have migrated to the app directory and no longer reference this anywhere.
 */
export const useFixturesContext = createContextHook(FixturesContext, 'FixturesContext')
