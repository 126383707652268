'use client'

import { DialogDismiss, useDialogContext, useStoreState } from '@ariakit/react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { TextLink } from '@syconium/little-miss-figgy'
import { Button } from '@syconium/little-miss-figgy/dist/components/Button'
import { FlagIconImage } from '@syconium/little-miss-figgy/dist/components/FlagIconImage'
import {
  TypeStyleBodySmall,
  TypeStyleHeadlineExtraExtraSmall,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { useBrowserStoredState } from '@syconium/little-miss-figgy/dist/lib/hooks/useBrowserStoredState'
import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { useTranslatedLocalization } from '../../_hooks/useTranslatedLocalization'
import { useSiteWideMessaging } from '../../_providers/SiteWideMessagingProvider.client'
import { useTranslation } from '../../_providers/TranslationProvider.client'
import {
  NextDialog,
  NextDialogBody,
  NextDialogHeader,
  NextDialogProvider,
  NextDialogTitle,
} from '../dialogs/NextDialog.client'

import {
  PrimaryNavLocalizationDialogContent,
  PrimaryNavLocalizationDialogContentFooter,
} from './PrimaryNavLocalization.client'

const WelcomeDialogCtasWrapper = styled(
  ({ className, children }: { className?: string; children?: React.ReactNode }) => {
    return <div className={className}>{children}</div>
  }
)`
  display: flex;
  margin-top: ${o => o.theme.spacing(4)};
  gap: ${o => o.theme.spacing(4)};
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const WelcomeDialogBody = styled(
  ({ className, children }: { className?: string; children?: React.ReactNode }) => {
    return <NextDialogBody className={className}>{children}</NextDialogBody>
  }
)`
  display: flex;
  gap: ${o => o.theme.spacing(2)};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const WelcomeDialog = () => {
  const translations = useTranslation()
  const {
    data: { region: currentRegion },
    status,
  } = useTranslatedLocalization()
  const siteWideMessaging = useSiteWideMessaging()
  const dialogContext = useDialogContext()
  const dialogState = useStoreState(dialogContext)

  const [currentView, setCurrentView] = useState<'welcome' | 'change-region'>('welcome')

  const { state: alreadyWelcomedRegions, setState: setAlreadyWelcomedRegions } =
    useBrowserStoredState<SupportedRegionIdentifier[]>({
      initialState: [],
      storageKey: '@figs:seen-welcome-modal',
    })

  useEffect(() => {
    if (
      currentRegion.id !== 'US' &&
      !alreadyWelcomedRegions.includes(currentRegion.id) &&
      dialogState?.open !== true
    ) {
      setAlreadyWelcomedRegions([...alreadyWelcomedRegions, currentRegion.id])
      dialogContext?.setOpen(true)
    }
  }, [
    currentRegion,
    alreadyWelcomedRegions,
    setAlreadyWelcomedRegions,
    dialogState?.open,
    dialogContext,
  ])

  const welcomeEyebrowText =
    `${translations.intlWelcomeModal.welcome} ${currentRegion.name}!`.toUpperCase()

  // Since we are an entire dialog that is system generated, we can return null here until we are ready.
  // The user is not "waiting" for us since they didn't trigger us.
  // Once we start returning content, we will just finally "open" to be viewed.
  if (status !== 'resolved') return null

  return (
    <NextDialog>
      {currentView === 'welcome' ? (
        <>
          <NextDialogHeader closeText={translations.magnolia.general.close}>
            <div>
              <TypeStyleHeadlineExtraExtraSmall isUppercase>
                {welcomeEyebrowText}
              </TypeStyleHeadlineExtraExtraSmall>
            </div>
            <div>
              <FlagIconImage src={currentRegion.flagSrc} />
            </div>
            <div>
              <NextDialogTitle>{translations.intlWelcomeModal.easierToBuy}</NextDialogTitle>
            </div>
          </NextDialogHeader>
          <WelcomeDialogBody>
            {siteWideMessaging.siteWideRegionSpecificShippingMessage ? (
              <TypeStyleBodySmall>
                {siteWideMessaging.siteWideRegionSpecificShippingMessage}
              </TypeStyleBodySmall>
            ) : null}
            <TypeStyleBodySmall>
              {translations.intlWelcomeModal.shopInYourLocalCurrency}
            </TypeStyleBodySmall>
            {siteWideMessaging.siteWideRegionSpecificDutiesAndFeesMessage ? (
              <TypeStyleBodySmall>
                {siteWideMessaging.siteWideRegionSpecificDutiesAndFeesMessage}
              </TypeStyleBodySmall>
            ) : null}
            <WelcomeDialogCtasWrapper>
              <Button type='button' as={DialogDismiss} block>
                {translations.intlWelcomeModal.continueShopping}
              </Button>
              <TextLink type='button' as='button' onClick={() => setCurrentView('change-region')}>
                {translations.intlWelcomeModal.changeCountry}
              </TextLink>
            </WelcomeDialogCtasWrapper>
          </WelcomeDialogBody>
          <PrimaryNavLocalizationDialogContentFooter />
        </>
      ) : (
        <PrimaryNavLocalizationDialogContent variant='full' />
      )}
    </NextDialog>
  )
}

export const PrimaryNavWelcomeDialog = () => {
  return (
    <NextDialogProvider>
      <WelcomeDialog />
    </NextDialogProvider>
  )
}
